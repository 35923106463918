export default {
  rules: {
    // Check for:
    // ---Cannot contain spaces
    // ---Contain a minimum of 8 characters
    // ---Contain at least one lowercase character (a-z)
    // ---Contain at least one uppercase character (A-Z)
    // ---Contain at least one numeric character (0-9)
    // ---Contain at least one non-alphanumeric character (! @ # $, etc)
    strongPasswordValidation({ value }) {
      return /^(?=.{8,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W|_])(?!.*\s).*/.test(value)
    },

    // Please use custom validation name format => {countryCode in lower case}PostalCode
    usPostalCode({ value }) {
      return /^\d{5}$/.test(value)
    },
    nlPostalCode({ value }) {
      return /^\d{4} ?[A-Z]{2}$/.test(value)
    },
    dePostalCode({ value }) {
      return /^\d{5}$/.test(value)
    },
    atPostalCode({ value }) {
      return /^\d{4}$/.test(value)
    },
    gbPostalCode({ value }) {
      return /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/.test(
        value
      )
    },
    atVatId({ value }) {
      return /^ATU[0-9]{8}$/.test(value)
    },
    deVatId({ value }) {
      return /^DE[0-9]{9}$/.test(value)
    },
    gbVatId({ value }) {
      return /^GB[0-9]{1,12}$/.test(value)
    },
  },
  library: {
    autocomplete: {
      classification: 'text',
      component: 'FeatureCountrySelectorInputAutocomplete',
    },
  },
}
