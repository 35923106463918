// import { useContext } from '@nuxtjs/composition-api'

// Composables
// import useAnalytics from '@/composables/analytics/useAnalytics'

export default () => {
  // const context = useContext()
  // const analytics = useAnalytics()

  /*
    Track link click to a social platform
  */
  const trackSocialLinkClick = (url: string) => {
    // create a URL object to get the hostname of this URL
    // const domain = new URL(url)
    // TODO: Ask Jason if we need a GA4 event
  }

  return {
    trackSocialLinkClick,
  }
}
