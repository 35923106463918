






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const btnClick = (): void => {
      // perform redirect
      if (props.href) {
        window.location.href = props.href
      }
    }

    return {
      btnClick,
    }
  },
})
