import https from 'https'
import { Context, Plugin } from '@nuxt/types'
import { EvaluateFeatureCommandOutput, EvaluationResult } from '@aws-sdk/client-evidently'
import { useProfileStore } from '~/store'
import { useCountry } from '~/composables/country/useCountry'
import {
  EvaluateOption,
  FeatureFlagsPlugin,
  FetchManyOptions,
  FetchFeatureResult,
} from '~/types/featureFlags/featureFlagsPlugin'
import { setTestingIdCookie } from '~/common/utils/testingIdCookie'
import { createEvidentlyRequest } from '~/common/utils/createEvidentlyRequest'
import { mapBatchEvaluateResult, mapEvaluationResult } from '~/common/utils/featureFlagsUtils'

function createFeatureFlagsClientPlugin(context: Context): FeatureFlagsPlugin {
  const axios = context.$axios.create({
    baseURL: '/api/evidently',
    headers: {
      'Content-Type': 'application/json',
    },
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  })

  const profileStore = useProfileStore(context.$pinia)
  const { selectedCountry } = useCountry(context.$pinia)
  const logger = context.$logger.child({ lgroup: 'evidently' })

  async function fetchMany(featuresToFetch: FetchManyOptions): Promise<FetchFeatureResult[]> {
    logger.info(`[CLIENT-SIDE batchEvaluateFeature] ${featuresToFetch.map((ft) => ft.name).join(', ')}`)

    const { data } = await axios.post<EvaluationResult[]>('/batchEvaluateFeature', {
      requests: featuresToFetch.map((feature) =>
        createEvidentlyRequest({
          featureName: feature.name,
          evaluateOption: feature.evaluateBasedOn,
          context,
          email: profileStore.userInfo.email,
          countryCode: selectedCountry.value,
        })
      ),
    })

    return mapBatchEvaluateResult(data, featuresToFetch)
  }

  async function fetch(featureName: string, evaluateOption: EvaluateOption): Promise<FetchFeatureResult> {
    logger.info(`[CLIENT-SIDE evaluateFeature] ${featureName}`)

    const { data } = await axios.post<EvaluateFeatureCommandOutput>(
      '/evaluateFeature',
      createEvidentlyRequest({
        featureName,
        evaluateOption,
        context,
        email: profileStore.userInfo.email,
        countryCode: selectedCountry.value,
      })
    )

    return mapEvaluationResult(data, featureName, evaluateOption)
  }

  return {
    fetchMany,
    fetch,
    setTestingIdCookie: (id: string) => setTestingIdCookie(context, id),
  }
}

const plugin: Plugin = (ctx, inject) => {
  inject('featureFlags', createFeatureFlagsClientPlugin(ctx))
}

export default plugin
