import { Client } from '../client'
import {
  URL_CUSTOMERS,
  URL_CUSTOMERS_ME,
  URL_CUSTOMERS_CONTACTS,
  URL_CUSTOMERS_LABNOTEBOOK,
  URL_PAYMENTS,
  URL_ORDERS,
} from '../util/constants'

export type MethodValue = {
  poNumber?: string
  cardToken?: string
  holderName?: string
  expiration?: string
  invoiceEmailAddress?: string
  cardType?: string
}

export type AddressPayload = {
  company: string
  streetName: string
  additionalStreetInfo: string
  city: string
  state: string
  postalCode: string
  country: string
  phone: string | undefined

  custom: {
    type: {
      typeId: 'type'
      key?: 'address-type'
      id?: string
    }
    fields: {
      phoneNumberExt: string | undefined
      taxExempt: boolean
      vatId?: string
      // TODO: Rename this field when carrier switch will be implemented for the API
      fedExCollectNumber?: string

      // These values are not shown to users, but are used when debugging export/import from ATG to CT:
      legacyId?: string
      nickname?: string

      // These values are not shown to users, but are used when dropping the Orders to EBS.
      // Unmapped addresses (without an EBS value) are sent to EBS Corrections Queue.
      // Mapped addresses (with an EBS value) go directly to EBS system for processing.
      // These values displayed on test pages to help with Back End debugging.
      ebsBillToId?: string
      ebsOrgAccountNumber?: string
      ebsShipToId?: string
      ebsSoldToId?: string
      billToDiscountCode?: string
      shipToDiscountCode?: string

      // These values aren't show to users, but determine the cost of Shipping user will pay:
      freightTerm?: string
    }
  }
}

type ContactPayload = {
  firstName: string
  lastName: string
  lab: string | undefined
  department: string | undefined
  building: string | undefined
  email: string | undefined
  phone: string | undefined
  phoneExtension: string | undefined
}

type FirstNamePayload = {
  action: 'setFirstName'
  firstName: string
}

type LastNamePayload = {
  action: 'setLastName'
  lastName: string
}

type CompanyPayload = {
  action: 'setCompanyName'
  companyName: string
}

type VatIdPayload = {
  action: 'setVatId'
  vatId: string
}

type CustomFieldPayload = {
  action: 'setCustomField'
  name: 'mobilePhone' | 'mobilePhoneExtension' | string
  value: string
}

type PriceInterface = {
  type: string
  currencyCode: 'USD' | 'EUR' | 'GBP' | 'CNY' | 'JPY'
  centAmount: number
  fractionDigits: number
}

type UpdateUserInfoPayload = FirstNamePayload | LastNamePayload | CompanyPayload | CustomFieldPayload | VatIdPayload

const expandParams = {
  params: {
    expand: 'custom.fields.contacts',
  },
}

export const bindClient = (client: Client) => {
  // Return ALL Profile data without labNotebooks
  const info = (): Promise<any> => {
    return client.axios.get(URL_CUSTOMERS_ME, expandParams)
  }

  const updateUserInfo = (actionsWithParams: UpdateUserInfoPayload[]): Promise<any> => {
    const data = {
      actions: actionsWithParams,
    }

    return client.axios.post(URL_CUSTOMERS, data)
  }

  // Shipping
  const setShippingAddress = (addressId: string, value: boolean): Promise<any> => {
    const action = value ? 'addShippingAddressId' : 'removeShippingAddressId'
    const data = {
      actions: [{ action, addressId }],
    }
    return client.axios.post(URL_CUSTOMERS, data)
  }

  const setDefaultShippingAddress = (addressId: string): Promise<any> => {
    const action = 'setDefaultShippingAddress'
    const data = {
      actions: [{ action, addressId }],
    }
    return client.axios.post(URL_CUSTOMERS, data)
  }

  // Billing
  const setBillingAddress = (addressId: string, value: boolean): Promise<any> => {
    const action = value ? 'addBillingAddressId' : 'removeBillingAddressId'
    const data = {
      actions: [{ action, addressId }],
    }
    return client.axios.post(URL_CUSTOMERS, data)
  }

  const setDefaultBillingAddress = (addressId: string): Promise<any> => {
    const action = 'setDefaultBillingAddress'
    const data = {
      actions: [{ action, addressId }],
    }
    return client.axios.post(URL_CUSTOMERS, data)
  }

  // Address
  const addAddress = (address: AddressPayload): Promise<any> => {
    const action = 'addAddress'
    const data = {
      actions: [{ action, address }],
    }
    return client.axios.post(URL_CUSTOMERS, data)
  }

  const updateAddress = (addressId: string, address: AddressPayload): Promise<any> => {
    const action = 'changeAddress'
    const data = {
      actions: [{ action, addressId, address }],
    }
    return client.axios.post(URL_CUSTOMERS, data)
  }

  const removeAddress = (addressId: string): Promise<any> => {
    const action = 'removeAddress'
    const data = {
      actions: [{ action, addressId }],
    }
    return client.axios.post(URL_CUSTOMERS, data)
  }

  // Contact
  const addContact = (contact: ContactPayload): Promise<any> => {
    const action = 'addContact'
    const data = {
      actions: [{ action, contact }],
    }
    return client.axios.post(URL_CUSTOMERS_CONTACTS, data)
  }

  const updateContact = (contactId: string, contact: ContactPayload): Promise<any> => {
    const action = 'changeContact'
    const data = {
      actions: [{ action, contactId, contact }],
    }
    return client.axios.post(URL_CUSTOMERS_CONTACTS, data)
  }

  const removeContact = (contactId: string): Promise<any> => {
    const action = 'removeContact'
    const data = {
      actions: [{ action, contactId }],
    }
    return client.axios.post(URL_CUSTOMERS_CONTACTS, data)
  }

  //  LabNotebook
  const getLabNotebooks = (): Promise<any> => {
    return client.axios.get(URL_CUSTOMERS_LABNOTEBOOK)
  }

  // OrderHistory
  const getOrderHistory = (offset: number, limit: number): Promise<any> => {
    // Pagination
    const params = {
      limit,
      offset,
      sort: 'custom.fields.createdAt desc',
    }

    return client.axios.get(URL_ORDERS, { params })
  }

  const getOrderByOrderNumber = (orderNumber: string): Promise<any> => {
    return client.axios.get(
      URL_ORDERS + `?where=custom(fields(webOrderNumber${encodeURIComponent('="' + orderNumber)}"))`
    )
  }

  const createPayment = (totalPrice: PriceInterface, method: string, methodValue: MethodValue): Promise<any> => {
    let data = {}
    if (method === 'card') {
      data = {
        amountPlanned: {
          centAmount: totalPrice.centAmount,
          currencyCode: totalPrice.currencyCode,
        },
        paymentMethodInfo: {
          method: 'credit-card',
          name: {
            en: 'Credit card',
          },
        },
        transaction: {
          type: 'Authorization',
          amount: {
            centAmount: totalPrice.centAmount,
            currencyCode: totalPrice.currencyCode,
          },
          state: 'initial',
          interactionId: methodValue.cardToken,
        },
        custom: {
          type: {
            typeId: 'type',
            key: 'payment-type',
          },
          fields: {
            cardHolderName: methodValue.holderName,
            cardExpiration: methodValue.expiration,
            poNumber: methodValue.poNumber,
            cardType: methodValue.cardType,
          },
        },
      }
    } else if (method === 'poNumber') {
      data = {
        amountPlanned: {
          centAmount: totalPrice.centAmount,
          currencyCode: totalPrice.currencyCode,
        },
        paymentMethodInfo: {
          method: 'purchase-order',
        },
        custom: {
          type: {
            typeId: 'type',
            key: 'payment-type',
          },
          fields: {
            poNumber: methodValue.poNumber,
            invoiceEmailAddress: methodValue.invoiceEmailAddress,
          },
        },
      }
    }
    return client.axios.post(URL_PAYMENTS, data)
  }

  return {
    info,
    updateUserInfo,
    setShippingAddress,
    setDefaultShippingAddress,
    setBillingAddress,
    setDefaultBillingAddress,
    addAddress,
    updateAddress,
    removeAddress,
    addContact,
    updateContact,
    removeContact,
    getLabNotebooks,
    getOrderHistory,
    getOrderByOrderNumber,
    createPayment,
  }
}
