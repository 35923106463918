// Composition
import { Ref, ref } from '@nuxtjs/composition-api'

// Store
import { useSearchStore } from '@/store/search'

// Variable that is used in multiple places
// I get strange TS errors when storing this in Pinia
// Object can't be a null, even if I set a check for not null before the line
const selectedIndex = ref(null) as Ref<number | null>

// set all selected to false
// set selectedIndex to null
const clearSuggestSelected = () => {
  const searchStore = useSearchStore()
  selectedIndex.value = null
  searchStore.clearSuggestSelected()
  searchStore.hideSuggests()
}

export { selectedIndex, clearSuggestSelected }
