







































// Composition
import { defineComponent } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

// Composable
import useBasket, { toggleShowBasket, showBasket, basketItems } from '@/composables/checkout/useBasket'
import useEcommerceAnalytics from '@/composables/analytics/useEcommerceAnalytics'

// Stores
import { useCheckoutStore } from '@/store/checkout'

// Components
import BasketSidebar from '@/components/feature/basket/BasketSidebar.vue'

export default defineComponent({
  components: {
    BasketSidebar,
  },

  setup() {
    const { trackViewCart } = useEcommerceAnalytics()
    const { showBasketPopup, itemsAdded } = storeToRefs(useCheckoutStore())
    // When a user clicks on the Basket button in the topbar
    const showBasketSidebar = () => {
      toggleShowBasket(true)

      // Send GTM event
      // send no items along with the event (null). In the composable, we check for this
      // if it is null, we get the items from the current basket
      trackViewCart('basket_main_nav')
    }

    const { basketTotalString } = useBasket()
    return {
      showBasket,
      basketItems,
      basketTotalString,
      showBasketPopup,
      itemsAdded,
      showBasketSidebar,
    }
  },
})
