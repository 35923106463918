import { Context } from '@nuxt/types'
import { useContext, computed } from '@nuxtjs/composition-api'
import { useCountry } from '../country/useCountry'
import { useUserStore } from '~/store'
import { useCMSGlobalSettingsStore } from '~/store/cms'

export function useQuotePageType(ctx?: Context) {
  const context = ctx ?? useContext()
  const userStore = useUserStore(context.$pinia)
  const cmsStore = useCMSGlobalSettingsStore(context.$pinia)
  const { isQuotesCountry } = useCountry()

  return computed(() => {
    if (
      cmsStore
        .getAttributeByCountry('b2dAllowedCountries')
        .value.map((c) => c.toLowerCase())
        .includes(userStore.selectedCountry.toLowerCase())
    ) {
      return 'lead'
    }
    if (isQuotesCountry.value) {
      return 'quote'
    }
    return null
  })
}
