import { computed } from '@nuxtjs/composition-api'
import useWindowSize from './useWindowSize'

export function useMobile() {
  const windowSize = useWindowSize()
  const isTablet = computed(() => windowSize.x.value < 1024 && windowSize.x.value !== 0)
  const isMobile = computed(() => windowSize.x.value < 768 && windowSize.x.value !== 0)
  const isSmallMobile = computed(() => windowSize.x.value < 640 && windowSize.x.value !== 0)

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    width: windowSize.x,
    height: windowSize.y,
  }
}
