import { CustomAxiosRequestConfig, CustomAxiosResponse, CustomAxiosError, CUSTOM_AXIOS_ERRORS } from '@cstweb/common'
import { Client } from '../client'

import {
  URL_PDF,
  URL_SIGNALSTAR,
  REQUEST_NUMBER_OF_RETRIES,
  URL_CLIENT_TOKEN,
  URL_ANONYMOUS_TOKEN,
  URL_CUSTOMERS_TOKEN,
  URL_REVOKE_TOKEN,
  URL_ACTIVE_CART,
} from '../util/constants'

const getErrorType = (config?: CustomAxiosRequestConfig) => {
  if (config?.url?.startsWith(URL_SIGNALSTAR)) {
    return CUSTOM_AXIOS_ERRORS.SignalStarAxiosError
  }

  if (config?.url?.startsWith(URL_PDF)) {
    return CUSTOM_AXIOS_ERRORS.PdfAxiosError
  }

  return CUSTOM_AXIOS_ERRORS.CommerceToolsAxiosError
}

const shouldReportError = (config?: CustomAxiosRequestConfig, response?: CustomAxiosResponse): boolean => {
  // console.log('🚀 ~ shouldReportError ~ shouldReportError: >>>')

  let shouldReport = true

  if (config && response) {
    if (config.method === 'get' && response.status === 404 && config.url && [URL_ACTIVE_CART].includes(config.url)) {
      shouldReport = false
    }
  }
  return shouldReport
}

const shouldReportResponse = (response: CustomAxiosResponse): boolean => {
  // console.log('🚀 ~ shouldReportResponse ~ shouldReportResponse: >>>')

  const { config } = response
  let shouldReport = false

  // FIXME: Fix this in auth server middleware logging
  if (
    config.url &&
    [URL_CLIENT_TOKEN, URL_ANONYMOUS_TOKEN, URL_CUSTOMERS_TOKEN, URL_REVOKE_TOKEN].includes(config.url)
  ) {
    shouldReport = true
  }
  return shouldReport
}

export const LogRequestMiddleware = (_client: Client) => {
  return {
    onRequest(config: any) {
      console.debug('CTC: onRequest: ', config.url)
      return config
    },
    onResponse(response: CustomAxiosResponse) {
      console.debug('CTC: onResponse 2: ', response.config.url)
      return response
    },
    onResponseError(error: CustomAxiosError): Promise<any> {
      console.debug('@@@ 1')

      throw error
    },
  }
}

export const LogResponseMiddleware = (client: Client) => {
  const LogService = client.logService
  return {
    onResponse(response: CustomAxiosResponse) {
      const { config } = response
      console.debug('CTC: onResponse: ', config.url)

      if (LogService && shouldReportResponse(response)) {
        try {
          console.debug('Sending Response')
          LogService.sendInfo(response)
        } catch (e) {
          console.error(e)
        }
      }

      return response
    },
    onResponseError(error: CustomAxiosError): Promise<any> {
      console.debug('@@@ 3')
      const { config, response }: { config?: CustomAxiosRequestConfig; response?: CustomAxiosResponse } = error
      if (!config) {
        console.debug('CTC: onResponseError: NO AxiosError')
        console.error(error)
      } else {
        console.debug('CTC: onResponseError: ', error.config.url)
        // console.error(error)
        // console.error(error.toJSON())
      }

      if (LogService && shouldReportError(config, response)) {
        try {
          console.debug('Sending Error')
          console.debug(error)
          LogService.sendError({ ...error, type: getErrorType(config) })
        } catch (e) {
          console.error(e)
        }
      }

      throw error
    },
  }
}
