import { computed } from '@nuxtjs/composition-api'
import { Pinia, storeToRefs } from 'pinia'
import { useRenderPerCountry } from './useRenderPerCountry'
import { useCMSGlobalSettingsStore, useUserStore } from '~/store'
import countries from '@/static/countries.json'
import { euCountryCodes } from '@/common/static/countries'

export function useCountry(pinia?: Pinia) {
  const { selectedCountry, selectedLanguage, selectedCurrency } = storeToRefs(useUserStore(pinia))
  const { getAttributeByCountry } = useCMSGlobalSettingsStore()
  const b2dAllowedAddToBasket = getAttributeByCountry('b2dAllowedCountries')

  const isJapan = computed(() => selectedCountry.value === 'JP')
  const isUS = computed(() => selectedCountry.value === 'US')
  const isB2DCountry = computed(() => b2dAllowedAddToBasket.value.includes(selectedCountry.value.toLowerCase()))
  const isQuotesCountry = useRenderPerCountry(['AT', 'DE', 'GB', 'US', 'JP'], selectedCountry.value).showForCountry
  const isAT = useRenderPerCountry(['AT']).showForCountry
  const isDE = useRenderPerCountry(['DE']).showForCountry
  const isGB = useRenderPerCountry(['GB']).showForCountry
  const isEU = useRenderPerCountry(euCountryCodes).showForCountry
  const isCN = useRenderPerCountry(['CN']).showForCountry
  const isBENELUX = useRenderPerCountry(['BE', 'NL', 'LU']).showForCountry
  const isPunchoutCountries = useRenderPerCountry(['AT', 'NL', 'GB', 'DE', 'US']).showForCountry
  const isCA = useRenderPerCountry(['CA']).showForCountry
  const isAU = useRenderPerCountry(['AU']).showForCountry
  const isNZ = useRenderPerCountry(['NZ']).showForCountry
  const isFR = useRenderPerCountry(['FR']).showForCountry
  const isEMEA = useRenderPerCountry(['AT', 'DE', 'GB']).showForCountry

  const countryNameFactory = (countryCode: string = 'US') => {
    const countryName = countries.filter((country) => country.value === countryCode).pop()
    const formatedCountryCode = ` (${countryCode === 'US' ? 'USA' : countryCode})`

    return (countryName?.label ?? '') + formatedCountryCode
  }

  const getLocaleCode = () => {
    // China
    if (selectedCountry.value === 'CN') {
      return 'zh-cn'
    }

    // Japan
    if (selectedCountry.value === 'JP') {
      if (selectedLanguage.value === 'jp') {
        return 'ja-jp'
      }
    }

    // Default
    return 'en-us'
  }

  return {
    selectedCountry,
    selectedLanguage,
    selectedCurrency,
    isUS,
    isJapan,
    isB2DCountry,
    countryNameFactory,
    isEU,
    isAT,
    isDE,
    isGB,
    isCN,
    getLocaleCode,
    isBENELUX,
    isCA,
    isAU,
    isNZ,
    isFR,
    isEMEA,
    isPunchoutCountries,
    isQuotesCountry,
  }
}
