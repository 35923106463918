import { storeToRefs } from 'pinia'
import isEmpty from 'lodash/fp/isEmpty'

// Common
import { facetObjects, nestedFacets, FACETNAMES } from '@/common/static/facets'

// Store
import { useSearchStore } from '~/store/search'

// Types
import { StaticFacetsInterface, FacetInterface } from '@/types/search/searchTypes'

export default () => {
  const { getResults: results, facets } = storeToRefs(useSearchStore())

  // CHeck if we have a match in the pretty name or the facet name
  const mapToFacetNameCondition = (obj: StaticFacetsInterface, str: string): boolean => {
    return obj.facetName.toLowerCase() === str.toLowerCase() || obj.facetPrettyName?.toLowerCase() === str.toLowerCase()
  }

  // Mapper method to find the pretty name of a facet or the slug in both the nested and refular facets
  const mapToFacetName = (str: string = '', type: string): string => {
    if (isEmpty(str)) {
      return str
    }

    // For regular facets
    let foundFacet = []

    // If nested facet
    if (nestedFacets.includes(str)) {
      foundFacet = facetObjects
        .flatMap((facet: StaticFacetsInterface) => {
          return facet.children !== undefined
            ? facet.children.filter((child) => mapToFacetNameCondition(child, str))
            : null
        })
        .filter((el) => el !== null)
    }
    // This is a regular facet
    else {
      foundFacet = facetObjects.filter((facet: StaticFacetsInterface) => mapToFacetNameCondition(facet, str))
    }

    if (
      foundFacet.length > 0 &&
      foundFacet[0] !== null &&
      foundFacet[0] !== undefined &&
      foundFacet[0].facetPrettyName !== undefined
    ) {
      return type === FACETNAMES.PRETTY_FACET_NAME ? foundFacet[0].facetPrettyName : foundFacet[0].facetName
    }
    return str
  }

  // helper func adjustCheckedFacets
  const existingFacetIndex = (name: string) => {
    if (isEmpty(facets.value.checkedFacets)) {
      return -1
    }

    // if the facet is other, search for all nested facets
    if (name === 'other' && nestedFacets.length > 0) {
      let foundNestedIndex = -1
      nestedFacets.forEach((nestedName) => {
        if (nestedName !== undefined) {
          const checkNestedFacet = facets.value.checkedFacets.findIndex((el: Object) =>
            Object.keys(el).includes(nestedName)
          )
          if (checkNestedFacet !== -1) {
            foundNestedIndex = checkNestedFacet
          }
        }
      })
      return foundNestedIndex
    } else {
      // Find a regular facet
      return facets.value.checkedFacets.findIndex((el: Object) => {
        return Object.keys(el).includes(name)
      })
    }
  }

  // optional class func
  const facetSelected = (name: string, value: string): boolean => {
    // Check regular facets and if the value is selected
    const existIndex = existingFacetIndex(name)
    if (existIndex === -1) return false
    return facets.value.checkedFacets[existIndex][name].includes(value)
  }

  const deleteFacet = (name: string) => {
    if (name === 'other') {
      // delete all nested facets
      nestedFacets.forEach((nestedName) => {
        if (nestedName !== undefined) {
          deleteCheckedFacet(nestedName)
        }
      })
    } else {
      deleteCheckedFacet(name)
    }
  }

  const facetKeySelected = (key: string) => {
    return facets.value.checkedFacets.map((obj) => Object.keys(obj)[0]).includes(key)
  }

  const deleteCheckedFacet = (name: string) => {
    const existIndex = existingFacetIndex(name)
    if (existIndex !== -1) {
      // delete the facet itself
      facets.value.checkedFacets.splice(existIndex, 1)
    }
  }

  const deleteFacetValue = (name: string, value: string) => {
    const existIndex = existingFacetIndex(name)

    if (existIndex !== -1) {
      // loop trough the values and check if we need to clear children selected facets as well
      facets.value.checkedFacets[existIndex][name].forEach((selectedFacetValue: string, index: number) => {
        if (selectedFacetValue.toLowerCase() === value.toLowerCase()) {
          facets.value.checkedFacets[existIndex][name].splice(index, 1)
        }
      })

      // possibly delete the facet itself
      if (isEmpty(facets.value.checkedFacets[existIndex][name])) {
        facets.value.checkedFacets.splice(existIndex, 1)
      }
    }
  }

  const isHierarchical = (
    name: string,
    facets: Array<StaticFacetsInterface> = facetObjects,
    selectorIndexPath: Array<number> = []
  ) => {
    for (const [index, node] of facets.entries()) {
      // only level 1
      if (node.facetName === name) {
        selectorIndexPath.push(index)
      }

      // possibly deeper levels
      if (node.parentValue === name) {
        selectorIndexPath.push(index)
      }

      // if nothing found, try again recursively
      if (node.children !== undefined) {
        isHierarchical(name, node.children, selectorIndexPath)
      }
    }
    if (selectorIndexPath.length > 0) {
      return selectorIndexPath
    } else {
      return false
    }
  }

  const getSelectedFacetCount = (key: string, value: string) => {
    if (results.value.facets === undefined) {
      return
    }

    // get the count from the facets for this facetvalue
    const selectedFacet = results.value.facets![key][0].data?.filter((facet: FacetInterface) => facet.value === value)

    // If we can't find any, this facet is not in the current set
    // just return a zero
    if (selectedFacet !== undefined && selectedFacet.length > 0) {
      return selectedFacet[0].count
    } else {
      return 0
    }
  }

  // listen to checked facets
  const adjustCheckedFacets = (name: string, value: string | Array<string>): void => {
    // check if this facet object exists
    const existIndex = existingFacetIndex(name)
    const isArray = typeof value !== 'string'

    // if so, add or remove
    if (existIndex !== -1) {
      // if it is a value, find it in the facetArray and delete/add it
      if (!isArray) {
        // if so, check if this value exists
        if (facets.value.checkedFacets[existIndex][name].includes(value)) {
          // if so, remove from array
          facets.value.checkedFacets[existIndex][name].splice(
            facets.value.checkedFacets[existIndex][name].indexOf(value),
            1
          )

          // delete whole facet is choice is 0
          if (isEmpty(facets.value.checkedFacets[existIndex][name])) {
            facets.value.checkedFacets.splice(existIndex, 1)
          }
        } else {
          // add if to the array
          facets.value.checkedFacets[existIndex][name].push(value)
        }
      }
      // if the facet exist, overwrite with the inserted array
      else {
        facets.value.checkedFacets[existIndex][name] = value
      }
    }
    // facet does not exist, add object + value
    else if (!isArray) {
      // add facet object to arr
      facets.value.checkedFacets.push({
        [name]: [value],
      })
    } else {
      facets.value.checkedFacets.push({
        [name]: value,
      })
    }
  }

  const addFacet = (name: string, value: string | Array<string>) => {
    // if this facet exists, do nothing
    if (existingFacetIndex(name) !== -1) {
      return
    }

    const isArray = typeof value !== 'string'
    if (!isArray) {
      facets.value.checkedFacets.push({
        [name]: [value],
      })
    } else {
      facets.value.checkedFacets.push({
        [name]: value,
      })
    }
  }

  return {
    deleteFacet,
    deleteFacetValue,
    facets,
    mapToFacetName,
    facetSelected,
    facetKeySelected,
    adjustCheckedFacets,
    addFacet,
    isHierarchical,
    existingFacetIndex,
    getSelectedFacetCount,
  }
}
