






















































import { defineComponent, ref, watch, onMounted, computed } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { v4 as uuid } from 'uuid'
import IconFaSolidMinus from '~icons/fa-solid/minus'
import IconFaSolidPlus from '~icons/fa-solid/plus'

import { useCheckoutStore } from '@/store/checkout'
import { useI18n } from '~/composables/i18n'
import { mapExceptionToError } from '~/composables/errors/useErrors'
import { BasketItemsDataInterface } from '@/types/checkout/checkoutTypes'

export default defineComponent({
  components: { IconFaSolidMinus, IconFaSolidPlus },
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number as () => number | null,
      default: null,
    },
    productSKU: {
      type: String,
      default: '',
    },
    isQtyTriggerDisabled: {
      type: Boolean,
      default: false,
    },
    keepOldValue: {
      type: Boolean,
      default: false,
    },
    minAmount: {
      type: Number,
      default: 1,
      required: false,
    },
    lineItems: {
      type: Object as () => BasketItemsDataInterface | undefined,
      required: false,
      default: undefined,
    },
  },
  emits: ['inputValueChanged', 'qtyInputMounted', 'delete'],
  setup(props, context) {
    let oldValue = 0 as number | ''
    let debounceTimeout: ReturnType<typeof setTimeout> | null = null

    const checkout = useCheckoutStore()
    const { changeBasketItemQuantity, clearErrors, setErrors } = checkout
    const { errors } = storeToRefs(checkout)
    const qty = ref<number | ''>(props.value ?? '')

    const qtyDisabled = computed(() => props.disabled || (props.productSKU === '' && !props.isQtyTriggerDisabled))
    const debounceTime = 400

    const { t } = useI18n()

    const inputId = uuid()

    const increment = () => {
      oldValue = qty.value
      if (qty.value === '') {
        qty.value = 0
      }
      qty.value++
      inputChange()
    }

    const decrement = () => {
      oldValue = qty.value
      if (qty.value !== '') {
        qty.value--
        inputChange()
      }
    }

    const getOldValue = () => {
      oldValue = qty.value
    }

    const setOldValue = () => {
      if (qty.value !== oldValue) {
        qty.value = oldValue
        inputChange()
      }
    }

    const onlyNumber = (event: { keyCode: number; which: number; preventDefault: () => void }) => {
      const keyCode = event.keyCode ? event.keyCode : event.which

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault()
      }

      if (props.large && qty.value === 0 && keyCode === 48) {
        event.preventDefault()
        qty.value = 1
      }
    }

    const inputChange = () => {
      // Delete
      if (qty.value === 0 && props.minAmount > 0) {
        context.emit('delete')
        qty.value = oldValue
        return
      }

      // Empty check
      if (qty.value === '') {
        return
      }

      // Emit the new value
      context.emit('inputValueChanged', typeof qty.value === 'string' ? null : qty.value)

      // When we need to keep the old value, please keep the emit above this part
      if (props.keepOldValue) {
        qty.value = oldValue
        return
      }

      // Set new oldValue
      oldValue = qty.value

      // Update the cart if we have an SKU and isQtyTriggerDisabled is false
      if (props.productSKU !== '' && !props.isQtyTriggerDisabled) {
        if (debounceTimeout) {
          clearTimeout(debounceTimeout)
        }
        debounceTimeout = setTimeout(async () => {
          try {
            clearErrors()
            // Preventing call with empty value after spamming backspace
            if (qty.value !== '') {
              if (props.lineItems) {
                await changeBasketItemQuantity(props.productSKU, getCalculatedQty(props.lineItems, props.productSKU))
              }
            }
          } catch (e) {
            console.error(e)
            setErrors(
              'quantityInput',
              mapExceptionToError(
                e,
                'An error occurred while trying to add products to the cart. Please try again later or contact customer service.'
              ),
              inputId
            )
          }
        }, debounceTime)
      }
    }

    const getCalculatedQty = (lineItems: BasketItemsDataInterface, sku: string) => {
      let diff = 0
      if (qty.value !== '' && props.value) {
        diff = qty.value - props.value
      }
      const quantitySum = Object.values(lineItems)
        .filter((item) => item.id === sku)
        .reduce((sum, item) => sum + item.quantity, 0)

      return quantitySum + diff
    }

    watch(props, () => {
      qty.value = props.value ?? ''
      oldValue = qty.value
    })

    onMounted(() => {
      context.emit('qtyInputMounted')
    })

    return {
      qty,
      oldValue,
      errors,
      qtyDisabled,
      increment,
      decrement,
      onlyNumber,
      inputChange,
      getOldValue,
      setOldValue,
      changeBasketItemQuantity,
      t,
      inputId,
    }
  },
})
