import { PRODUCT_CATEGORIES, PRODUCT_STATUS } from '../utils/product/constants'
import { NESTED_FACET_NAMES } from '@/common/static/search'

import { StaticFacetsInterface } from '@/types/search/searchTypes'

const facetObjects: Array<StaticFacetsInterface> = [
  {
    facetName: 'categories',
    facetPrettyName: 'Product Category',
    popularFacet: true,
  },
  {
    facetName: 'applications',
    facetPrettyName: 'Applications',
    popularFacet: true,
    multiSelect: true,
  },
  {
    facetName: 'speciesreactivity',
    facetPrettyName: 'Species Reactivity',
    popularFacet: true,
    multiSelect: true,
  },
  {
    facetName: 'hostspecies',
    facetPrettyName: 'Host Species',
    popularFacet: true,
    multiSelect: true,
  },
  {
    facetName: 'producttype',
    facetPrettyName: 'Product Type',
    popularFacet: true,
    multiSelect: true,
  },
  {
    facetName: 'targetmodifications',
    facetPrettyName: 'Modification Type',
    popularFacet: true,
    multiSelect: true,
  },
  {
    facetName: 'conjugationtype',
    facetPrettyName: 'Conjugation Type',
    popularFacet: true,
    multiSelect: true,
  },
  {
    facetName: 'formulation',
    facetPrettyName: 'Formulation',
  },
  {
    facetName: NESTED_FACET_NAMES.other.key,
    facetPrettyName: NESTED_FACET_NAMES.other.value,
    children: [
      {
        facetPrettyName: 'Epitope Tag',
        facetName: 'epitopetag',
      },
      {
        facetPrettyName: 'New Products',
        facetName: 'newproduct',
      },
      {
        facetPrettyName: 'Recombinant',
        facetName: 'recombinant',
      },
      {
        facetPrettyName: 'Trial Size Available',
        facetName: 'trialsizeavailable',
      },
      {
        facetPrettyName: 'XP®',
        facetName: 'xp',
      },
    ],
  },
]

// return a facet array with level 1 facets
const showFacetsArray = facetObjects
  .filter((el) => el.popularFacet !== undefined && el.popularFacet === true)
  .map((facet) => facet.facetName)

// find the facets that need to be hidden
const hiddenFacets = facetObjects.filter((el) => el.popularFacet === undefined).map((facet) => facet.facetName)

// Array with all nested facets
const nestedFacets = facetObjects
  .filter((el) => el.children !== undefined)
  .flatMap((facet) => {
    return facet.children?.map((child) => child.facetName)
  })

// just show all facets
const showAllFacetsArray = facetObjects.map((facet) => {
  if (facet.children === undefined) {
    return facet.facetName
  } else {
    return {
      [facet.facetName]: facet.children,
    }
  }
})

const showAllFacetsArrayFlat = facetObjects.flatMap((facet) => {
  if (facet.children === undefined) {
    return facet.facetName
  } else {
    return facet.children.flatMap((child) => child.facetName)
  }
})

// find the facets that are multi select
const multiSelectFacets = facetObjects.filter((facet) => facet.multiSelect).map((facet) => facet.facetName)

const facetStatusArray = [
  PRODUCT_STATUS.RELEASED,
  PRODUCT_STATUS.PENDING,
  PRODUCT_STATUS.RELEASED_ON_HOLD,
  PRODUCT_STATUS.PRE_DISCONTINUED,
  // 'Released',
  // 'Pending',
  // 'Released - On Hold',
  // 'Pre-discontinued',
  // 'Discontinued',
  // 'Obsolete',
  // 'Pre-released',
]

const categoriesOrder = [
  PRODUCT_CATEGORIES.PRIMARY_ANTIBODIES,
  PRODUCT_CATEGORIES.SECONDARY_ANTIBODIES,
  PRODUCT_CATEGORIES.ANTIBODY_CONJUGATES,
  PRODUCT_CATEGORIES.ACTIVATORS_AND_INHIBITORS,
  PRODUCT_CATEGORIES.BUFFERS_AND_DYES,
  PRODUCT_CATEGORIES.CELLULAR_ASSAY_KITS,
  PRODUCT_CATEGORIES.CHIP_KITS_AND_REAGENTS,
  PRODUCT_CATEGORIES.CUT_AND_RUN_KITS_AND_REAGENTS,
  PRODUCT_CATEGORIES.CUT_AND_TAG_KITS_AND_REAGENTS,
  PRODUCT_CATEGORIES.CYTOKINES_AND_GROWTH_FACTORS,
  PRODUCT_CATEGORIES.ELISA_KITS,
  PRODUCT_CATEGORIES.ANTIBODY_ARRAYS,
  PRODUCT_CATEGORIES.EXPERIMENTAL_CONTROLS,
  PRODUCT_CATEGORIES.FLOW_CYTOMETRY_KITS_AND_REAGENTS,
  PRODUCT_CATEGORIES.FLOW_CYTOMETRY_PANELS,
  PRODUCT_CATEGORIES.INTRASEQ_KITS_AND_REAGENTS,
  PRODUCT_CATEGORIES.INTRASEQ_KITS_AND_REAGENTS_HTML,
  PRODUCT_CATEGORIES.PROTEOMIC_ANALYSIS_PRODUCTS,
  PRODUCT_CATEGORIES.RECOMBINANT_PROTEINS,
  PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS,
  PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS_HTML,
  PRODUCT_CATEGORIES.SIRNA,
  PRODUCT_CATEGORIES.WB_AND_IP_REAGENTS,
]

const FACETNAMES = {
  PRETTY_FACET_NAME: 'pretty',
  SLUG_FACET_NAME: 'slug',
}

const noneFilterArray = [
  {
    categories: ['', ' ', null, undefined],
  },
] as const

export {
  FACETNAMES,
  facetObjects,
  hiddenFacets,
  nestedFacets,
  showFacetsArray,
  showAllFacetsArray,
  showAllFacetsArrayFlat,
  facetStatusArray,
  categoriesOrder,
  multiSelectFacets,
  noneFilterArray,
}
