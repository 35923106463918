import { Context } from '@nuxt/types'
import { EvaluatedFeatures, FeatureDefinitions } from '~/features/features'
import { useFeatureStore } from '~/store/featureFlags'

type Options<TFeatureKey extends keyof FeatureDefinitions> = {
  expectedValue?: EvaluatedFeatures[TFeatureKey]['value']
  redirectTo?: string
  staleTime?: number // in seconds
}

export function featureAccess<TFeatureKey extends keyof FeatureDefinitions>(
  featureKey: TFeatureKey,
  options?: Options<TFeatureKey>
) {
  return async (ctx: Context) => {
    const featureStore = useFeatureStore(ctx.$pinia)

    if (process.client) {
      // If stale, revalidate on client side
      await featureStore.fetchIfStale(featureKey, options?.staleTime)
    }

    // On the server, all values were fetched and saved to the feature store
    // inside the server plugin. So we can just use the value from the store.
    const realValue = featureStore.featureFlags![featureKey].value
    if (options?.expectedValue !== undefined) {
      if (realValue !== options.expectedValue) {
        ctx.redirect(options?.redirectTo ?? '/')
      }
      return
    }

    if (!realValue) {
      ctx.redirect(options?.redirectTo ?? '/')
    }
  }
}
