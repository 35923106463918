import { APIError } from 'src/middleware/error-mapping'

export interface DiscountCodeNonApplicableError extends APIError {
  code: 'DiscountCodeNonApplicable'
  reason: string
  validFrom: string
  validUntil: string
  discountCode: string
  action: {
    action: string
    code: string
  }
}

function isDiscountCodeNonApplicableError(error: APIError): error is DiscountCodeNonApplicableError {
  return error.code === 'DiscountCodeNonApplicable'
}

export function mapDiscountCodeNonApplicableError(error: APIError): string {
  if (isDiscountCodeNonApplicableError(error)) {
    if (error.reason === 'DoesNotExist') {
      return `Promotion code ${error.discountCode} not recognized. Please try again`
    } else {
      return `Promotion code ${error.discountCode ?? error.action.code} has expired`
    }
  }

  return error.message
}
