import differenceInDays from 'date-fns/fp/differenceInDays'
import { CookieComplexStore } from '../util/cookie'
import {
  AUTH_STORE_FIELD,
  CART_STORE_FIELD,
  CUSTOMER_STORE_FIELD,
  EXPAND_DISCOUNT_CODE,
  EXPAND_PAYMENTINFO,
  EXPAND_LINE_ITEM_DISCOUNT_PER_QTY,
  EXPAND_LINE_ITEM_DISCOUNT_PRICE,
  EXPAND_STATE,
  EXPAND_LINE_ITEM_STATE,
  URL_CARTS,
  TOKEN_FLOW,
  EXPAND_VARIANT_ATTRIBUTES_VALUES,
  EXPAND_VARIANT_ATTRIBUTES_VALUE,
  EXPAND_MASTER_VARIANT_ATTRIBUTES_VALUES,
  EXPAND_MASTER_VARIANT_ATTRIBUTES_VALUE,
  EXPAND_CATEGORIES,
  EXPAND_LINE_ITEM_VARIANT_ATTRIBUTES_VALUE,
} from './constants'

export const setAuthHeaders = ({ config, authCookieStore }: any) => {
  // console.log('🚀 ~ setAuthHeaders ~ setAuthHeaders')
  config.headers = config.headers || {}
  config.headers.Authorization = `Bearer ${authCookieStore.get(AUTH_STORE_FIELD.ACCESS_TOKEN)}`
}

export const hasValidToken = (authCookieStore: CookieComplexStore, url: string): boolean => {
  if (!authCookieStore.get(AUTH_STORE_FIELD.ACCESS_TOKEN)) {
    return false
  }

  /**
   * FIXME: Remove undefined below after ATG/Netrex is turned off
   * for backward compatibility: check for undefined below is for ATG/Netrex, since those do not set the flow field in ct-auth cookie
   */
  if (
    url.startsWith(URL_CARTS) &&
    ![TOKEN_FLOW.ANONYMOUS, TOKEN_FLOW.CUSTOMER, TOKEN_FLOW.PASSWORD, undefined].includes(
      authCookieStore.get(AUTH_STORE_FIELD.FLOW) as string
    )
  ) {
    return false
  }

  return true
}

export const hasToken = (authCookieStore: CookieComplexStore): boolean => {
  return !!authCookieStore.get(AUTH_STORE_FIELD.ACCESS_TOKEN)
}

export const hasRefreshToken = (authCookieStore: CookieComplexStore): boolean => {
  return !!authCookieStore.get(AUTH_STORE_FIELD.REFRESH_TOKEN)
}

export const isCreatingCart = (cartCookieStore: CookieComplexStore): boolean => {
  return (cartCookieStore.get(CART_STORE_FIELD.IS_CREATING) || false) as boolean
}

export const hasCart = (cartCookieStore: CookieComplexStore): boolean => {
  if (!cartCookieStore.get(CART_STORE_FIELD.CART_ID)) {
    return false
  }
  return true
}

export const hasCustomer = (customerCookieStore: CookieComplexStore): boolean => {
  if (!customerCookieStore.get(CUSTOMER_STORE_FIELD.CUSTOMER_ID)) {
    return false
  }
  return true
}

export const hasPunchoutCustomer = (customerCookieStore: CookieComplexStore): boolean => {
  return !!customerCookieStore.get(CUSTOMER_STORE_FIELD.IS_PUNCHOUT)
}

export const isCartOlderThanXDays = (cartCookieStore: CookieComplexStore, numOfDays = 1) => {
  const lastModifiedAt = cartCookieStore.get(CART_STORE_FIELD.LAST_MODIFIED_AT) as string
  return lastModifiedAt ? differenceInDays(new Date(lastModifiedAt), new Date()) >= numOfDays : false
}

// TODO: Rename to addCartExpandParams
export const addExpandParams = (initParams: URLSearchParams): URLSearchParams => {
  const expandParams = new URLSearchParams(initParams)
  expandParams.append('expand', EXPAND_LINE_ITEM_DISCOUNT_PER_QTY)
  expandParams.append('expand', EXPAND_LINE_ITEM_DISCOUNT_PRICE)
  expandParams.append('expand', EXPAND_DISCOUNT_CODE)
  expandParams.append('expand', EXPAND_PAYMENTINFO)
  expandParams.append('expand', EXPAND_STATE)
  expandParams.append('expand', EXPAND_LINE_ITEM_STATE)
  expandParams.append('expand', EXPAND_LINE_ITEM_VARIANT_ATTRIBUTES_VALUE)

  return expandParams
}

export const addProductExpandParams = (initParams: URLSearchParams): URLSearchParams => {
  const expandParams = new URLSearchParams(initParams)
  expandParams.append('expand', EXPAND_MASTER_VARIANT_ATTRIBUTES_VALUE)
  expandParams.append('expand', EXPAND_MASTER_VARIANT_ATTRIBUTES_VALUES)
  expandParams.append('expand', EXPAND_VARIANT_ATTRIBUTES_VALUE)
  expandParams.append('expand', EXPAND_VARIANT_ATTRIBUTES_VALUES)
  expandParams.append('expand', EXPAND_CATEGORIES)

  return expandParams
}
