import { EvaluateFeatureCommandOutput, EvaluationResult } from '@aws-sdk/client-evidently'
import { EvaluateOption, FetchFeatureResult, FetchManyOptions } from '~/types/featureFlags/featureFlagsPlugin'

export function mapEvaluationResult(
  data: EvaluationResult | EvaluateFeatureCommandOutput,
  featureName: string,
  evaluateBasedOn: EvaluateOption
): FetchFeatureResult {
  // The response contains an object with a single value, which contains the flag's value
  const featureValue = Object.values(data.value ?? {})[0]

  if (featureValue === undefined) {
    console.warn(
      `Feature ${featureName}, evaluated based on: ${evaluateBasedOn} was not found. ` +
        'Using null as a fallback value.'
    )
  }

  return {
    name: featureName,
    value: featureValue ?? null,
    evaluateBasedOn,
  }
}

export function mapBatchEvaluateResult(
  data: EvaluationResult[],
  featuresToFetch: FetchManyOptions
): FetchFeatureResult[] {
  const res: FetchFeatureResult[] = []
  for (let i = 0; i < data.length; i++) {
    const feature = featuresToFetch[i]
    const result = data[i]

    // The `feature` field is an ARN, so extract only the feature name, which is a last part of the ARN
    const featureName = result.feature?.split('/').at(-1)!

    res.push(mapEvaluationResult(result, featureName, feature.evaluateBasedOn))
  }

  return res
}
