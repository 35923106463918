










import { defineComponent } from '@nuxtjs/composition-api'

// Analytics
import useSocialAnalytics from '@/composables/analytics/useSocialAnalytics'

export default defineComponent({
  props: {
    social: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { trackSocialLinkClick } = useSocialAnalytics()

    const onSocialClick = () => {
      trackSocialLinkClick(props.social.link.href)
    }
    return {
      onSocialClick,
    }
  },
})
