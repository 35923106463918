import { APIError } from '@cstweb/commercetools-client'

export function isApiError(error: unknown): error is { response: { data: { errors: APIError[] } } } {
  const errors = (error as any)?.response?.data?.errors
  return (
    errors &&
    Array.isArray(errors) &&
    errors.every(
      (e) =>
        typeof e === 'object' &&
        e &&
        'code' in e &&
        typeof e.code === 'string' &&
        'message' in e &&
        typeof e.message === 'string'
    )
  )
}
