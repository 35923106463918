import { Context, Plugin } from '@nuxt/types'
import {
  SignalstarAssignedAntibody,
  BufferKitInterface,
  TargetSpecies,
  SignalstarPdfPayload,
  CreateExperimentReqPayload,
  SendExperimentEmailPayload,
} from '@cstweb/commercetools-client'
import { decodeHTMLEntities } from '~/common/utils/characters'

function createSignalStarPlugin({ $ct }: Context) {
  const generateSignalstarExperimentPdf = (
    experimentId: string,
    countryCode: string,
    language: string,
    imagingRounds: SignalstarAssignedAntibody[],
    bufferKit: BufferKitInterface,
    generate?: boolean
  ) => {
    // Escape product names
    imagingRounds = imagingRounds.map((round) => {
      // `imagingRound` object is passed from the component and is reactive.
      // Create a shallow copy to prevent rerendering of UI
      return {
        ...round,
        productName: round.productName.replaceAll('<sup>', '').replaceAll('</sup>', ''),
      }
    })
    bufferKit = { ...bufferKit, productName: bufferKit.productName.replaceAll('<sup>', '').replaceAll('</sup>', '') }

    const payload: SignalstarPdfPayload = {
      countryCode,
      language,
      requestItemType: 'panelBuilder',
      data: {
        imagingRounds,
        bufferKit,
        experimentId,
        generate,
      },
    }

    return $ct.pdf.createSignalstarPdf(JSON.parse(decodeHTMLEntities(JSON.stringify(payload)) as string))
  }

  // .. Data related
  const getSignalstarProducts = (targetSpecies: TargetSpecies, countryCode: string) => {
    return $ct.signalstar.getSignalstarProducts(targetSpecies, countryCode)
  }

  // .. Email Related
  const sendExperimentEmail = (payload: SendExperimentEmailPayload) => {
    return $ct.signalstar.sendExperimentEmail(payload)
  }

  // SS-Security update
  const createExperiment = (payload: CreateExperimentReqPayload) => {
    return $ct.signalstar.createExperiment(payload)
  }

  return {
    createExperiment,
    getSignalstarProducts,
    sendExperimentEmail,
    generateSignalstarExperimentPdf,
  }
}

const plugin: Plugin = (ctx, inject) => {
  inject('signalStar', createSignalStarPlugin(ctx))
}

export type SignalStarPlugin = ReturnType<typeof createSignalStarPlugin>

export default plugin
