import { isSameDay, isAfter, isBefore, isWithinInterval } from 'date-fns'

import {
  ContentRule,
  ContentTarget,
  Rule,
  Time,
  DateMatch,
  LogicalOperator,
  Configuration,
  RuleCondition,
  ConditionValue,
  ContentComponent,
} from '~/common/utils/parsers/cms/contentTarget'

import { ProductInterface } from '~/types/product/productTypes'

type ParsedDates = {
  currentDate: Date
  cmsDates: Date[]
}

export type ContentTargetConfig = {
  currentProduct: ProductInterface
  selectedCountry: string
}

export function getContentComponentsFromContentTarget(parsedContentTarget: ContentTarget, config: ContentTargetConfig) {
  const contentComponents = [...parsedContentTarget.contentComponents]

  parsedContentTarget.contentRules.forEach((rule) => {
    if (evaluateContentRule(rule, config)) {
      rule.contentComponents.forEach((ruleComponent: ContentComponent) => contentComponents.push(ruleComponent))
    }
  })
  return contentComponents
}

export function evaluateContentRule(contentRule: ContentRule, config: ContentTargetConfig) {
  const evaluations = contentRule.rules.map((rule) => evaluateRules(rule, config))
  return doLogicalOperationOnRules(evaluations, contentRule.logicalOperator)
}

function evaluateRules(rule: Rule, config: ContentTargetConfig) {
  const conf =
    typeof rule.configuration === 'boolean' ? rule.configuration : evaluateConfiguration(rule.configuration, config)
  const time = typeof rule.time === 'boolean' ? rule.time : evaluateTime(rule.time)
  // no logicalOperator in Rule, so both conf and time needs to be true
  return doLogicalOperationOnRules([conf, time], 'and')
}

function evaluateConfiguration(configuration: Configuration, config: ContentTargetConfig) {
  const conditions = configuration.conditions.map((condition) => evaluateCondition(condition, config))
  return doLogicalOperationOnRules(conditions, configuration.logicalOperator)
}

function evaluateCondition(condition: RuleCondition, config: ContentTargetConfig) {
  const conditionValues = condition.conditionValues.map((value) => evaluateConditionValue(value, config))
  return doLogicalOperationOnRules(conditionValues, condition.logicalOperator)
}

function evaluateConditionValue(conditionValue: ConditionValue, config: ContentTargetConfig) {
  const result = domainSwitch(conditionValue, config)
  return conditionValue.is_not ? !result : result
}

function domainSwitch(conditionValue: ConditionValue, config: ContentTargetConfig) {
  // TODO: Every time new domain is added in CMS, this switch needs to be expanded with new domain
  switch (conditionValue.domain) {
    case 'product_type': {
      return conditionValue.value === config.currentProduct.data.productType
    }
    case 'product_id': {
      return conditionValue.value === config.currentProduct.data.key
    }
    case 'product_ids': {
      return (conditionValue.value as string).split(',').includes(config.currentProduct.data.key)
    }
    case 'product_status': {
      return conditionValue.value === config.currentProduct.data.attributes.statusDates.status
    }
    case 'product_category': {
      return conditionValue.value === config.currentProduct.data.productCategory
    }
    case 'country_codes': {
      return conditionValue.value.includes(config.selectedCountry)
    }
    default: {
      throw new Error('Missing domain case in domainSwitch method')
    }
  }
}

function evaluateTime(time: Time) {
  const parsedDates: ParsedDates = {
    currentDate: new Date(new Date().toUTCString()),
    cmsDates: time.date.map((date: any) => new Date(new Date(date).toUTCString())),
  }
  const comparedDates = compareDatesBasedOnMatch(parsedDates, time.match)

  return time.is_not ? !comparedDates : comparedDates
}

function compareDatesBasedOnMatch(parsedDates: ParsedDates, match: DateMatch) {
  switch (match) {
    case 'on': {
      return isSameDay(parsedDates.currentDate, parsedDates.cmsDates[0])
    }
    case 'after': {
      return isAfter(parsedDates.currentDate, parsedDates.cmsDates[0])
    }
    case 'before': {
      return isBefore(parsedDates.currentDate, parsedDates.cmsDates[0])
    }
    case 'between': {
      return isWithinInterval(parsedDates.currentDate, { start: parsedDates.cmsDates[0], end: parsedDates.cmsDates[1] })
    }
  }
}

function doLogicalOperationOnRules(rules: boolean[], logicalOperator: LogicalOperator) {
  switch (logicalOperator) {
    case 'and': {
      return rules.every((rule) => rule)
    }
    case 'or': {
      return rules.some((rule) => rule)
    }
    // this is actually NOR logical operation, but the value coming from the CMS is 'not'
    case 'not': {
      return !rules.some((rule) => rule)
    }
  }
}
