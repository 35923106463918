import { computed } from '@nuxtjs/composition-api'
import { useUserStore } from '~/store'

export function useRenderPerCountry(countryCodes: string[] = [], countryCode?: string) {
  const userStore = useUserStore()

  const showForCountry = computed(() => countryCodes.includes(countryCode ?? userStore.selectedCountry))
  const hideForCountry = computed(() => !showForCountry.value)

  return { showForCountry, hideForCountry }
}
