import { Context } from '@nuxt/types'
import jwtDecode from 'jwt-decode'
import { detectServerBaseUrl, findCookieDomain, mergeCookieSettings } from '@cstweb/common'
import { constants } from '@cstweb/commercetools-client'
import { DecodedPunchoutToken } from '@/types/punchout/punchoutTypes'
import { useProfileStore } from '~/store/profile'
import { useCheckoutStore } from '~/store/checkout'

const ROUTE_NAME = 'ct-punchout'

const isPunchoutLoginUrl = (routeName: string | null = '') => {
  return ROUTE_NAME === routeName
}

const CLOUDFLARE_HEADERS = {
  CLIENT_IP: 'cf-connecting-ip',
  CLIENT_COUNTRY: 'cf-ipcountry',
  CLIENT_REGION_CODE: 'cf-region-code',
}

const COUNTRY_CODE = {
  IE: 'IE',
}

const REGION_CODE = {
  NIR: 'NIR',
}

export default async function (context: Context) {
  const { route, $ct, $logger, redirect, $cookies, req, $pinia } = context
  if (isPunchoutLoginUrl(route.name)) {
    const logGroup = { lgroup: 'punchout' }
    const logger = $logger.child({ ...logGroup })

    let goto = '/'

    const jwtToken = route.query.token
    const productId = route.query.productId

    // If URL does not contain JWT token, prevent decoding and redirect to home page
    if (!jwtToken) {
      logger.error('[punchout_login] missing url token')
      redirect(goto)
      return
    }

    const decodedToken: DecodedPunchoutToken = jwtDecode(jwtToken.toString())

    if (decodedToken.access_token) {
      // console.log('************************')
      // console.log(`CART ID: ${decodedToken.cart_id}`)
      // console.log(`TOKEN: ${decodedToken.access_token}`)
      // console.log('AUTH Cookie Value')
      // console.dir($ct.client.cookies.auth.getValue())
      // console.log('CART Cookie Value')
      // console.dir($ct.client.cookies.cart.getValue())
      // console.log('************************')
      logger.info({ cartId: decodedToken.cart_id, token: `*******${jwtToken.slice(-7)}` }, `[punchout_login]`)

      $ct.client.cookies.auth.setValue(
        Object.assign({}, $ct.client.cookies.auth.getValue(), {
          [constants.AUTH_STORE_FIELD.FLOW]: 'customer',
          [constants.AUTH_STORE_FIELD.ACCESS_TOKEN]: decodedToken.access_token,
          [constants.AUTH_STORE_FIELD.EXPIRES_IN]: decodedToken.expires_in,
        })
      )

      $ct.client.cookies.cart.setValue(
        Object.assign(
          {},
          $ct.client.cookies.cart.getValue(),
          { [constants.CART_STORE_FIELD.CART_ID]: 'punchout_cart' },
          { [constants.CART_STORE_FIELD.CART_ID]: decodedToken.cart_id }
        )
      )
      $ct.client.cookies.customer.setValue(
        Object.assign({}, $ct.client.cookies.customer.getValue(), {
          [constants.CUSTOMER_STORE_FIELD.CUSTOMER_ID]: 'punchout',
          [constants.CUSTOMER_STORE_FIELD.IS_PUNCHOUT]: true,
        })
      )

      $ct.client.cookies.auth.persist()
      // not calling $ct.client.cookies.cart.persist() nor $ct.client.cookies.customer.persist(),
      // since those are called in ct client middlewares, when fetchProfile and fetchBasket are executed

      let countryCode: string = 'US'
      let languageCode: string = 'en' // country/language code helper

      const host = process.server ? detectServerBaseUrl(req) : location.origin
      const cookieDomain = findCookieDomain(host ?? '', false) // Do not enable full domain cookies for c_country/c_language cookies
      const cookieSettings = mergeCookieSettings({ domain: cookieDomain })

      const domainParts = cookieDomain?.split('.') ?? []
      const tld = domainParts.length ? domainParts[domainParts.length - 1] : ''

      const customerIdCookie = $ct.client.cookies.customer.get(constants.CUSTOMER_STORE_FIELD.CUSTOMER_ID)
      const cartIdCookie = $ct.client.cookies.cart.get(constants.CART_STORE_FIELD.CART_ID)

      const countryCodeCookie: string = $cookies.get(constants.COOKIES.HYBRID_COUNTRY_CODE)
      const languageCodeCookie: string = $cookies.get(constants.COOKIES.HYBRID_LANGUAGE_CODE)

      const cfCountryCodeHeader = req?.headers[CLOUDFLARE_HEADERS.CLIENT_COUNTRY]?.toString().toUpperCase() ?? undefined
      const cfRegionCodeHeader =
        req?.headers[CLOUDFLARE_HEADERS.CLIENT_REGION_CODE]?.toString().toUpperCase() ?? undefined

      const profileStore = useProfileStore($pinia)
      const checkoutStore = useCheckoutStore($pinia)

      try {
        let profileData: any

        const isLoggedIn = customerIdCookie !== undefined
        if (isLoggedIn) {
          profileData = await profileStore.fetchProfile()
        }

        // handle c_country cookie
        if (profileData?.custom?.fields?.registrationOrigin) {
          countryCode = profileData.custom.fields.registrationOrigin
          setCountryCodeCookie($cookies, countryCode, cookieSettings)
        } else if (tld === 'jp' || tld === 'cn') {
          countryCode = tld === 'jp' ? 'JP' : 'CN'
          setCountryCodeCookie($cookies, countryCode, cookieSettings)
        } else if (route.query.country) {
          countryCode = (route.query.country as string) || countryCode
          setCountryCodeCookie($cookies, countryCode, cookieSettings)
        } else if (!countryCodeCookie && !cfCountryCodeHeader) {
          countryCode = 'US'
          setCountryCodeCookie($cookies, countryCode, cookieSettings)
        } else if (!countryCodeCookie && cfCountryCodeHeader) {
          countryCode = cfCountryCodeHeader

          if (cfRegionCodeHeader && cfRegionCodeHeader === REGION_CODE.NIR) {
            countryCode = COUNTRY_CODE.IE
          }

          setCountryCodeCookie($cookies, countryCode, cookieSettings)
        } else {
          countryCode = countryCodeCookie
        }

        // handle c_language cookie
        if (tld === 'jp' || tld === 'cn') {
          languageCode = tld === 'jp' ? 'jp' : 'cn'
          setLanguageCodeCookie($cookies, languageCode, cookieSettings)
        } else if (route.query.language) {
          languageCode = (route.query.language as string) || languageCode
          setLanguageCodeCookie($cookies, languageCode, cookieSettings)
        } else if (!languageCodeCookie) {
          languageCode = 'en'
          setLanguageCodeCookie($cookies, languageCode, cookieSettings)
        } else {
          languageCode = languageCodeCookie
          setLanguageCodeCookie($cookies, languageCode, cookieSettings)
        }

        const hasCart = cartIdCookie !== undefined
        if (hasCart) {
          await checkoutStore.fetchBasket()
        }
      } catch (error) {
        // console.error(error)
        $logger.error(error)
      }

      if (productId) {
        goto = `/products/${productId}`
      }

      logger.info({ token: `*******${jwtToken.slice(-7)}` }, `[punchout_login] redirect to: '${goto}'`)
      redirect(goto)
    } else {
      redirect(goto)
    }
  }
}

const setCountryCodeCookie = ($cookies: any, countryCode: string = 'US', cookieSettings: any) => {
  const cc = countryCode !== 'US' ? countryCode : 'USA' // FIXME: remove 'USA' after ATG Sunset
  $cookies.set(constants.COOKIES.HYBRID_COUNTRY_CODE, cc, cookieSettings)
}

const setLanguageCodeCookie = ($cookies: any, languageCode: string = 'en', cookieSettings: any) => {
  $cookies.set(constants.COOKIES.HYBRID_LANGUAGE_CODE, languageCode, cookieSettings)
}
