






































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { jsonToHtml } from '@contentstack/json-rte-serializer'
import { useCMSGlobalSettingsStore, useCMSNavigationStore } from '@/store/cms'
import { DEVKEY } from '~/common/constants/cms'

import MenuHolder from '@/components/global/footer/MenuHolder.vue'

export default defineComponent({
  components: {
    MenuHolder,
  },
  setup() {
    const { getAttributeByCountry } = useCMSGlobalSettingsStore()
    const cmsNavigationStore = useCMSNavigationStore()
    const navigation = cmsNavigationStore.byKey(DEVKEY.GLOBAL_FOOTER)
    const brandLogo = getAttributeByCountry('footerBrandLogo')
    const social = getAttributeByCountry('social')
    const techSupportNumber = getAttributeByCountry('footerTechPhoneNumber')
    const ctx = useContext()

    const backgroundImageUrl = getAttributeByCountry('backgroundImageId')
      ? ctx.$img(`${getAttributeByCountry('backgroundImageId')}`, null, {
          provider: 'mc',
        })
      : ''

    const copyrightHTML =
      getAttributeByCountry('copyrightRte') &&
      jsonToHtml(getAttributeByCountry('copyrightRte'), {
        allowNonStandardTypes: true,
        customElementTypes: {
          reference: (_attrs, _child, json) => {
            if (json.attrs['asset-type'].includes('image')) {
              return `<img class="${json.attrs['class-name']}" src="${json.attrs['asset-link']}"></img>`
            } else {
              return ''
            }
          },
        },
      })

    return { brandLogo, navigation, social, backgroundImageUrl, copyrightHTML, techSupportNumber }
  },
})
