// Pinia
import { StateTree, defineStore } from 'pinia'
import { useProductStore } from './product'

// Types
import { CompareProductRecord, CompareProductsStoreInterface } from '~/types/search/compareProductsTypes'
import { compareErrorBuilder, compareProductRecordBuilder } from '~/common/utils/compareProducts'

export const COMPARE_STORE_SESSION_KEY = 'selectedProductIds'

export const useCompareProductsStore = defineStore({
  id: 'compare-products',
  state: (): CompareProductsStoreInterface => ({
    comparedProducts: [],
  }),
  getters: {
    getCompareProductIds: (state) => {
      return state.comparedProducts.filter((el) => !el.error && el.productTitle && el.productId)
    },
    getCompareProductsUrl() {
      const productIdString: string = this.getCompareProductIds.map((el) => el.productId).join()
      return '/compare-products.jsp?productIds=' + productIdString
    },
    compareProductExists: (state) => (id: string | number) => {
      return !!state.comparedProducts.find((el) => el.productId === id)
    },
  },
  actions: {
    setComparedProducts(comparedProducts: Array<CompareProductRecord>) {
      this.comparedProducts = comparedProducts
    },
    clearCompareProducts() {
      this.setComparedProducts([])
      sessionStorage.removeItem(COMPARE_STORE_SESSION_KEY)
    },
    async toggleCompareProducts(key: string | number) {
      if (!key) {
        return
      }

      const existingProduct = this.comparedProducts.find((product) => product.productId.toString() === key.toString())

      if (existingProduct) {
        // .. Delete if exists
        this.setComparedProducts(this.comparedProducts.filter((cmpProduct) => cmpProduct !== existingProduct))
      } else {
        // .. Fetch if not exists
        try {
          const { fetchFullProduct, products } = useProductStore()
          await fetchFullProduct(key.toString())

          const product = products[key.toString()]

          if (product) {
            this.comparedProducts.push(compareProductRecordBuilder(product))
          }
        } catch (error) {
          console.log(error)

          this.comparedProducts.push(compareErrorBuilder(key.toString()))
        }
      }
    },
    async setCompareProductsFromSession() {
      if (process.client) {
        const { fetchFullProduct, getMultipleProducts } = useProductStore()

        /**
         * Compare store contains only SKU + Title records
         * for sake of having all data about products ready, it is crucial
         * to fetch product store which is source of product data
         */

        try {
          await Promise.all(
            this.comparedProducts.map(async (storedProduct) => {
              try {
                // .. Fetch each product separately
                await fetchFullProduct(storedProduct.productId)
              } catch (error) {
                console.error(error)
              }
            })
          )

          // .. getMultipleProducts already returns sorted products
          const products = getMultipleProducts(this.comparedProducts.map((product) => product.productId))
          const sortedMappedProducts = this.comparedProducts.map((cmpProd) => {
            const product = products.find((prod) => prod?.data.key === cmpProd.productId)

            if (product) {
              return compareProductRecordBuilder(product)
            }

            return compareErrorBuilder(cmpProd.productId)
          })

          this.setComparedProducts(sortedMappedProducts)
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
  persist: {
    key: COMPARE_STORE_SESSION_KEY,
    /**
     * Remove custom serializer after ATG-SUNSET
     * It is used for having same data structure which is needed
     * for old PCP page
     */
    serializer: {
      serialize: (value: StateTree) => {
        return JSON.stringify(value.comparedProducts)
      },
      deserialize: (value: string) => {
        try {
          const parsedJson = JSON.parse(value)

          // .. Production may contain "value" wrapper so do handling here
          return { comparedProducts: parsedJson.value ?? parsedJson }
        } catch (error) {
          return { comparedProducts: [] }
        }
      },
    },
    // .. sessionStorage is present only in browser
    storage: process.client ? sessionStorage : undefined,
  },
})
