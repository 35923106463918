import format from 'date-fns/format'

export const subtractYears = (numOfYears: number, date = new Date()) => {
  date.setFullYear(date.getFullYear() - numOfYears)
  return date
}

export const convertStringToEuDate = (date: string) => {
  const parsedDate = date.split('-')
  return `${parsedDate[2]}-${parsedDate[1]}-${parsedDate[0]}`
}

/**
 * Converts data in string format to date
 * @param date string in format `YYYY-MM-DD`
 */
export const convertStringToDate = (date: string) => {
  const parsedDate = date.split('-').map((value) => parseInt(value))
  return new Date(parsedDate[0], parsedDate[1] - 1, parsedDate[2])
}

type Format = typeof format
type RemoveFirst<T> = T extends [any, ...infer Y] ? Y : never
export const formatDate = (date: Date, ...rest: RemoveFirst<Parameters<Format>>): ReturnType<Format> | null => {
  try {
    return format(date, ...rest)
  } catch (e) {
    return null
  }
}
