import cloneDeep from 'lodash/fp/cloneDeep'

export const attachAxiosResponseTimeLoggerInterceptor = ({
  axios,
  path,
  logger,
}: {
  axios: any
  path?: string
  logger: any
}) => {
  axios.interceptors.request.use((x: any) => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    const startTime = new Date().getTime()
    x.meta = x.meta || {}
    x.meta.requestStartTime = startTime
    return x
  })

  // #region Response
  axios.interceptors.response.use(
    (x: any) => {
      const startTime = x.config.meta?.requestStartTime
      const endTime = new Date().getTime()
      const perfTime = startTime ? endTime - startTime : 'N/A'
      const msg = `[request]: ${x.config.method.toUpperCase()} ${x.config.url} - Total time: ${perfTime} ms`

      // console.log(msg)
      // console.dir(x)
      logger.debug(
        {
          lgroup: 'perf',
          path,
          url: `${x.config.baseURL}${x.config.url}`,
          response: { headers: cloneDeep(x.headers) },
          perf: { totalTime: `${perfTime}ms` },
        },
        msg
      )
      return x
    },
    // Handle 4xx & 5xx responses
    (x: any) => {
      const startTime = x.config.meta?.requestStartTime
      const endTime = new Date().getTime()
      const perfTime = startTime ? endTime - startTime : 'N/A'
      const msg = `[request]: ${x.config.method.toUpperCase()} ${x.config.url} - Total time: ${perfTime} ms`

      // console.error(msg)
      logger.error(
        {
          lgroup: 'perf',
          path,
          url: `${x.config.baseURL}${x.config.url}`,
          response: { headers: cloneDeep(x.headers) },
          perf: { totalTime: `${perfTime}ms` },
        },
        msg
      )
      throw x
    }
  )
}
