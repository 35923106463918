import download from 'downloadjs'
import { Context, Plugin } from '@nuxt/types'
import { QuoteTemplateData } from '@cstweb/pdf-middleware'
import { QuotePdfPayload, QuoteRequestType } from '@cstweb/commercetools-client'
import { decodeHTMLEntities } from '~/common/utils/characters'

function createPdfPlugin({ $ct }: Context) {
  return {
    downloadQuote: async (quote: QuoteTemplateData, type: 'us' | 'japan' | 'b2d' | 'emea', language?: string) => {
      const templateMapping: Record<typeof type, QuoteRequestType> = {
        us: 'quoteUS',
        japan: 'quoteJP',
        b2d: 'quoteB2D',
        emea: 'quoteEMEA',
      }

      const payload: QuotePdfPayload = {
        requestItemType: templateMapping[type],
        countryCode: quote.countryCode.toUpperCase(),
        language: language?.toUpperCase() ?? '',
        data: quote,
      }

      const { data } = await $ct.pdf.createQuotePdf(JSON.parse(decodeHTMLEntities(JSON.stringify(payload)) as string))
      // @ts-ignore
      download(data.url)
    },
  }
}

const plugin: Plugin = (ctx, inject) => {
  inject('pdf', createPdfPlugin(ctx))
}

export type PdfPlugin = ReturnType<typeof createPdfPlugin>

export default plugin
