const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  presets: [],
  prefix: 'tw-',
  theme: {
    fontFamily: {
      'noto-sans': '"Noto Sans", Helvetica, sans-serif',
      cstf: ['cstf'],
    },
    extend: {
      typography: {
        sm: {
          css: {
            color: '#333333',
            a: {
              color: '#6699cc',
              '&:hover': {
                color: '#003366',
              },
            },
            blockquote: {
              borderLeftColor: '#003366',
              borderLeft: 'none',
              paddingInlineStart: '0',
              fontStyle: 'normal',
              color: '#6699cc',
              fontSize: '1.5rem',
            },
            table: {
              borderCollapse: 'collapse',
              fontSize: '0.875rem',
            },
          },
        },
      },
    },
    screens: {
      xs: '414px',
      ...defaultTheme.screens,
      lg: '64rem',
      hxs: { raw: '(max-height: 530px)' },
    },
  },
  plugins: [require('@tailwindcss/typography')],
  content: [
    './components/**/*.{vue,js,ts}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.{js,ts}',
    './nuxt.config.{js,ts}',
  ],
  safelist: [
    { pattern: /bg-(midnight-blue|bahama-blue|danube|killarney|apple|dusty-gray|monza|hokey-pokey)/ },
    {
      pattern: /text-(midnight-blue|bahama-blue|danube|killarney|apple|dusty-gray|monza|hokey-pokey)/,
      variants: ['hover'],
    },
  ],
}
