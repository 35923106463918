













































import { defineComponent, useRouter } from '@nuxtjs/composition-api'

// Common
import { clearSearchHistoryObject, setSearchHistoryObject } from '@/common/utils/searchHistory'
import { firstCharUpperCase, decodeHTMLEntities } from '@/common/utils/characters'
import { SEARCH_TYPES } from '@/common/static/search'

// Store
import { useSearchStore } from '@/store/search'

// Composables
import useSearch from '@/composables/search/useSearch'
import useSearchUrl from '@/composables/search/useSearchUrl'
import { clearSuggestSelected } from '@/composables/search/useSuggestions'
import useSearchAnalytics from '@/composables/analytics/useSearchAnalytics'

// Components
import HistoryIcoSVG from '@/assets/images/history.svg?inline'

// Types
import { SuggestionlistObjectInterface } from '@/types/search/searchTypes'

export default defineComponent({
  components: {
    HistoryIcoSVG,
  },

  props: {
    suggestion: {
      type: Object,
      required: true,
    },
    searchInput: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const searchStore = useSearchStore()
    const { prettyTypeaheadName, storedSearchInput, storedTypeaheadSearchInput } = useSearch()
    const { getSearchUrl } = useSearchUrl()
    const router = useRouter()
    const { trackTypeaheadSuggestionClick } = useSearchAnalytics()

    const clickOnSuggestionTitle = (suggestion: SuggestionlistObjectInterface) => {
      // clear selected index
      clearSuggestSelected()

      // delete checked facets
      searchStore.deleteCheckedFacets()

      // hide suggests box
      searchStore.hideSuggests()

      // send GTM event, only for ga4
      trackTypeaheadSuggestionClick(suggestion.title, suggestion.type + ' suggestion header')

      // Set tab
      searchStore.setSelectedTab(suggestion.type)

      // store search value
      storedSearchInput.value = props.searchInput

      // Save in localStorage
      setSearchHistoryObject(props.searchInput)

      // push to new URL
      router.push(getSearchUrl())
    }

    const clickCategorySuggestion = (suggestion: SuggestionlistObjectInterface) => {
      // perform a new search
      clearSuggestSelected()

      // delete checked facets
      searchStore.deleteCheckedFacets()

      // Set product selected tab
      searchStore.setSelectedTab(SEARCH_TYPES.PRODUCT)

      // send GTM event, only for ga4
      trackTypeaheadSuggestionClick(suggestion.title, suggestion.type + ' suggestion header')

      // store search value
      storedSearchInput.value = props.searchInput

      // Save in localStorage
      setSearchHistoryObject(props.searchInput)

      // push to new URL
      router.push(getSearchUrl({ redirect: false }))
    }

    const clearAllHistory = () => {
      // delete history items from store
      searchStore.deleteSuggest(SEARCH_TYPES.HISTORY)
      // delete it from the localStorage as well
      clearSearchHistoryObject()
    }

    return {
      storedTypeaheadSearchInput,
      prettyTypeaheadName,
      clickOnSuggestionTitle,
      clickCategorySuggestion,
      clearAllHistory,
      firstCharUpperCase,
      SEARCH_TYPES,
      decodeHTMLEntities,
    }
  },
})
