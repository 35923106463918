import { Context } from '@nuxt/types'
import { constants } from '@cstweb/commercetools-client'
import cloneDeep from 'lodash/fp/cloneDeep'

export default function ({ req, route, $logger, $ct }: Context) {
  const customerIdCookie = $ct.client.cookies.customer.get(constants.CUSTOMER_STORE_FIELD.CUSTOMER_ID)
  const customerPunchoutCookie = $ct.client.cookies.customer.get(constants.CUSTOMER_STORE_FIELD.IS_PUNCHOUT)
  const cartIdCookie = $ct.client.cookies.cart.get(constants.CART_STORE_FIELD.CART_ID)
  const accessToken = $ct.client.cookies.auth.get(constants.AUTH_STORE_FIELD.ACCESS_TOKEN)

  // const isLoggedIn = customerIdCookie !== undefined
  const isPunchout = customerPunchoutCookie === true
  // const hasCart = cartIdCookie !== undefined
  const lgroup = isPunchout ? 'punchout' : 'default'

  const logContext = {
    lgroup,
    accessToken: accessToken && `*******${accessToken.toString().slice(-7)}`,
    cartId: cartIdCookie,
    customerId: customerIdCookie,
    url: decodeURIComponent(route.fullPath),
    req: { headers: cloneDeep(req?.headers) },
  }

  $logger.info({ ...logContext }, `[page render (SSR)]`)
}
