import { computed, useContext } from '@nuxtjs/composition-api'
import type { Context } from '@nuxt/types'
import type enTranslations from '~/i18n/en_EN.json'
import type jpTranslations from '~/i18n/ja_JP.json'
import type cnTranslations from '~/i18n/zh_CN.json'

type TranslationKeys = keyof typeof enTranslations | keyof typeof jpTranslations | keyof typeof cnTranslations

export function useI18n(context?: Context) {
  const { i18n } = context ?? useContext()

  function t(key: TranslationKeys, params?: string | string[]) {
    if (params === undefined) {
      return i18n.t(key) as string
    }
    if (Array.isArray(params)) {
      return i18n.t(key, params) as string
    }
    return i18n.t(key, [params]) as string
  }

  function tc(key: TranslationKeys, count: number, values?: Record<string, any>) {
    return i18n.tc(key, count, values)
  }

  return {
    ...i18n,
    t,
    tc,
    locale: computed(() => i18n.locale),
    localeProperties: computed(() => i18n.localeProperties),
  }
}
