










// Composition
import { defineComponent } from '@nuxtjs/composition-api'

// Common
import { decodeHTMLEntities } from '@/common/utils/characters'
import { SEARCH_TYPES } from '@/common/static/search'
import { setSearchHistoryObject } from '@/common/utils/searchHistory'

// Composables
import { clearSuggestSelected } from '@/composables/search/useSuggestions'
import useSearchAnalytics from '@/composables/analytics/useSearchAnalytics'
import useSearch from '@/composables/search/useSearch'

// Types
import { SuggestionlistObjectInterface } from '@/types/search/searchTypes'

export default defineComponent({
  props: {
    suggestion: {
      type: Object as () => SuggestionlistObjectInterface,
      required: true,
    },
    searchInput: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { trackTypeaheadSuggestionClick, trackElasticsearchClickEvent } = useSearchAnalytics()
    const { getSearchResultUrl } = useSearch()

    // show name in nice format and add id for product
    const showName = (suggestion: any) => {
      if (suggestion.type === SEARCH_TYPES.PRODUCT) {
        return suggestion.title + ' #' + suggestion.id
      } else {
        return suggestion.title
      }
    }

    const clickItemtypeSuggestion = (suggestion: SuggestionlistObjectInterface) => {
      // clear suggestions
      clearSuggestSelected()

      // send GTM event
      trackTypeaheadSuggestionClick(suggestion.title, suggestion.type)

      // Store in localStorage
      setSearchHistoryObject(props.searchInput)

      // Send Elastic event
      if (suggestion.type !== SEARCH_TYPES.CATEGORIES && suggestion.id !== undefined) {
        trackElasticsearchClickEvent(suggestion?.id)
      }
    }

    return {
      showName,
      clickItemtypeSuggestion,
      getSearchResultUrl,
      decodeHTMLEntities,
    }
  },
})
