// Pinia
import { defineStore, storeToRefs } from 'pinia'
import { constants, DiscountCodesOnCart } from '@cstweb/commercetools-client'

// LoDash
import isEmpty from 'lodash/fp/isEmpty'

// Stores
import { useProfileStore } from './profile'
import { useUserStore } from './user'
import { useEbsStore } from '~/store/ebs'

// composables & utils
import { parseLineItemToBasketItem } from '~/common/utils/parsing'
import { getTotalDiscountPrice, getSubtotal, getShippingPrice } from '@/composables/checkout/useBasket'

// Types
import {
  CheckoutStoreInterface,
  BasketDataInterface,
  BasketItemsDataInterface,
  BasketInterface,
  PaymentDataInterface,
  CheckoutErrorType,
  BasketItemInterface,
  CrossSellProductInterface,
} from '@/types/checkout/checkoutTypes'
import { mapExceptionToError } from '~/composables/errors/useErrors'
import { sortLineDataItems } from '~/common/utils/sorting'
import { getCookies } from '~/common/utils/getCookies'
import { APPLICATIONS } from '~/common/utils/product/constants'
import { useFeatureFlag } from '~/composables/featureFlags/useFeatureFlag'

// Dummy API response
export const useCheckoutStore = defineStore({
  id: 'checkout',
  state: (): CheckoutStoreInterface => ({
    basketItems: {
      data: {},
      itemsData: {},
    },
    showBasket: false,
    showBasketPopup: false,
    showItemChangePopup: false,
    changeItemModalMode: 'delete',
    itemToChange: null,
    isLoading: false,
    version: 0,
    cartId: '',
    itemsAdded: 0,
    selectedShippingAddress: useProfileStore().getDefaultShippingAddress()?.id || '',
    selectedBillingAddress: useProfileStore().getDefaultBillingAddress()?.id || '',
    selectedContact: useProfileStore().defaultContactId || '',
    isExpanded: false,
    errors: {
      promoBlock: [],
      tableBlock: [],
      quantityInput: {},
    },
    poNumberPayment: null,
  }),
  getters: {
    totalLineItemQuantity(): number {
      if (process.server) {
        return 0
      }
      return (
        this.basketItems.data._totalLineItemQuantity ??
        (getCookies({ context: this.$nuxt, cookieName: constants.COOKIES.HYBRID_CART_QUANTITY }) || 0)
      )
    },
    isEmpty(): boolean {
      return isEmpty(this.basketItems.itemsData)
    },
  },
  actions: {
    async fetchBasket() {
      try {
        this.isLoading = true
        // FIXME: when this call fails with 404 for example the page does not render
        const { data } = await this.$nuxt.$ct.cart.get()

        this._updateStore(data)
      } catch (e: any) {
        this.itemsAdded = 0
      } finally {
        this.isLoading = false
      }
    },
    // TODO: fix type
    async addToBasket(items: { id: string; quantity: number; experimentId?: string | null }[]) {
      this.isLoading = true
      let itemsAdded = 0
      items.forEach((item) => {
        itemsAdded += item.quantity
      })
      try {
        const { data } = await this.$nuxt.$ct.cart.add(items)
        this._updateStore(data)
        this.toggleBasketPopup(itemsAdded)
      } finally {
        this.isLoading = false
      }
    },
    async removeBasket() {
      try {
        await this.$nuxt.$ct.cart.remove()
      } catch (error) {
        console.error(error)
      }
    },
    setBasket(basketData: any, isExpanded: boolean) {
      this._updateStore(basketData)
      this.isExpanded = isExpanded
    },
    async deleteBasketItem(itemToDelete: BasketItemInterface) {
      // Normal and free line items have the same id. When a user
      // wants to delete an item that is free or has a free line version,
      // don't call the removeItem method. That would delete both of them.
      // Instead, decrease the quantity by the other line item amount.
      // NOTE: This is not a case for signal star items (items with experiment id).
      const sameItems = Object.values(this.basketItems.itemsData).filter(
        (item) =>
          item.productId === itemToDelete.productId &&
          item.variant.id === itemToDelete.variant.id &&
          item.custom?.fields?.experimentId === itemToDelete?.custom?.fields?.experimentId
      )

      if (sameItems.length !== 1) {
        const newQuantity = sameItems.reduce((sum, item) => sum + item.quantity, 0) - itemToDelete.quantity
        return await this.changeBasketItemQuantity(itemToDelete.id, newQuantity)
      }

      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.cart.removeItem(itemToDelete.id)
        this._updateStore(data)
      } finally {
        this.isLoading = false
      }
    },
    async changeBasketItemQuantity(id: string, qty: number) {
      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.cart.changeQuantity(id, qty || 0)
        this._updateStore(data)
      } finally {
        this.isLoading = false
      }
    },
    async applyPromo(promoCode: string) {
      const { data } = await this.$nuxt.$ct.cart.applyPromo(promoCode)
      this._updateStore(data)
    },
    async paymentOperation(operation: string, paymentId: string) {
      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.cart.paymentOperation(operation, paymentId)
        this._updateStore(data)
      } finally {
        this.isLoading = false
      }
    },
    async placeOrder() {
      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.orders.placeOrder()
        this._updateStore(data)
        return data
      } finally {
        this.isLoading = false
        // this.$reset()
      }
    },
    async setShippingAddressToCart() {
      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.cart.setShippingAddressToCart(
          this.selectedShippingAddress,
          this.selectedContact,
          useProfileStore().getAddressById(this.selectedShippingAddress)?.country ?? '',
          null
        )
        this._updateStore(data)
      } finally {
        this.isLoading = false
      }
    },
    async setBillingAddressToCart(addressId: string) {
      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.cart.setBillingAddressToCart(
          addressId,
          useProfileStore().getAddressById(addressId)?.country ?? ''
        )
        this._updateStore(data)
      } finally {
        this.isLoading = false
      }
    },
    async setGlobalOrderPromoiseDateToOrderLines(lineItems: BasketItemsDataInterface) {
      const ebsStore = useEbsStore()
      const { gopItems } = storeToRefs(ebsStore)
      this.isLoading = true
      try {
        // loop through each line item and build array object to pass to CT call
        let linesWithGop: { lineId: string; promiseDate: string }[] = []
        for (const itemId in lineItems) {
          const item = lineItems[itemId]
          const lineId = item.id
          // for each line, extract the sku info
          const sku = item.variant.sku ? item.variant.sku : ''
          // lookup finalShipDate for sku in ebsStore
          const promiseDate = gopItems.value[sku ? sku : '']?.finalShipDate
          // create object with required payload
          if (!isEmpty(promiseDate) && !isEmpty(lineId)) {
            const gopLineItem = { lineId, promiseDate }
            // push object to array
            linesWithGop.push(gopLineItem)
          }
        }
        // array object to pass to CT call to set setLineItemCustomField 'estShipDate' with global order promise date
        if (linesWithGop.length > 0) {
          await this.$nuxt.$ct.cart.addGopInfoToOrder(linesWithGop)
        }
      } finally {
        this.isLoading = false
      }
    },
    async setAdditionalInfo(additionalInfo: any) {
      this.isLoading = true
      try {
        await this.$nuxt.$ct.cart.setAdditionalInfo(additionalInfo)
      } finally {
        this.isLoading = false
      }
    },
    async removeDiscountCodesFromCart(discountCodes: DiscountCodesOnCart[]) {
      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.cart.removeDiscountCodesFromCart(discountCodes)
        this._updateStore(data)
      } finally {
        this.isLoading = false
      }
    },
    removePaymentsFromCart() {
      this.basketItems?.data?.payments?.forEach(async (payment: any) => {
        await this.paymentOperation('removePayment', payment.id)
      })
    },
    async toggleShowBasket(state: boolean): Promise<void> {
      this.showBasket = state
      if (this.showBasket) {
        try {
          await this.fetchBasket()
        } catch (error) {
          console.error(error)
        }
      }
    },
    setSelectedShippingAddress(id: string) {
      this.selectedShippingAddress = id
    },
    setSelectedBillingAddress(id: string) {
      this.selectedBillingAddress = id
    },
    setSelectedContact(id: string) {
      this.selectedContact = id
    },
    setCartId(cartId: string) {
      this.cartId = cartId
    },
    toggleBasketPopup(qty: number) {
      this.itemsAdded = qty
      this.showBasketPopup = true
      setTimeout(() => {
        this.showBasketPopup = false
      }, 3000)
    },
    fillAddressesAndContact(data: any) {
      if (!this.selectedShippingAddress) {
        this.selectedShippingAddress =
          data?.shippingAddress?.id || (useProfileStore().getDefaultShippingAddress()?.id ?? '')
      }
      if (!this.selectedBillingAddress) {
        this.selectedBillingAddress =
          data?.billingAddress?.id || (useProfileStore().getDefaultBillingAddress()?.id ?? '')
      }
      if (!this.selectedContact) {
        this.selectedContact = data?.custom?.fields?.contactId || (useProfileStore().defaultContactId ?? '')
      }
    },
    toggleChangeItemPopup(state: boolean, item?: BasketItemInterface, mode: string = 'delete') {
      this.changeItemModalMode = mode
      this.showItemChangePopup = state
      if (item) {
        this.itemToChange = item
      }
    },
    /**
     * @param component Name of the component for which to set an error
     * @param error Error to be set
     * @param quantityInputId Required only when compoenent is 'quantityInput'
     */
    setErrors(component: CheckoutErrorType, error: unknown, quantityInputId?: string) {
      const mappedErrors = mapExceptionToError(error, typeof error === 'string' ? error : 'Something went wrong.')
      if (component === 'quantityInput') {
        this.errors[component][quantityInputId ?? ''] ??= []
        this.errors[component][quantityInputId ?? ''].push(...mappedErrors)
        // force reassignment is required for the store to publish the change
        this.errors[component] = { ...this.errors[component] }
        return
      }
      this.errors[component].push(...mappedErrors)
    },
    clearErrors() {
      this.errors = {
        promoBlock: [],
        tableBlock: [],
        quantityInput: {},
      }
    },
    _updateStore(data: any) {
      this.clearErrors()
      this.basketItems = mapBasketData(data)
      this.setCartId(data.id ?? '')
      this.version = data.version
      this.fillAddressesAndContact(data)
      this.isExpanded = true
    },
  },
})

function mapCrossSellProducts(lineData: BasketItemsDataInterface) {
  const showCrossSelling = useFeatureFlag('showCrossSelling')
  const showIntraseqCrossSelling = useFeatureFlag('showIntraseqCrossSelling')

  // Items without those that are already in basket
  const crossSellProducts: CrossSellProductInterface[] = [
    { sku: '82906S', productName: 'InTraSeq<sup>™</sup> Assay Kit', type: 'intraseq-assay-kit' },
    { sku: '48167S', productName: 'InTraSeq<sup>™</sup> Antibody Cocktail', type: 'intraseq-antibody-cocktail' },
    { sku: '9803S', productName: 'Cell Lysis Buffer (10X)', type: 'wb' },
    { sku: '8112L', productName: 'SignalStain<sup>®</sup> Antibody Diluent', type: 'ihc' },
  ]
    .filter((csProduct) => !(!showCrossSelling.value && ['wb', 'ihc'].includes(csProduct.type)))
    .filter((csProduct) => !(!showIntraseqCrossSelling.value && csProduct.type.includes('intraseq')))
    .filter((csProduct) => Object.keys(lineData).every((key) => key.split('-')[1] !== csProduct.sku))

  let assignedCrossSellProducts: any = {}
  let canMap = true

  const findAndMoveCSProduct = (
    key: string,
    ...validCSProductTypes: ('wb' | 'ihc' | 'intraseq-assay-kit' | 'intraseq-antibody-cocktail')[]
  ) => {
    const csProductIndex = crossSellProducts.findIndex((product) => validCSProductTypes.includes(product.type as any))

    if (csProductIndex > -1) {
      assignedCrossSellProducts = {
        ...assignedCrossSellProducts,
        [key]: [...(assignedCrossSellProducts[key] || []), crossSellProducts.splice(csProductIndex, 1)[0]],
      }
      return true
    }
    return false
  }

  while (crossSellProducts.length > 0 && canMap) {
    const lengthBeforeMapping = crossSellProducts.length
    for (const key in lineData) {
      const item = lineData[key]
      const lot = item.variant?.attributes?.find((attribute) => attribute.name === 'lot')
      const applicationGroups =
        lot?.value?.obj?.value?.applications?.application?.map((application: any) => application.group) || []

      if ((item.name as string).includes('InTraSeq') && (item.name as string).includes('Assay Kit')) {
        const success = findAndMoveCSProduct(key, 'intraseq-antibody-cocktail')
        if (success) {
          continue
        }
      } else if ((item.name as string).includes('InTraSeq') && (item.name as string).includes('Antibody Cocktail')) {
        const success = findAndMoveCSProduct(key, 'intraseq-assay-kit')
        if (success) {
          continue
        }
      } else if ((item.name as string).includes('InTraSeq')) {
        const success = findAndMoveCSProduct(key, 'intraseq-assay-kit', 'intraseq-antibody-cocktail')
        if (success) {
          continue
        }
      }

      if (applicationGroups.includes(APPLICATIONS.WESTERN_BLOTTING.group)) {
        const success = findAndMoveCSProduct(key, 'wb')
        if (success) {
          continue
        }
      }

      if (applicationGroups.includes(APPLICATIONS.IMMUNOHISTOCHEMISTRY.group)) {
        const success = findAndMoveCSProduct(key, 'ihc')
        if (success) {
          continue
        }
      }
    }

    if (lengthBeforeMapping === crossSellProducts.length) {
      canMap = false
    }
  }

  return assignedCrossSellProducts
}

export function mapBasketData(data: any) {
  const basketData = { ...data } as BasketDataInterface
  const basket = {} as BasketInterface
  const selectedLocale = useUserStore().selectedLocale

  const mapDiscountCodesOnCart = (data: any, status: 'active' | 'inactive'): DiscountCodesOnCart[] => {
    return (
      data.discountCodes
        ?.filter((code: any) =>
          status === 'active' ? code.state.toLowerCase() === 'matchescart' : code.state.toLowerCase() !== 'matchescart'
        )
        .map((filteredCode: any) => ({
          id: filteredCode.discountCode.id,
          code: filteredCode.discountCode.obj?.code,
          name: filteredCode.discountCode.obj?.name['en-US'],
        })) ?? []
    )
  }

  // Has been parsed in middleware
  if (!isEmpty(data._discountCodes)) {
    basketData.discountCodes = data._discountCodes
  }

  // Parsing from utils
  const lineData: BasketItemsDataInterface = data.lineItems.reduce(
    (prevLineItem: any, currLineItem: any) => ({
      ...prevLineItem,
      ...parseLineItemToBasketItem(currLineItem, selectedLocale, basketData.discountCodes),
    }),
    {}
  )

  const sortedLineData = sortLineDataItems(lineData)

  const parsePayments = (paymentInfo: any): PaymentDataInterface[] => {
    return (
      paymentInfo?.payments?.map((payment: any) => ({
        id: payment.obj?.id,
        amountPlanned: payment.obj?.amountPlanned,
        paymentMethodInfo: payment.obj?.paymentMethodInfo,
        custom: payment.obj?.custom,
      })) ?? []
    )
  }

  // Other
  basketData.shippingInfo = data.shippingInfo
  basketData.totalPrice = data.taxedPrice?.totalGross ?? data.totalPrice
  basketData._totalLineItemQuantity = data.totalLineItemQuantity ?? 0
  basketData.totalPromo = getTotalDiscountPrice(data)
  basketData.subtotalPrice = getSubtotal(data)
  basketData.currencyCode = data.totalPrice.currencyCode
  basketData.fractionDigits = data.totalPrice.fractionDigits
  basketData.tax = data.taxedPrice?.totalTax
  basketData.isPromoActiveOnCart = data._isPromoActive
  basketData.payments = parsePayments(data.paymentInfo)
  basketData.shippingPrice = getShippingPrice(data)
  basketData.inactiveDiscountCodesOnCart = mapDiscountCodesOnCart(data, 'inactive')
  basketData.activeDiscountCodesOnCart = mapDiscountCodesOnCart(data, 'active')
  basketData.assignedCrossSellProducts = mapCrossSellProducts(sortedLineData)

  basket.data = basketData
  basket.itemsData = sortedLineData

  return basket
}
