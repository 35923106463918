import { defineStore } from 'pinia'
import { ProductImage } from '~/types/cloudflare/productImageTypes'

interface Store {
  images: {
    [key in string]: ProductImage
  }
}

export const useStore = defineStore({
  id: 'cloudflare',
  state: (): Store => ({
    images: {},
  }),
  actions: {
    async fetchCloudflareProductImages(productId: string) {
      try {
        const images = await this.$nuxt.$cloudflare.fetchProductImagesByProductId(productId)

        this.patchImagesStore(images)
      } catch (error) {}
    },
    patchImagesStore(images: ProductImage[] = []) {
      for (const image of images) {
        this.images[image.damId] = image
      }
    },
  },
})
