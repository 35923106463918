import https from 'https'
import { Context, Plugin } from '@nuxt/types'
import { useI18n } from '~/composables/i18n'
import { formatPrice } from '~/common/utils/numbers'

// This plugin is client only because cloudflare blocks requests coming from the server
export interface GetClpResponse {
  sku: string
  // For CN - Inventory current stock field is string
  // For JP - Inventory current stock field is boolean
  // Each is parsed into different variable to simplify handling of types
  inventory: { status: string } | { inStock: boolean }
  listPrice: number
}

export type ProductAvailabilityAndPricing = {
  sku: string
  availability: string
  pricing: string
}

const createPimPlugin = (context: Context) => {
  const baseURL = context.$config.cst.pimCLPUrl
  const { t } = useI18n(context)

  const axios = context.$axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  })

  const getClp = async (productNumber: string, countryCode: string): Promise<GetClpResponse[]> => {
    const { data } = await axios.get('/getclp', { params: { productno: productNumber, country: countryCode } })

    if (Array.isArray(data.products)) {
      // Other countries return products as an array
      return (data.products as any[]).map<GetClpResponse>((p) => ({
        sku: p.sku,
        listPrice: Number(p.pricing['list-price'].replace('.', '')),
        inventory: { status: p.inventory['current-stock'] },
      }))
    }

    if (data.products) {
      // countryCode = JP returns products as an object (side note: also response type is text)
      return Object.entries(data.products).map<GetClpResponse>(([key, value]: [string, any]) => ({
        sku: key,
        listPrice: Number(value.pricing['list-price']),
        inventory: {
          inStock: value.inventory['current-stock'],
        },
      }))
    } else {
      return []
    }
  }

  const mapGetClpProductToAvailability = (product: GetClpResponse) => {
    if ('status' in product.inventory) {
      return product.inventory.status
    }
    return t(product.inventory.inStock ? 'inStock' : 'notInStock')
  }

  const getProductAvailability = async (productNumber: string, countryCode: string, variant?: string) => {
    const products = await getClp(productNumber, countryCode)
    variant = variant ?? products[0].sku
    let productInfo = products.find((p) => p.sku === variant)

    if (!productInfo) {
      console.error('Failed to find variant information in getClp response.', products, variant)
      console.warn(`Using the ${products[0]} as fallback.`)
      productInfo = products[0]
    }

    return mapGetClpProductToAvailability(productInfo)
  }

  const getProductAvailabilitiesAndPricing = async (
    productNumber: string,
    countryCode: string
  ): Promise<ProductAvailabilityAndPricing[]> => {
    const products = await getClp(productNumber, countryCode)
    return products.map((p) => ({
      sku: p.sku,
      availability: mapGetClpProductToAvailability(p),
      // WEBP-9500 - prices data only for CN (OldPriceBrickContent)
      pricing: formatPrice(p.listPrice, 'CNY', 2),
    }))
  }

  return { getClp, getProductAvailability, getProductAvailabilitiesAndPricing, mapGetClpProductToAvailability }
}

export type PimPlugin = ReturnType<typeof createPimPlugin>

const plugin: Plugin = (ctx, inject) => {
  inject('pim', createPimPlugin(ctx))
}

export default plugin
