











import { defineComponent, computed } from '@nuxtjs/composition-api'

import { useCheckoutStore } from '@/composables/checkout/useBasket'
import { useI18n } from '~/composables/i18n'

export default defineComponent({
  setup() {
    const { t, tc } = useI18n()
    const checkoutStore = useCheckoutStore()

    const basketStatusString = computed(() => {
      const totalBasketItems = checkoutStore.totalLineItemQuantity
      return totalBasketItems === 0
        ? t('yourBasketIsEmpty')
        : tc('yourBasketHasItems', totalBasketItems, { count: totalBasketItems > 99 ? '99+' : totalBasketItems })
    })

    const closeSideBar = () => checkoutStore.toggleShowBasket(false)

    return {
      closeSideBar,
      basketStatusString,
    }
  },
})
