import { constants } from '@cstweb/commercetools-client'
import { useProfileStore } from '@/store/profile'

export default function (ctx: any) {
  // console.log('Executing Verified Account Middleware')
  const isVerifiedCookie = ctx.$cookies.get(constants.COOKIES.CUSTOMER)?.isVerified
  const profileStore = useProfileStore(ctx.$pinia)
  const isVerified = profileStore.isVerified || isVerifiedCookie
  if (!isVerified) {
    return ctx.redirect('/account/sign-up/verify-request')
  }
}
