import pinoLogger, { stdTimeFunctions } from 'pino'

const LOG_SENSITIVE_DATA = process.env.APP_LOG_SENSITIVE_DATA === 'true'
const LOG_LEVEL = process.env.APP_LOG_LEVEL || 'info'
const censor = '*******'
const redactPaths = !LOG_SENSITIVE_DATA
  ? ['err.headers.cookie', 'err.headers.authorization', 'err.headers.Authorization', 'username', 'password']
  : []

const formatters = {
  level: (label: string) => {
    return { level: label.toUpperCase() }
  },
}

export const logger = pinoLogger({
  name: 'log:cms',
  level: LOG_LEVEL,
  redact: {
    paths: redactPaths,
    censor,
  },
  serializers: {
    msg: (msg) => {
      if (msg) {
        msg = decodeURIComponent(msg)
        if (!LOG_SENSITIVE_DATA) {
          msg = msg
            .replace(/username=([^&]*)/i, `username=${censor}`)
            .replace(/password=([^&]*)/i, `password=${censor}`)
        }
      }
      return msg
    },
  },
  formatters,
  timestamp: stdTimeFunctions.isoTime,
})
