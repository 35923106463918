import { getDisplayNameForApplicationCode } from './parsing'
import { CompareProductRecord } from '~/types/search/compareProductsTypes'
import { MappedProduct, ProductInterface } from '~/types/product/productTypes'

export const productMapper = (product: MappedProduct) => ({
  data: product,
  getIsoType: product.attributes.isotype,
  getSource: product.attributes.host,
  getName: product.name,
  getReactivity: product.attributes.lots?.lot.reactivity?.reacts.map((obj) => obj?.species).join(', '),
  getDetection: product.attributes.lots?.lot?.sensitivity,
  getClonality: product.productType,
  getSourcePurification: product.attributes.sourcePurification,
  getSpecificitySensitivity: product.attributes.specificitySensitivity,
  getCOA: product.attributes?.certificateOfAnalysis?.coa,
  getProductId: product.key,
  getProductType: product.productType,
  getProductStatus: product.attributes.statusDates.status,
  getRecommendedApplications: product.attributes.lots?.lot.applications?.application
    .map((apl) => getDisplayNameForApplicationCode(apl?.code ?? ''))
    .join(', '),
})

export const sameIsoTypeSource = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key &&
        (otherProduct.getSource ?? '' + otherProduct.getIsoType) ===
          (currProduct.getSource ?? '' + currProduct.getIsoType)
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export const sameConjugates = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key &&
        (otherProduct.data.conjugations ?? '') === (currProduct.data.conjugations ?? '')
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export const sameReactivity = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key && otherProduct.getReactivity === currProduct.getReactivity
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export const sameApplications = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key &&
        otherProduct.getRecommendedApplications === currProduct.getRecommendedApplications
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export const sameSpecificitySensitivity = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key &&
        otherProduct.getSpecificitySensitivity === currProduct.getSpecificitySensitivity
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export const sameSourcePurification = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key &&
        otherProduct.getSourcePurification === currProduct.getSourcePurification
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export const sameDetection = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key && otherProduct.getDetection === currProduct.getDetection
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export const sameClonality = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return (
    allProducts.some(
      (otherProduct) =>
        otherProduct.data.key !== currProduct.data.key && otherProduct.getClonality === currProduct.getClonality
    ) || hasDuplicateProduct(allProducts, currProduct)
  )
}

export type MappedProductPCP = ReturnType<typeof productMapper>

export const hasDuplicateProduct = (
  allProducts: ReturnType<typeof productMapper>[],
  currProduct: ReturnType<typeof productMapper>
) => {
  return allProducts.filter((product) => product.data.key === currProduct.data.key).length > 1
}

export const compareErrorBuilder = (productId: string): any => ({
  productId,
  error: 'We are not able to retrieve this product info',
})

export const compareProductRecordBuilder = (product: ProductInterface): CompareProductRecord => ({
  productId: product.data.key,
  productTitle: product.data.name,
})
