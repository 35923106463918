import { computed, useContext } from '@nuxtjs/composition-api'
import { constants } from '@cstweb/commercetools-client'
import { useProfileStore } from '~/store/profile'
import { useCheckoutStore } from '~/store/checkout'

/*
    METHODS
*/
// method to set the cookie to the store
export const useUserStatus = ($ctx?: any) => {
  const ctx = $ctx || useContext()

  const profileStore = useProfileStore(ctx.$pinia)
  const checkoutStore = useCheckoutStore(ctx.$pinia)

  const isLoggedIn = computed(() => {
    const customerIdCookie = ctx.$cookies.get(constants.COOKIES.CUSTOMER)?.id
    return profileStore.userInfo?.id !== '' || customerIdCookie !== undefined
  })

  const isVerified = computed(() => {
    const isVerifiedCookie = ctx.$cookies.get(constants.COOKIES.CUSTOMER)?.isVerified
    return profileStore.isVerified || isVerifiedCookie
  })

  const hasCart = computed(() => {
    const cartIdCookie = ctx.$cookies.get(constants.COOKIES.CART)?.id
    return checkoutStore.cartId !== '' || cartIdCookie !== undefined
  })

  return { isLoggedIn, isVerified, hasCart }
}
