









// Composition
import { defineComponent } from '@nuxtjs/composition-api'

// Composable
import { basketItems } from '@/composables/checkout/useBasket'
import { useI18n } from '~/composables/i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    return {
      t,
      basketItems,
    }
  },
})
