import { Context } from '@nuxt/types'
import { getImage as mcGetImage } from './media-cloudflare.plugin'
import { getImage as msGetImage } from './media-server.plugin'

function buildPlugin() {
  return {
    mc: {
      getImage: mcGetImage,
    },
    ms: {
      getImage: msGetImage,
    },
  }
}

export type CMSPlugin = ReturnType<typeof buildPlugin>

export default function (_context: Context, inject: any) {
  inject('image', buildPlugin())
}
