import { joinURL } from 'ufo'
import { ProviderGetImage, ImageModifiers } from '@nuxt/image'
import type { Context } from '@nuxt/types'
import { detectServerBaseUrl, findTLD } from '@cstweb/common'
import { TLD } from '@/common/constants/utils'

interface ProviderModifiers extends Partial<ImageModifiers> {
  variant?: string
}
export const getImage: ProviderGetImage = (
  src,
  { modifiers, baseURL } = {},
  { options: _options, nuxtContext, $img: _$img }
) => {
  const { $config, req } = nuxtContext as Context
  let url = baseURL || $config.cst.cloudflare.images.deliveryUrl
  let host

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)
  }

  if (process.client) {
    host = location.origin
  }

  if (TLD.CN.toLowerCase() === findTLD(host)?.toLowerCase()) {
    url = $config.cst.cn.cloudflare.images.deliveryUrl || baseURL
  }

  const { variant } = modifiers as ProviderModifiers
  url = joinURL(url, src, variant ?? '')

  return {
    url,
  }
}
