import { storeToRefs } from 'pinia'
// Composables
import useAnalytics from '@/composables/analytics/useAnalytics'

// Types
import { BasketItemInterface, BasketDataInterface, AddItemToBasketInterface } from '@/types/checkout/checkoutTypes'
import {
  GA4AddToCartProductInterface,
  GA4BasketParamsInterface,
  RemoveFromCartInterface,
  GA4BasketParamsParamObjectInterface,
} from '@/types/analytics/ecommerceAnalyticsTypes'
import { OrderInterface } from '@/types/profile/orderHistoryTypes'
import { JapanCustomerInfo } from '@/types/quotes/quotesTypes'

// Common
import { valutaMapping } from '@/common/mapping/mapping'
import { centsToPrice } from '@/common/utils/numbers'

// Stores
import { useCheckoutStore } from '~/store/checkout'
import { useUserStore } from '~/store/user'
import { useProductStore } from '~/store/product'

export default () => {
  const analytics = useAnalytics()
  const checkoutStore = useCheckoutStore()
  const userStore = useUserStore()
  const { basketItems } = storeToRefs(checkoutStore)
  /*
    Helper function to get the current basket items
  */
  const getCurrentBasketItems = () => {
    const items = [] as Array<{ key: string; id?: string; quantity: number }>
    Object.keys(basketItems.value.itemsData).forEach((key: string) => {
      if (basketItems.value.itemsData[key].variant.sku !== undefined) {
        let productKey =
          basketItems.value.itemsData[key].productKey + '-' + basketItems.value.itemsData[key].variant.sku

        // Add optional experiment ID to product key
        if (basketItems.value.itemsData[key].custom?.fields.experimentId) {
          productKey += '-' + basketItems.value.itemsData[key].custom?.fields.experimentId
        }

        items.push({
          key: productKey,
          id: basketItems.value.itemsData[key].variant.sku,
          quantity: basketItems.value.itemsData[key].quantity,
        })
      }
    })
    return items
  }

  /*
    Get al GA4 basket params
  */
  const getGA4BasketParams = (options: GA4BasketParamsInterface) => {
    const parameterObject: GA4BasketParamsParamObjectInterface = {
      event: options.event,
      currency: '',
      ecommerce: {
        coupon: [] as Array<string>,
        items: [] as Array<GA4AddToCartProductInterface>,
      },
      ...analytics.getCustomDimensions().ga4,
    }

    // GA4 coupon, only add it if it exists
    const basketItemsData = checkoutStore?.basketItems?.data
    if (basketItemsData.discountCodes !== undefined) {
      Object.values(basketItemsData.discountCodes).forEach((value) => {
        if (Array.isArray(value)) {
          const code = value[0].toString()
          if (!parameterObject.ecommerce.coupon.includes(code)) {
            parameterObject.ecommerce.coupon.push(code)
          }
        }
      })
    }

    // Set the currency code, also for events with excludeBasketItems:true
    const currencyCode = checkoutStore.basketItems?.data?.totalPrice?.currencyCode ?? ''
    if (options.excludePriceInfo === undefined || options.excludePriceInfo === false) {
      // GA4 currency code
      parameterObject.currency = currencyCode
    }

    // Only add the items objects if excludeBasketItems is false or undefined
    // We use this option in add_to_cart where we only want to add the added item
    // and not the items in the existing cart.
    // All other events use the existing cart items
    if (!options.excludeBasketItems) {
      // if items is empty, get the current items from the basket
      const items = getCurrentBasketItems()

      // loop trough the items -> ID&quantity
      // for every item, get it from the basket
      // send a new event
      items.forEach((item) => {
        // check for each item if it is present in the basket
        Object.keys(basketItems.value.itemsData).forEach((key: string) => {
          // If SKU is the same as the entered ID
          if (key === item.key) {
            // if so, take that product and use it to fill the product array in this GTM event
            const productData = basketItems.value.itemsData[key] as BasketItemInterface
            const itemVariant = productData.variant.attributes?.filter((attr: any) => {
              if (attr.name === 'code') {
                return true
              }
              return false
            })
            const discount = centsToPrice(
              (productData.price.value.centAmount - productData.netPrice) * productData.quantity,
              productData.price.value.fractionDigits
            )

            // check for applied promos and add to coupon array
            if (productData.appliedPromoNames.length > 0) {
              productData.appliedPromoNames.forEach((coupon: string) => {
                parameterObject.ecommerce.coupon.push(coupon)
              })
            }

            const baseItemInfo = {
              item_name: productData.name.toString(),
              item_id: productData.productKey,
              item_variant: itemVariant !== undefined && itemVariant.length > 0 ? itemVariant[0].value.toString() : '',
              item_brand: 'cst',
              quantity: item.quantity,
            }

            // GA4 product info
            parameterObject.ecommerce.items?.push(
              options.excludePriceInfo
                ? baseItemInfo
                : {
                    ...baseItemInfo,
                    price: centsToPrice(productData.listPrice, productData.price.value.fractionDigits),
                    discount,
                  }
            )
          } // endif
        }) // end object keys
      }) // end map
    }

    // GA4 ATC location
    if (options.location !== undefined && options.location.length > 0) {
      parameterObject.atc_location = options.location
    }
    return parameterObject
  }

  /*
    Track clicking on the quickAdd button
  */
  const trackQuickAddClick = () => {
    const GA4Params = {
      event: 'click_quick_add',
      ...analytics.getCustomDimensions().ga4,
    }
    analytics.pushAndClearEcommerceObject(GA4Params)
  }

  /*
    Track clicking on the quickAdd button
  */
  const trackMultiQuickAddClick = () => {
    const GA4Params = {
      event: 'click_multi_quick_add',
      ...analytics.getCustomDimensions().ga4,
    }
    analytics.pushAndClearEcommerceObject(GA4Params)
  }

  /*
    Track an add to cart
  */
  const trackAddToCart = (location: string = '', items: Array<AddItemToBasketInterface> = []) => {
    // GA4
    // Get the getGA4BasketParams but exclude the current items in the basket.
    // Instead, fill in the items array with the received items from trackAddToCart
    const trackAddToCartParams = getGA4BasketParams({
      location,
      event: 'add_to_cart',
      excludeBasketItems: true,
    })

    // Loop through the items and find the corresponding info in the cart
    // When done, send the add_to_cart event
    let totalValue = 0
    const itemInfo: Array<GA4AddToCartProductInterface> = []

    items.forEach((el) => {
      // Divide the productnumber from the size
      const productIdAndSizeArray = el.id.match(/[a-zA-Z]+|[0-9]+/g)

      // Start defining the product key
      let productKey = productIdAndSizeArray![0] + '-' + el.id

      // Optionally add the experiment ID from SignalStar
      if (el.experimentId) {
        productKey += '-' + el.experimentId
      }

      // Find the item in the basket
      const itemInBasket = checkoutStore.basketItems.itemsData[productKey]

      // Add to totalValue
      totalValue += itemInBasket ? itemInBasket.netPrice * el.quantity : 0

      // Create item to push and add to ecommerce.items object
      const itemToPush = {
        item_id: el.id,
        item_brand: 'cst',
        quantity: el.quantity,
        item_name: itemInBasket ? itemInBasket.name : '',
        item_variant: itemInBasket ? productIdAndSizeArray![1] : '',
        price: itemInBasket ? centsToPrice(itemInBasket.netPrice, 2) : 0,
      } as GA4AddToCartProductInterface

      itemInfo.push(itemToPush)
    })
    trackAddToCartParams.ecommerce.items = itemInfo

    // Push to DL
    analytics.pushAndClearEcommerceObject({
      value: centsToPrice(totalValue, 2) ?? 0,
      ...trackAddToCartParams,
    })
  }

  /*
  For Old Price Brick in some cases (CN and default states - like AS) we are still tracking default e_requestQuote event but only in GA4
*/
  const trackAddToOldPriceBrick = (location: string = '', items: Array<AddItemToBasketInterface> = []) => {
    const gaItems = items.map((item) => ({
      name: item.id,
      id: item.id,
      variant: item.variant,
      brand: 'cst',
      quantity: 1,
      category: useProductStore().products[item.id].data.productCategory,
    }))

    const ga4Object = {
      event: 'e_requestQuote',
      request: {
        products: gaItems,
      },
      atc_location: location,
      ...analytics.getCustomDimensions().ga4,
    }
    analytics.pushAndClearEcommerceObject(ga4Object)
  }

  /*
    Track a view cart event
  */
  const trackViewCart = (location: string = '') => {
    // only send to ga4
    analytics.pushAndClearEcommerceObject({
      value:
        Object.keys(checkoutStore.basketItems.itemsData).length > 0 && checkoutStore.basketItems.data.subtotalPrice
          ? centsToPrice(checkoutStore.basketItems.data.subtotalPrice, 2)
          : 0,
      ...getGA4BasketParams({
        location,
        event: 'view_cart',
      }),
    })
  }

  /*
    Track a remove cart item
  */
  const trackRemoveFromCart = (location: string = '', options: RemoveFromCartInterface) => {
    // get the item id and quantity
    // go over the stored basketItems and get the quantity from there
    let itemForDelete: any = {}
    Object.values(basketItems.value.itemsData).forEach((basketItem) => {
      if (basketItem.id === options.itemId) {
        itemForDelete = {
          item_name: basketItem.name.toString(),
          item_id: basketItem.productKey,
          item_variant: basketItem.variant.sku,
          price: basketItem.totalPrice.centAmount,
          item_brand: 'cst',
          quantity: basketItem.quantity,
          currencyCode: basketItem.totalPrice.currencyCode,
        }
      }
    })

    const currency = valutaMapping[itemForDelete.currencyCode as keyof typeof valutaMapping]

    // GA4
    const GA4Params = {
      event: 'remove_from_cart',
      atc_location: location,
      currency,
      ecommerce: {
        items: [
          {
            item_name: itemForDelete.item_name,
            item_id: itemForDelete.item_id,
            item_variant: itemForDelete.item_variant,
            price: itemForDelete.price,
            item_brand: 'cst',
            quantity: itemForDelete.quantity,
            currencyCode: itemForDelete.currencyCode,
          },
        ],
      },
      ...analytics.getCustomDimensions().ga4,
    }
    analytics.pushAndClearEcommerceObject(GA4Params)
  }

  /*
    Track checkout start
    checkout step 1
  */
  const trackCheckoutBegin = () => {
    // GA4
    let value = basketItems.value.data?.totalPrice?.centAmount
    if (basketItems.value.data?.totalPrice?.centAmount) {
      value = centsToPrice(
        basketItems.value.data?.totalPrice?.centAmount,
        basketItems.value.data?.totalPrice?.fractionDigits
      )
    }
    analytics.pushAndClearEcommerceObject({
      ...getGA4BasketParams({
        event: 'begin_checkout',
      }),
      value,
    })
  }

  /*
    Track checkout shipping info
    checkout step 2
  */
  const trackCheckoutShippingInfo = () => {
    // GA4
    let value = basketItems.value.data?.totalPrice?.centAmount
    if (basketItems.value.data?.totalPrice?.centAmount) {
      value = centsToPrice(
        basketItems.value.data?.totalPrice?.centAmount,
        basketItems.value.data?.totalPrice?.fractionDigits
      )
    }

    analytics.pushAndClearEcommerceObject({
      ...getGA4BasketParams({
        event: 'add_shipping_info',
      }),
      value,
    })
  }

  /*
    Track checkout Billing info
    checkout step 3
  */
  const trackCheckoutBillingInfo = () => {
    // GA4
    const ga4Params = getGA4BasketParams({
      event: 'add_payment_info',
    })

    let value = basketItems.value.data?.totalPrice?.centAmount
    if (basketItems.value.data?.totalPrice?.centAmount) {
      value = centsToPrice(
        basketItems.value.data?.totalPrice?.centAmount,
        basketItems.value.data?.totalPrice?.fractionDigits
      )
    }

    analytics.pushAndClearEcommerceObject({ ...ga4Params, value })
  }

  /*
    Track checkout place order
    checkout step 4
  */
  const trackCheckoutPlaceOrder = () => {
    // Retrieve payment mehod
    const cartData = basketItems.value.data as BasketDataInterface
    const paymentMethod = cartData.paymentInfo.payments[0].obj.paymentMethodInfo.method

    // GA4
    const ga4Params = getGA4BasketParams({ event: 'place_order' })

    // Swap property names because 'place_order'is a custom event
    // This way, we are not able to use the items array
    // So we have to rename to a products array
    // With the product_name and product_id
    // First copy the array and assign to new prop
    ga4Params.ecommerce.products = ga4Params.ecommerce.items
    delete ga4Params.ecommerce.items

    // Now change the parameters of every item in the product array
    ga4Params.ecommerce.products = ga4Params.ecommerce.products?.map((item: any) => {
      return {
        discount: item.discount,
        price: item.price,
        quantity: item.quantity,
        product_name: item.item_name,
        product_id: item.item_id,
        product_variant: item.item_variant,
        product_brand: item.item_brand,
      }
    })

    analytics.pushAndClearEcommerceObject({ ...ga4Params, payment_method: paymentMethod })
  }

  /*
    Track checkout purchase
    checkout successfull purchase
  */
  const trackCheckoutPurchase = (order: OrderInterface) => {
    const ga4Params = getGA4BasketParams({
      event: 'purchase',
    })

    // Revenue, tax and shipping prices
    const revenue = centsToPrice(order.totalPrice.centAmount, order.totalPrice.fractionDigits).toString()
    const taxPrice =
      order.taxedPrice !== undefined
        ? centsToPrice(order.taxedPrice.totalTax.centAmount, order.taxedPrice.totalTax.fractionDigits).toString()
        : null
    const shippingPrice =
      order.shippingInfo !== undefined
        ? centsToPrice(order.shippingInfo.price.centAmount, order.shippingInfo.price.fractionDigits).toString()
        : null

    // GA4
    const ga4Otherparams = {
      ecommerce: {
        transaction_id: order.id.toString(),
        tax: taxPrice,
        price: order.totalPrice.centAmount.toString(),
        shipping: shippingPrice,
        value: revenue,
        items: Array.isArray(order.lineItems)
          ? order.lineItems.map((productData: any) => {
              return {
                name: productData.name[userStore.selectedLocale],
                id: productData.productKey,
                price: centsToPrice(
                  productData.price.value.centAmount,
                  productData.price.value.fractionDigits
                ).toString(),
                brand: 'cst',
                quantity: productData.quantity,
                category: '',
              }
            })
          : [],
      },
    }

    // replace discounts. The coupon codes are removed from the basket upon order
    // we get the discounts that came with the order from the order object
    if (order.discountCodes !== undefined && Array.isArray(order.discountCodes) && order.discountCodes.length > 0) {
      const discountCodeArray = order.discountCodes.map((code: any) => code.discountCode.obj.code)

      // GA4
      ga4Params.ecommerce.coupon = discountCodeArray
    }

    // Push
    analytics.pushAndClearEcommerceObject({ ...ga4Params, ...ga4Otherparams })
  }

  /*
    Track review quote
  */
  const trackQuoteReview = () => {
    // TODO, check if we need to create this for universal
    // Universal- e_quoteReview (trigger + tag need to be made)
    const params = getGA4BasketParams({
      event: 'quote_review',
    })

    // TODO, figure out what this promo is
    // quote_promo: "",
    const otherParams = {
      quote_value: basketItems.value.data.totalPrice?.centAmount,
      quote_item_brand: 'cst',
      quote_quantity: basketItems.value.data._totalLineItemQuantity,
    }

    analytics.pushAndClearEcommerceObject({ ...params, ...otherParams })
  }

  const trackQuoteSummary = () => {
    // TODO, do we need this?
    // Universal - e_quoteSummary (trigger + tag need to be made)

    const params = getGA4BasketParams({
      event: 'quote_summary',
    })

    // TODO, figure out what this promo is
    // quote_promo: "",
    const otherParams = {
      quote_value: basketItems.value.data.totalPrice?.centAmount,
      quote_item_brand: 'cst',
      quote_quantity: basketItems.value.data._totalLineItemQuantity,
    }

    analytics.pushAndClearEcommerceObject({ ...params, ...otherParams })
  }

  const trackQuoteCustomerInfo = (info: JapanCustomerInfo) => {
    // GA4
    const params = getGA4BasketParams({
      event: 'quote_customer_info',
    })

    // Add required fields from customer info
    const otherParams = {
      firstNameF: info.firstNameF,
      firstNameK: info.firstNameK,
      lastNameF: info.lastNameF,
      lastNameK: info.lastNameK,
      quoteEmailAddress: info.email,
      orgName: info.orgName,
    }
    analytics.pushAndClearEcommerceObject({ ...params, ...otherParams })
  }

  const trackQuoteConfirmation = (quoteId: string | number) => {
    // ga4 - quote_confirmation
    const params = getGA4BasketParams({
      event: 'quote_confirmation',
    })

    // TODO, figure out what this promo is
    // quote_promo: "",
    const otherParams = {
      quote_id: quoteId,
      quote_value: basketItems.value.data.totalPrice?.centAmount,
      quote_item_brand: 'cst',
      quote_quantity: basketItems.value.data._totalLineItemQuantity,
    }

    analytics.pushAndClearEcommerceObject({ ...params, ...otherParams })
  }

  const trackQuoteDownload = (quoteId: string) => {
    // TODO, create a GA4 event
  }

  const trackQuoteSendEmail = (quoteId: string) => {
    // GA4
    const paramsGA4 = {
      event: 'quote_send_email',
      quote_id: quoteId,
      ...analytics.getCustomDimensions().ga4,
    }
    analytics.pushAndClearEcommerceObject(paramsGA4)
  }

  const trackLeadReview = () => {
    const params = getGA4BasketParams({
      event: 'lead_review',
      excludePriceInfo: true,
    })

    const otherParams = {
      quote_item_brand: 'cst',
      quote_quantity: basketItems.value.data._totalLineItemQuantity,
    }

    analytics.pushAndClearEcommerceObject({ ...params, ...otherParams })
  }

  const trackLeadCustomerInfo = () => {
    // GA4
    const params = getGA4BasketParams({
      event: 'lead_additional_info',
      excludePriceInfo: true,
    })

    analytics.pushAndClearEcommerceObject(params)
  }

  const trackLeadSummary = () => {
    const params = getGA4BasketParams({
      event: 'lead_request_summary',
      excludePriceInfo: true,
    })

    const otherParams = {
      quote_item_brand: 'cst',
      quote_quantity: basketItems.value.data._totalLineItemQuantity,
    }

    analytics.pushAndClearEcommerceObject({ ...params, ...otherParams })
  }

  const trackLeadConfirmation = (quoteId: string | number) => {
    const params = getGA4BasketParams({
      event: 'lead_confirmation',
      excludePriceInfo: true,
    })

    const otherParams = {
      quote_id: quoteId,
      quote_item_brand: 'cst',
      quote_quantity: basketItems.value.data._totalLineItemQuantity,
    }

    analytics.pushAndClearEcommerceObject({ ...params, ...otherParams })
  }

  const trackLeadSendEmail = (quoteId: string) => {
    // GA4
    const paramsGA4 = {
      event: 'lead_send_email',
      quote_id: quoteId,
      ...analytics.getCustomDimensions().ga4,
    }
    analytics.pushAndClearEcommerceObject(paramsGA4)
  }

  const trackLeadDownload = (quoteId: string) => {
    // TODO, create a GA4 event
  }

  const trackSignalStarStep = (step: string, override: boolean = false) => {
    // GA4 only
    analytics.pushAndClearEcommerceObject({
      event: 'signal_star',
      SS_step: step,
      ...(override && {
        SS_override: true,
      }),
      ...analytics.getCustomDimensions().ga4,
    })
  }

  return {
    trackQuickAddClick,
    trackMultiQuickAddClick,
    trackAddToCart,
    trackAddToOldPriceBrick,
    trackViewCart,
    trackRemoveFromCart,
    trackCheckoutBegin,
    trackCheckoutShippingInfo,
    trackCheckoutBillingInfo,
    trackCheckoutPlaceOrder,
    trackCheckoutPurchase,
    trackQuoteReview,
    trackQuoteSummary,
    trackQuoteConfirmation,
    trackQuoteCustomerInfo,
    trackQuoteDownload,
    trackQuoteSendEmail,
    trackLeadReview,
    trackLeadCustomerInfo,
    trackLeadSummary,
    trackLeadConfirmation,
    trackLeadSendEmail,
    trackLeadDownload,
    trackSignalStarStep,
  }
}
