import { Context } from '@nuxt/types'
import { useContext } from '@nuxtjs/composition-api'
import { QuoteTemplateData } from '@cstweb/pdf-middleware'
import { useCheckoutStore } from '../checkout/useBasket'
import { useCountry } from '../country/useCountry'
import { mapBasketData } from '~/store'
import {
  EmeaShippingAddressInterface,
  JapanCustomerInfo,
  JpShippingAddressInterface,
  QuoteCartInterface,
  QuoteInterface,
  UsShippingAddressInterface,
} from '~/types/quotes/quotesTypes'
import { formatPrice } from '~/common/utils/numbers'
import { formatDate } from '~/common/utils/dates'

export default function useQuotes(context?: Context) {
  const { $ct: ct, $pdf: pdf } = context ?? useContext()

  const store = useCheckoutStore()
  const { selectedLanguage } = useCountry()

  async function generateQuote() {
    const { data } = await ct.quotes.generateQuote()
    store._updateStore(data)
    return mapQuoteData(data)
  }

  async function setCustomerInfo(info: JapanCustomerInfo) {
    const { data } = await ct.quotes.setCustomerInfo({
      firstNameF: info.firstNameF,
      firstNameK: info.firstNameK,
      lastNameF: info.lastNameF,
      lastNameK: info.lastNameK,
      quoteEmailAddress: info.email,
      isQuoteNewsletterOptIn: info.newsletterOptIn,
      orgName: info.orgName,
      depName: info.depName,
    })
    return mapQuoteData(data)
  }

  async function submitQuote(recaptchaToken?: string, language?: string) {
    const { data } = await ct.quotes.submitQuote(recaptchaToken, language)
    return mapQuote(data)
  }

  async function fetchMyQuotes(params?: { limit: number; offset: number }) {
    const { data: quotes } = await ct.quotes.getQuotes(params)
    return {
      count: quotes.count as number,
      limit: quotes.limit as number,
      offset: quotes.offset as number,
      total: quotes.total as number,
      results: (quotes.results as any[]).map(mapQuote),
    }
  }

  async function downloadQuote(quote: QuoteInterface) {
    const type = quote.cart.shippingAddress.type

    const templateData = mapQuoteToTemplateData(quote)
    await pdf.downloadQuote(templateData, type, selectedLanguage.value)
  }

  async function sendQuoteEmail(quoteNumber: string, anonymousId?: string) {
    await ct.quotes.sendQuoteEmail(quoteNumber, anonymousId)
  }

  return { generateQuote, submitQuote, fetchMyQuotes, downloadQuote, setCustomerInfo, sendQuoteEmail }
}

function mapQuoteData(data: any): QuoteCartInterface {
  let shippingAddress: UsShippingAddressInterface | JpShippingAddressInterface | EmeaShippingAddressInterface
  if (data.shippingAddress.country !== 'JP') {
    shippingAddress = {
      type: data.shippingAddress.country === 'US' ? 'us' : 'emea',
      firstName: data.shippingAddress.firstName,
      lastName: data.shippingAddress.lastName,
      country: data.shippingAddress.country,
      company: data.shippingAddress.company,
      email: data.shippingAddress.email,
    }
  } else {
    shippingAddress = {
      type: 'japan',
      lastNameK: data.shippingAddress.custom?.fields.lastNameK ?? '',
      firstNameK: data.shippingAddress.custom?.fields.firstNameK ?? '',
      lastNameF: data.shippingAddress.custom?.fields.lastNameF ?? '',
      firstNameF: data.shippingAddress.custom?.fields.firstNameF ?? '',
      email: data.shippingAddress.custom?.fields.quoteEmailAddress ?? '',
      newsletterOptIn: data.shippingAddress.custom?.fields.isQuoteNewsletterOptIn ?? false,
      orgName: data.shippingAddress.company ?? '',
      depName: data.shippingAddress.building ?? '',
      country: data.shippingAddress.country,
    }
  }

  return { ...mapBasketData(data), shippingAddress, lastModifiedAt: data.lastModifiedAt }
}

function mapQuote(data: any): QuoteInterface {
  return { ...data, anonymousId: data.cart?.anonymousId, cart: mapQuoteData(data.cart) }
}

function mapQuoteToTemplateData(quoteData: QuoteInterface): QuoteTemplateData {
  const contact = mapQuoteToTemplateContact(quoteData)
  const type = quoteData.cart.shippingAddress.type
  const discounts = `${quoteData.cart.data.totalPromo! > 0 ? '- ' : ''} ${formatPrice(
    quoteData.cart.data.totalPromo!,
    quoteData.cart.data.currencyCode!,
    quoteData.cart.data.fractionDigits!
  )}`
  const shipping = quoteData.cart.data.shippingInfo
    ? formatPrice(
        quoteData.cart.data.shippingInfo?.discountedPrice?.value.centAmount ??
          quoteData.cart.data.shippingInfo!.price.centAmount,
        quoteData.cart.data.currencyCode!,
        quoteData.cart.data.fractionDigits!
      )
    : 'FREE'

  return {
    countryCode: quoteData.cart.shippingAddress.country,
    quoteNumber: quoteData.quoteNumber,
    expirationDate: formatDate(new Date(quoteData.quoteExpirationDate), 'yyyy-MM-dd')!,
    contact,
    creationDate: formatDate(
      new Date(quoteData.quoteCreationDate || quoteData.cart.lastModifiedAt || new Date()),
      'yyyy-MM-dd'
    )!,
    subtotal: formatPrice(
      quoteData.cart.data.subtotalPrice!,
      quoteData.cart.data.currencyCode!,
      quoteData.cart.data.fractionDigits!
    ),
    discounts,
    shipping: type === 'emea' ? 'TBD' : shipping,
    tax: 'TBD',
    total: formatPrice(
      quoteData.cart.data.totalPrice!.centAmount,
      quoteData.cart.data.currencyCode!,
      quoteData.cart.data.fractionDigits!
    ),
    items: Object.values(quoteData.cart.itemsData).map((item) => {
      return {
        sku: item.variant.sku!,
        name: item.name as string,
        size: item.variant.size!,
        quantity: item.quantity,
        listPrice: formatPrice(item.listPrice, quoteData.cart.data.currencyCode!, quoteData.cart.data.fractionDigits!),
        promoCodes: item.appliedPromoNames.join(', '),
        experimentId: item.custom?.fields.experimentId,
        netPrice: formatPrice(item.netPrice, quoteData.cart.data.currencyCode!, quoteData.cart.data.fractionDigits!),
        totalPrice: formatPrice(
          item.totalPriceComputed,
          quoteData.cart.data.currencyCode!,
          quoteData.cart.data.fractionDigits!
        ),
      }
    }),
  }
}

function mapQuoteToTemplateContact(quote: QuoteInterface) {
  if (quote.cart.shippingAddress.type === 'us' || quote.cart.shippingAddress.type === 'emea') {
    return {
      firstName: quote.cart.shippingAddress.firstName,
      lastName: quote.cart.shippingAddress.lastName,
      email: quote.cart.shippingAddress.email,
      organization: quote.cart.shippingAddress.company,
    }
  }
  return {
    firstName: quote.cart.shippingAddress.firstNameK,
    lastName: quote.cart.shippingAddress.lastNameK,
    email: quote.cart.shippingAddress.email,
    organization: quote.cart.shippingAddress.orgName,
    department: quote.cart.shippingAddress.depName,
  }
}
