import { Context } from '@nuxt/types'

export default function isPreview(options: { available: boolean }) {
  return (context: Context) => {
    const isPreview = context.$config.cst.isPreview

    if (isPreview && !options.available) {
      context.error({ statusCode: 404, message: 'Not Available in Preview Mode!' })
    }
  }
}
