import cloneDeep from 'lodash/fp/cloneDeep'
import { defineStore } from 'pinia'
import countryToCurrency from 'country-to-currency'

export interface ConfigStore {
  countryCode: string
  languageCode: string
  currency: string
  regionalSiteAvailable: boolean
  clientIP: string
}

export const useConfigStore = defineStore({
  id: 'config',
  state: (): ConfigStore => {
    return {
      countryCode: 'US',
      currency: 'USD',
      languageCode: 'en',
      regionalSiteAvailable: false,
      clientIP: '',
    }
  },
  actions: {
    set(data: Partial<ConfigStore>) {
      const newConfig = cloneDeep(data)

      if (newConfig.countryCode) {
        newConfig.countryCode = newConfig.countryCode !== 'USA' ? newConfig.countryCode : 'US' // FIXME: remove 'USA' after ATG Sunset
        newConfig.currency = countryToCurrency[newConfig.countryCode as keyof typeof countryToCurrency]
      }

      this.$patch(newConfig)
    },
  },
})
