







import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
})
