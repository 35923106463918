import https from 'https'
import { detectServerBaseUrl } from '@cstweb/common'

export default function ({ $axios, req }, inject) {
  let baseURL = `/api/paymetric`

  if (process.server) {
    const host = process.static ? '' : detectServerBaseUrl(req)
    baseURL = `${host}/api/paymetric`
  }

  // Create a custom axios instance
  const payment = $axios.create({
    baseURL,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  })

  // Inject to context as $payment
  inject('payment', payment)
}
