import { Context } from '@nuxt/types'
import { constants } from '@cstweb/commercetools-client'

type PunchoutMiddlewareOptions = {
  allowForPunchout: boolean
}

// ..
export default function punchoutAccess(middlewareOptions: PunchoutMiddlewareOptions) {
  return (context: Context) => {
    // ..
    const { allowForPunchout = false } = middlewareOptions
    const isPunchout = context.$cookies.get(constants.COOKIES.CUSTOMER)?.isPunchout ?? false

    if ((!allowForPunchout && isPunchout) || (allowForPunchout && !isPunchout)) {
      console.log('** no punchout **')
      context.redirect('/')
    }
  }
}
