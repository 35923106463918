import { APIError } from '@cstweb/commercetools-client'
import { isApiError } from '~/common/utils/errorParsing'

export const ERROR_CODE = {
  MATCHING_PRICE_NOT_FOUND: 'MatchingPriceNotFound',
  BAD_RESPONSE: 'ERR_BAD_RESPONSE',
  GENERAL_ERROR: 'something_went_wrong',
} as const

export const getMappedErrorMessage = (errorCode: string, fallback: string = '') => {
  switch (errorCode) {
    case ERROR_CODE.MATCHING_PRICE_NOT_FOUND:
      return 'Please visit the product page for detailed pricing and availability information.'
    case ERROR_CODE.BAD_RESPONSE:
    case ERROR_CODE.GENERAL_ERROR:
      return 'Something went wrong.'
    default:
      return fallback
  }
}

export type Errors = APIError[]

export const mapExceptionToError = (exception: unknown, fallbackMessage: string = 'Something went wrong.'): Errors => {
  if (isApiError(exception)) {
    return exception.response.data.errors.map((e) => ({ ...e, message: getMappedErrorMessage(e.code, e.message) }))
  }
  return [{ code: '', message: fallbackMessage }]
}

export const isInvalidTypePTMScan = (error: { msg: string }) => error.msg === 'Contact the CST Proteomics Group.'
