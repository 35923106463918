


























import { defineComponent, useRoute, useMeta, watch } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

import Header from '../components/global/Header.vue'
import RenderDebug from '~/components/global/RenderDebug.vue'
// import RedirectVisitorModalVue from '~/components/feature/country-selector/RedirectVisitorModal.vue'

import Footer from '@/components/global/footer/Footer.vue'
import { useSidebarStore, useUserStore } from '@/store'

import LogMiddleware from '@/middleware/log'
import WithDataMiddleware from '@/middleware/with-data'

export default defineComponent({
  components: {
    Header,
    // RedirectVisitorModalVue,
    Footer,
    RenderDebug,
  },
  middleware: [LogMiddleware, WithDataMiddleware],
  setup() {
    const route = useRoute()
    const { sidebar } = useSidebarStore()
    const { htmlAttrs } = useMeta()
    const { selectedLanguage } = storeToRefs(useUserStore())

    watch(selectedLanguage, () => {
      // update the 'lang' attribute on the 'html' element whenever the language is changed
      htmlAttrs.value = { ...htmlAttrs.value, lang: selectedLanguage.value }
    })

    return {
      route,
      sidebar,
    }
  },
  head: {},
})
