import isEmpty from 'lodash/fp/isEmpty'
import { ContentFragment } from '~/modules/contentstack/types'

export type LogicalOperator = 'and' | 'or' | 'not'
export type DateMatch = 'on' | 'between' | 'before' | 'after'

export type Time = {
  date: any
  is_not: boolean
  match: DateMatch
}

export type ConditionValue = {
  is_not: boolean
  value: string | string[]
  domain: string
}

export type RuleCondition = {
  logicalOperator: LogicalOperator
  conditionValues: ConditionValue[]
}

export type Configuration = {
  logicalOperator: LogicalOperator
  conditions: RuleCondition[]
}

export type Rule = {
  time: Time | boolean
  configuration: Configuration | boolean
}

export type ContentComponent = {
  uid: string
  content_type_uid: string
  componentType: string
  contentFragment: ContentFragment
}

export type ContentRule = {
  logicalOperator: LogicalOperator
  contentComponents: ContentComponent[]
  rules: Rule[]
}

export type ContentTarget = {
  contentRules: ContentRule[]
  contentComponents: ContentComponent[]
  devkey: string
}

export function parseContentTargetResponse(data: Record<string, any>): ContentTarget {
  // We are fetching content target based on the devkey, so there will always be only one returned Content Target
  const contentTarget = data.data?.all_content_target?.items
  if (!contentTarget?.length) {
    throw new Error('No Content Target found')
  }
  const target = contentTarget[0]
  const rules = target.content_rules
  return {
    devkey: target.devkey,
    contentComponents: mapContentComponents(target.content_components),
    contentRules: rules?.map((rule: any) => parseContentRule(rule)) ?? [],
  }
}

export function parseManyContentTargetsResponse(data: Record<string, any>): ContentTarget[] {
  const targets = data.data?.all_content_target?.items

  return targets.map((target: any) => ({
    devkey: target.devkey,
    contentComponents: mapContentComponents(target.content_components),
    contentRules: target.content_rules?.map((rule: any) => parseContentRule(rule)) ?? [],
  }))
}

function parseContentRule(rule: any): ContentRule {
  if (isEmpty(rule.rules)) {
    throw new Error('Missing Rules in Content Rule')
  }
  return {
    logicalOperator: rule.match,
    contentComponents: mapContentComponents(rule.content_components),
    rules: rule.rules.map((rule: any) => parseRule(rule)),
  }
}

function mapContentComponents(contentComponents: Record<string, any>[]): ContentComponent[] {
  return (
    contentComponents?.map((contentComponent) => {
      const filteredObject = Object.entries(contentComponent).filter(([key, _value]) => key !== '__typename')
      const componentType = componentTypeMap[filteredObject[0][0] as keyof typeof componentTypeMap]
      return {
        componentType,
        ...filteredObject[0][1].contentConnection.edges[0].node.system,
        contentFragment: filteredObject[0][1].contentConnection.edges[0].node,
      }
    }) ?? []
  )
}

function parseRule(rule: any): Rule {
  return {
    time: isEmpty(rule.rule.time) ? true : parseTime(rule.rule.time.at(0)),
    configuration: isEmpty(rule.rule.configurations) ? true : parseConfiguration(rule.rule.configurations.at(0)),
  }
}

function parseTime(time: any): Time {
  if (isEmpty(time.date)) {
    throw new Error('Missing Date in Time')
  }
  return time
}

function parseConfiguration(configuration: any): Configuration | boolean {
  if (isEmpty(configuration.conditions)) {
    return true
  }
  return {
    logicalOperator: configuration.match,
    conditions: configuration.conditions.map((condition: any) => parseCondition(condition)),
  }
}

function parseCondition(condition: any): RuleCondition {
  if (isEmpty(condition.condition_values)) {
    throw new Error('Missing Condition Value in Condition')
  }
  return {
    logicalOperator: condition.match,
    conditionValues: condition.condition_values.map((value: any) => parseConditionValue(value)),
  }
}

function parseConditionValue(value: any): ConditionValue {
  if (isEmpty(value.entityConnection.edges) && isEmpty(value.value)) {
    throw new Error('Missing Entity and Value in Condition Value')
  }
  // Domain (1 to 1) - there is always one
  // Entity (0 to 1) - none or one
  return {
    is_not: value.is_not,
    domain: value.metadata_domainConnection.edges.at(0).node.devkey,
    value: isEmpty(value.entityConnection.edges)
      ? value.value
      : mapValueBasedOnEntityTypeName(value.entityConnection.edges.at(0).node),
  }
}

function mapValueBasedOnEntityTypeName(node: any) {
  switch (node.__typename) {
    case 'Audience': {
      return node.countriesConnection.edges.map((country: any) => country.node.country_code)
    }
    case 'Country': {
      // make array from string of country code, so we can have only one 'country_codes' domain
      return node.country_code.split(' ')
    }
    case 'MetadataDomainValue': {
      return node.value
    }
    default: {
      throw new Error('Failed to match value based on entity type name. Node: ' + JSON.stringify(node, undefined, 2))
    }
  }
}

const componentTypeMap = {
  generic: 'Generic',
  additional_information: 'AdditionalInformation',
  legal_statement: 'LegalStatement',
} as const
