import { APIError } from 'src/middleware/error-mapping'
import { mapDiscountCodeNonApplicableError } from './discountCodeNonApplicable'
import { mapInvalidInputError } from './invalidInputErrorMapper'

export const errorMessageMap: Record<string, (error: APIError) => string> = {
  InvalidCredentials: () => 'Email Address or password incorrect.',
  DiscountCodeNonApplicable: mapDiscountCodeNonApplicableError,
  InvalidVatId: () => 'VAT registration number not valid. Please enter a valid number.',
  InvalidInput: mapInvalidInputError,
}
