import cloneDeep from 'lodash/fp/cloneDeep'
import { CustomAxiosRequestConfig, CustomAxiosResponse, CustomAxiosError } from '@cstweb/common'
import { Client } from '../client'
import { errorMessageMap } from '../util/errorMappers'

export interface APIError {
  code: string
  message: string
}

const MAP_OF_ERROR_CODES = {
  UNKNOWN_ERROR: 'UnknownError',
  NETWORK_ERROR: 'NetworkError',
}

const MAP_OF_CUSTOM_ERROR_MESSAGES = {
  [MAP_OF_ERROR_CODES.UNKNOWN_ERROR]: 'Unknown error. Please try again later.',
  [MAP_OF_ERROR_CODES.NETWORK_ERROR]: 'Network error. Please try again later.',
}

const mapCustomApiErrorMessage = (apiError: APIError): APIError => {
  const error = { ...apiError }

  if (apiError.code in errorMessageMap) {
    error.message = errorMessageMap[apiError.code](error)
  }

  return error
}

const mapApiErrors = (errors: APIError[]) => {
  return errors.map((error) => mapCustomApiErrorMessage(error))
}

export const getEnhancedError = (error: any, errors: APIError[], extendData = true) => {
  const newError = cloneDeep(error)
  newError.response = newError.response ?? {}
  newError.response.data = extendData
    ? JSON.stringify({
        ...(error?.response?.data && typeof error?.response?.data === 'string'
          ? JSON.parse(error?.response?.data)
          : error?.response?.data || {}),
        errors,
      })
    : JSON.stringify({ errors })

  return newError
}

export default (_client: Client) => {
  return {
    onResponseError(error: CustomAxiosError): Promise<any> {
      const { config, response }: { config?: CustomAxiosRequestConfig; response?: CustomAxiosResponse } = error

      if (config && response && response.data) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // Check if the response is JSON or not
        const isJSON = response.headers && response.headers['content-type']?.includes('application/json')
        // If JSON, use json(). Otherwise, use text().
        // let msg = isJSON ? response.data.json() : response.data.text()

        if (isJSON) {
          const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data

          let { errors: apiErrors } = data
          if (!apiErrors) {
            apiErrors = [data]
          }

          const errors = mapApiErrors(apiErrors)
          console.debug('>>>>> Default Error')
          throw getEnhancedError(error, errors)
        } else {
          // console.error(error.response.data)
          const errors = [{ code: 'something_went_wrong', message: response.data }]
          console.debug('>>>>> Something went wrong Error')
          throw getEnhancedError(error, errors, false)
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // `http.ClientRequest` in node.js
        const errors: APIError[] = [
          {
            code: MAP_OF_ERROR_CODES.NETWORK_ERROR,
            message: MAP_OF_CUSTOM_ERROR_MESSAGES[MAP_OF_ERROR_CODES.NETWORK_ERROR],
          },
        ]
        console.debug('>>>>> Network Error')
        throw getEnhancedError(error, errors, false)
      } else {
        // Something happened in setting up the request that triggered an Error
        const errors: APIError[] = [
          {
            code: MAP_OF_ERROR_CODES.UNKNOWN_ERROR,
            message: MAP_OF_CUSTOM_ERROR_MESSAGES[MAP_OF_ERROR_CODES.UNKNOWN_ERROR],
          },
        ]
        console.debug('>>>>> Unknown Error')
        throw getEnhancedError(error, errors, false)
      }
    },
  }
}
