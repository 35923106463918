import isEmpty from 'lodash/fp/isEmpty'
import has from 'lodash/fp/has'

import { CookieSettings, mergeCookieSettings } from '@cstweb/common'

import { CookieProvider, CookieStoreConfig, CookieStoreSimpleValue, CookieStoreComplexValue } from '../types'

abstract class CookieStore {
  readonly namespace: string
  readonly provider: CookieProvider
  readonly settings: CookieSettings

  static namespace<T>(
    this: new (namespace: string, config: CookieStoreConfig) => T,
    namespace: string,
    config: CookieStoreConfig
  ) {
    return new this(namespace, config)
  }

  constructor(namespace: string, config: CookieStoreConfig) {
    this.namespace = namespace
    this.provider = config.provider
    this.settings = mergeCookieSettings(config.settings)
  }

  remove() {}
  persist() {}
  migrate() {
    const allCookies = this.provider.getAll()
    if (has(this.namespace, allCookies)) {
      this.provider.set(this.namespace, allCookies[this.namespace], this.settings)
    }
  }
}

export class CookieComplexStore extends CookieStore {
  value: CookieStoreComplexValue = {} // in memory cookie value
  fetching: boolean = false

  get(key: string) {
    if (isEmpty(this.value)) {
      const cookies = this.provider.getAll()
      this.value = cookies[this.namespace] ?? {}
    }

    // console.log('!!!!!!!!')
    // console.log(JSON.stringify({ cookie: this.namespace, value: this.value }))
    return this.value[key]
  }

  set(key: string, value: CookieStoreSimpleValue) {
    if (isEmpty(this.value)) {
      this.value = {}
    }
    this.value[key] = value
  }

  getValue() {
    return this.value
  }

  setValue(value: CookieStoreComplexValue) {
    this.value = value
    // this.persist()
  }

  clear() {
    this.value = {} // clean in memory cookie value
    this.persist() // set cookie into cookie header
  }

  remove() {
    this.value = {} // clean in memory cookie value
    this.provider.remove(this.namespace, this.settings)
  }

  persist() {
    this.provider.set(this.namespace, isEmpty(this.value) ? undefined : this.value, this.settings)
  }
}

export class CookieSimpleStore extends CookieStore {
  value: CookieStoreSimpleValue = undefined

  get() {
    if (isEmpty(this.value)) {
      const cookies = this.provider.getAll()
      this.value = cookies[this.namespace] ?? undefined
    }
    return this.value
  }

  set(value: CookieStoreSimpleValue): void {
    this.value = value
  }

  clear() {
    this.value = undefined
    this.persist()
  }

  remove() {
    this.value = undefined
    this.provider.remove(this.namespace, this.settings)
  }

  persist() {
    this.provider.set(this.namespace, this.value, this.settings)
  }
}
