import { Context } from '@nuxt/types'
import { useConfigStore } from '~/store/config'

type CountryMiddlewareOptions = {
  countryCodes: string[]
  allowAccess?: boolean
}

export function countryAccess({ countryCodes = [], allowAccess = false }: CountryMiddlewareOptions) {
  return (context: Context) => {
    const configStore = useConfigStore(context.$pinia)

    let countryCookie = configStore.countryCode ?? context.$cookies.get('c_country')

    countryCookie = countryCookie !== 'USA' ? countryCookie : 'US' // FIXME: remove 'USA' after ATG Sunset

    const failedAllowCon = !countryCodes.includes(countryCookie) && allowAccess
    const failedDenyCon = countryCodes.includes(countryCookie) && !allowAccess

    if (failedAllowCon || failedDenyCon) {
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
      context.redirect('/')
    }
  }
}
