














import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { useProductStore } from '~/store/product'

export default defineComponent({
  setup() {
    const { $config } = useContext()
    const renderTarget = process.static ? 'STATIC' : 'SSR'
    const renderTimestamp = new Date().toISOString()
    const commit = $config.commitSha

    const productStore = useProductStore()
    const currentProduct = productStore.currentProduct

    return {
      debugInfo: {
        renderTarget,
        renderTimestamp,
        commit,
        currentProduct,
      },
    }
  },
})
