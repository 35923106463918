import { v4 as uuidv4 } from 'uuid'
import { Context } from '@nuxt/types'
import { constants, findCookieDomain } from '@cstweb/commercetools-client'
import { detectServerBaseUrl, mergeCookieSettings, extractCloudflareHeaders } from '@cstweb/common'
import { useUserStore } from '~/store/user'
import { useCheckoutStore } from '~/store/checkout'
import { useProfileStore } from '~/store/profile'
import { useConfigStore } from '~/store/config'
import { useFeatureStore } from '~/store/featureFlags'
import { useProductStore } from '~/store/product'
import { useCountry } from '~/composables/country/useCountry'
import { ProductPriceInterface } from '~/types/product/productTypes'
import { isCountryCodeValid } from '~/common/utils/country'
import { EVENT_SESSION_ID_COOKIE } from '~/common/constants/utils'

export default async function ({ $pinia, $ct, i18n, $user, $cookies, req, route, redirect }: Context) {
  /**
   * Do not execute this plugin during static site page generation on the server.
   * Execute this plugin only when:
   * 1. process.static=true AND process.client=true
   * 2. process.static=false AND (process.client=true OR process.server=true)
   *
   * Moving this check here from nuxt.config.js because plugin was not being executed on JP/CN proxies
   */
  if (process.static && process.server) {
    return
  }

  const host = process.client ? location.origin : detectServerBaseUrl(req)!
  const cookieDomain = findCookieDomain(host ?? '', false) // Do not enable full domain cookies for c_country/c_language cookies
  const cookieSettings = mergeCookieSettings({ domain: cookieDomain })

  const domainParts = cookieDomain?.split('.') ?? []
  const tld = domainParts.length ? domainParts[domainParts.length - 1] : ''

  // console.log('->>> TLD: ', tld)

  const profileStore = useProfileStore($pinia)
  const userStore = useUserStore($pinia)
  const checkoutStore = useCheckoutStore($pinia)
  const configStore = useConfigStore($pinia)
  const productStore = useProductStore($pinia)

  const customerIdCookie = $ct.client.cookies.customer.get(constants.CUSTOMER_STORE_FIELD.CUSTOMER_ID)
  const isLoggedIn = customerIdCookie !== undefined

  const cartIdCookie = $ct.client.cookies.cart.get(constants.CART_STORE_FIELD.CART_ID)
  const hasCart = cartIdCookie !== undefined

  const getSettings = () => {
    return process.client ? $user.fetchSettings() : Promise.resolve(extractCloudflareHeaders(req.headers))
  }

  const [settingsData, profileData] = await Promise.all([
    tld === 'jp' || tld === 'cn' ? Promise.resolve({}) : getSettings(),
    isLoggedIn ? profileStore.fetchProfile() : Promise.resolve(undefined),
  ])

  let countryCode: string = $cookies.get(constants.COOKIES.HYBRID_COUNTRY_CODE) ?? settingsData.countryCode ?? 'US'
  let languageCode: string = $cookies.get(constants.COOKIES.HYBRID_LANGUAGE_CODE) ?? 'en'

  const url = new URL(process.client ? location.href : (host.endsWith('/') ? host.slice(0, -1) : host) + route.fullPath)
  const queryCountryCode = url.searchParams.get('country')
  const queryLanguageCode = url.searchParams.get('language')
  const eventSessionIdCookie = $cookies.get(EVENT_SESSION_ID_COOKIE)

  const queryValidCountryCode = queryCountryCode && isCountryCodeValid(queryCountryCode) ? queryCountryCode : 'US'
  // const queryValidLanguageCode = queryLanguageCode && isLanguageValid(queryLanguageCode) ? queryLanguageCode : 'en'

  if (profileData?.custom?.fields?.registrationOrigin) {
    countryCode = profileData.custom.fields.registrationOrigin
  } else if (tld === 'jp' || tld === 'cn') {
    countryCode = tld === 'jp' ? 'JP' : 'CN'
  } else {
    countryCode = queryCountryCode ? queryValidCountryCode : countryCode
  }

  // console.log('Country: ', countryCode)

  if (countryCode === 'JP') {
    languageCode = 'jp'
  } else if (countryCode === 'CN') {
    languageCode = 'cn'
  } else {
    // languageCode = queryLanguageCode ? queryValidLanguageCode : languageCode
    languageCode = 'en'
  }

  const eventSessionId = eventSessionIdCookie || uuidv4()
  if (!eventSessionIdCookie) {
    $cookies.set(EVENT_SESSION_ID_COOKIE, eventSessionId, cookieSettings)
  }

  setCountryCodeCookie($cookies, countryCode, cookieSettings)
  setLanguageCodeCookie($cookies, languageCode, cookieSettings)

  userStore.init(countryCode, languageCode)
  configStore.set({
    countryCode,
    // regionalSiteAvailable: (countryCode === 'JP' && languageCode === 'jp') || countryCode === 'CN',
    languageCode: languageCode || i18n.defaultLocale,
    clientIP: settingsData?.clientIP ?? '',
  })

  if (queryCountryCode || queryLanguageCode) {
    url.searchParams.delete('country')
    url.searchParams.delete('language')
    if (process.client) {
      location.href = url.toString()
    } else {
      redirect(url.toString())
    }
    return
  }

  await Promise.all([hasCart ? checkoutStore.fetchBasket() : Promise.resolve(), useFeatureStore().fetchAll()])

  // Because country is set on the client, the country on the server is always US, so we need to reassign
  // priceForCurrentCountry in the product store with current country
  const { isB2DCountry } = useCountry()
  Object.values(productStore.products).forEach((product) => {
    Object.values(product.data.skuData).forEach((sku) => {
      sku.priceForCurrentCountry =
        sku.prices?.find((price) => price.country === (isB2DCountry.value ? 'US' : userStore.selectedCountry)) ??
        // we need to reset priceForCurrentCountry as fallback, since some SKU may not have prices for some countries
        // and from the server it comes with US price (when available) as a fallback
        ({} as ProductPriceInterface)
    })
  })
}

const setCountryCodeCookie = ($cookies: any, countryCode: string = 'US', cookieSettings: any) => {
  const cc = countryCode !== 'US' ? countryCode : 'USA' // FIXME: remove 'USA' after ATG Sunset
  $cookies.set(constants.COOKIES.HYBRID_COUNTRY_CODE, cc, cookieSettings)
}

const setLanguageCodeCookie = ($cookies: any, languageCode: string = 'en', cookieSettings: any) => {
  $cookies.set(constants.COOKIES.HYBRID_LANGUAGE_CODE, languageCode, cookieSettings)
}
