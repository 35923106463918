// import subDays from 'date-fns/subDays'
import cloneDeep from 'lodash/fp/cloneDeep'
import countryToCurrency from 'country-to-currency'
import { CustomAxiosRequestConfig, CustomAxiosResponse } from '@cstweb/common'
import { Client } from '../client'
import { addExpandParams, hasCart, hasCustomer, hasPunchoutCustomer, isCartOlderThanXDays } from '../util'
import { editResponseForPromotions } from '../util/parser'

import {
  CART_STORE_FIELD,
  URL_CARTS,
  URL_ACTIVE_CART,
  URL_LOGIN,
  URL_SIGNUP,
  AUTH_STORE_FIELD,
  ACTION_RECALCULATE,
  HTTP_STATUS,
} from '../util/constants'

import { getEnhancedError } from './error-mapping'

interface ActionInterface {
  action: string
  sku: string
  quantity: number
  custom?: {
    typeKey: string
    fields: {
      experimentId: string | null
    }
  }
}

interface RequestDataInterface {
  version: number
  actions: ActionInterface[]
}

interface RequestDataNewCartInterface {
  currency: string
  country: string
  lineItems: {
    sku: string
    quantity: number
  }[]
  custom?: Record<string, any>
}

function hasRecalculateAction(actions: Partial<ActionInterface>[]) {
  return actions.find((a) => a.action === ACTION_RECALCULATE.action)
}

function addRecalculateProductDataAction(actions: Partial<ActionInterface>[]) {
  // Do not add recalculate action if there is one already.
  if (!hasRecalculateAction(actions)) {
    actions.push(ACTION_RECALCULATE)
  }
  return actions
}

function getRequestPayload(
  data: RequestDataInterface,
  client: Client
): RequestDataInterface | RequestDataNewCartInterface {
  if (hasCart(client.cookies.cart)) {
    const cartVersion = Number(client.cookies.cart.get(CART_STORE_FIELD.CART_VERSION)) || 0
    const payload = {
      // if we need to recalculate
      version: hasRecalculateAction(data.actions) ? data.version || cartVersion : cartVersion,
      actions: data.actions,
    }
    return payload
  }

  // FIXME: Remove after ATG removed
  let countryCode = client.cookies.hybridCountryCode.get() as string
  // console.log('🚀 ~ countryCode[1]:', countryCode)

  countryCode =
    client.settings?.b2dCountries && client.settings?.b2dCountries.includes(countryCode?.toLowerCase())
      ? 'US'
      : countryCode
  // console.log('🚀 ~ countryCode[2]:', countryCode)
  countryCode = countryCode !== 'USA' ? countryCode : 'US' // FIXME: remove 'USA' after ATG Sunset
  // console.log('🚀 ~ countryCode[3]:', countryCode)

  const cartCheckoutType =
    client.settings?.b2dCountries && client.settings?.b2dCountries.includes(countryCode?.toLowerCase())
      ? 'quoteb2d'
      : undefined

  // Add custom `checkoutType` attribute to the cart.
  // This is required for B2D to work. B2D flow expects this attribute to be set on cart creation.
  let customFields
  if (cartCheckoutType) {
    customFields = {
      type: {
        typeId: 'type',
        key: 'order-type',
      },
      fields: {
        checkoutType: cartCheckoutType,
      },
    }
  }

  return {
    country: countryCode ?? 'US',
    currency: countryToCurrency[countryCode as keyof typeof countryToCurrency] ?? 'USD',
    lineItems: data.actions.map((item) => ({
      sku: item.sku,
      quantity: item.quantity,
      ...(item.custom?.fields?.experimentId && {
        custom: {
          typeKey: 'line-item-type',
          fields: {
            experimentId: item.custom?.fields?.experimentId || '',
          },
        },
      }),
    })),
    custom: customFields,
  }
}

const ERROR_NO_ACTIVE_CART = {
  statusCode: '404',
  message: 'No active cart exists.',
  errors: [
    {
      code: 'ResourceNotFound',
      message: 'No active cart exists.',
    },
  ],
}

const ERROR_NO_CART_WITH_ID = {
  statusCode: '404',
  message: 'Cart with provided id does not exist.',
  errors: [
    {
      code: 'ResourceNotFound',
      message: 'Cart with provided id does not exist.',
    },
  ],
}

export const CartMiddleware = (client: Client) => {
  const axios = client.axios
  const cartCookieStore = client.cookies.cart
  const customerCookieStore = client.cookies.customer
  const hybridCartQuantityCookieStore = client.cookies.hybridCartQuantity

  return {
    // FIXME: Remove after hybrid demo - remove cookies from options above

    onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        return config
      }

      if (config.url?.startsWith(URL_CARTS)) {
        if (!config.url.includes(URL_ACTIVE_CART)) {
          if (hasCart(cartCookieStore)) {
            config.url = `${URL_CARTS}/${cartCookieStore.get(CART_STORE_FIELD.CART_ID)}`
          } else if (hasPunchoutCustomer(customerCookieStore)) {
            throw getEnhancedError(
              {
                config,
                response: {
                  status: HTTP_STATUS.NOT_FOUND,
                  headers: {
                    'content-type': 'application/json',
                  },
                },
              },
              ERROR_NO_CART_WITH_ID.errors,
              false
            )
          } else if (config.method === 'get') {
            config.url = URL_ACTIVE_CART
          }
        }

        if (!config.syncVersion && (config.method === 'get' || config.method === 'post')) {
          config.params = addExpandParams(config.params)
        }

        if (config.method === 'post') {
          const jsonData = config.data && JSON.parse(config.data)
          if (jsonData) {
            const newData = getRequestPayload(jsonData, client)
            config.data = JSON.stringify(newData)
          }

          if (!config.syncVersion) {
            if (!hasCart(cartCookieStore) && hasCustomer(customerCookieStore)) {
              // check for punchout user already done above, so not needed here
              console.log('CTC: FETCH Active Cart...')
              const promiseFn = async (resolve: any, _reject: any) => {
                try {
                  await axios.get(URL_ACTIVE_CART)
                  // resolve(config)
                } catch (error) {
                  console.error(error)
                } finally {
                  resolve(config)
                }
              }
              return new Promise(promiseFn)
            }
          }
        }

        if (config.method === 'delete' && hasCart(cartCookieStore)) {
          const cartVersion = cartCookieStore.get(CART_STORE_FIELD.CART_VERSION) as string
          if (cartVersion) {
            const params = new URLSearchParams(config.params)
            params.append('version', cartVersion)
            config.params = params
          }
        }
      }

      return config
    },
    onResponse(response: CustomAxiosResponse) {
      const { config, data } = response

      if ((config.url && [URL_LOGIN, URL_SIGNUP].includes(config.url)) || config.url?.includes(URL_CARTS)) {
        if (config.method === 'delete' && config.url.includes(URL_CARTS)) {
          client.clearCartCookies()
          return response
        }

        let jsonData = data && typeof data === 'string' ? JSON.parse(data) : data
        jsonData = jsonData || {}

        let cartId, version, totalLineItemQuantity
        let lastModifiedAt: string = ''

        if (jsonData.cart) {
          cartId = jsonData.cart.id
          version = jsonData.cart.version
          totalLineItemQuantity = jsonData.cart.totalLineItemQuantity
          lastModifiedAt = jsonData.cart.lastModifiedAt
        }

        if (jsonData.type && jsonData.type.toLowerCase() === 'cart') {
          cartId = jsonData.id
          version = jsonData.version
          totalLineItemQuantity = jsonData.totalLineItemQuantity
          lastModifiedAt = jsonData.lastModifiedAt
        }

        if (cartId) {
          cartCookieStore.set(CART_STORE_FIELD.CART_ID, cartId)
          cartCookieStore.set(CART_STORE_FIELD.CART_VERSION, version)

          // Test Recalculating cart, when it is older the 1 day
          // console.log('************************************')
          // lastModifiedAt = (subDays(new Date(lastModifiedAt), 2)).toISOString()
          // console.log(lastModifiedAt)

          cartCookieStore.set(CART_STORE_FIELD.LAST_MODIFIED_AT, lastModifiedAt)
          hybridCartQuantityCookieStore.set(totalLineItemQuantity)

          cartCookieStore.persist()
          hybridCartQuantityCookieStore.persist()
        }

        response.data = JSON.stringify(editResponseForPromotions(jsonData))
      }
      return response
    },
  }
}

// @deprecated
export const CartNotExistsMiddleware = (client: Client) => {
  const authCookieStore = client.cookies.auth
  const cookieProvider = client.cookiesProvider.provider
  // const cartCookieStore = Client.cartCookieStore

  return {
    onRequest(config: CustomAxiosRequestConfig) {
      if (config.url?.includes(URL_ACTIVE_CART) || config.url?.includes(URL_CARTS)) {
        const accessToken = authCookieStore.get(AUTH_STORE_FIELD.ACCESS_TOKEN)
        // const cartId = cartCookieStore.get(CART_STORE_FIELD.CART_ID)

        // .. Do make request if its panelBuilder Anonymous flow (step-4 panel-builder edge case)
        const panelBuilderInitialized = cookieProvider.get('panel-builder')
        if (panelBuilderInitialized && !accessToken) {
          return config
        }

        const error = config.url.includes(URL_ACTIVE_CART) ? ERROR_NO_ACTIVE_CART : ERROR_NO_CART_WITH_ID

        // Don't make a request if customer/anonymous token is not available.
        if (!accessToken) {
          throw error
        }
      }

      return config
    },
  }
}

export const CartRecalcMiddleware = (client: Client) => {
  const axios = client.axios
  const cartCookieStore = client.cookies.cart

  return {
    onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        return config
      }
      if (config.url?.startsWith(URL_CARTS) && config.method === 'post') {
        const jsonData = config.data && JSON.parse(config.data)
        if (jsonData) {
          if (isCartOlderThanXDays(cartCookieStore, 1)) {
            console.debug('CTC: Recalculating Cart - [old_cart]')
            addRecalculateProductDataAction(jsonData.actions)
          }
          config.data = JSON.stringify(jsonData)
        }
      }

      return {
        ...config,
        recalculateCart: config.recalculateCart || false,
      }
    },
    onResponse(response: CustomAxiosResponse) {
      const { config, data } = response

      if (!config.recalculateCart && config.url?.startsWith(URL_CARTS) && config.method === 'post') {
        let jsonData = data && typeof data === 'string' ? JSON.parse(data) : data
        jsonData = jsonData || {}

        if (!jsonData.taxedPrice) {
          const actions = [ACTION_RECALCULATE]
          const newData = {
            version: jsonData.version,
            actions,
          }
          const newConfig = {
            url: URL_CARTS,
            method: 'post',
            data: JSON.stringify(newData),
            recalculateCart: true,
          }
          console.debug('CTC: Recalculating Cart - [taxed_price]')
          return axios(newConfig)
        }
      }
      return response
    },
  }
}

// (WEBP-4924)
export const CartRecalcAfterLoginMiddleware = (client: Client) => {
  const axios = client.axios

  return {
    async onResponse(response: CustomAxiosResponse) {
      const { config, data } = response

      if (!config.recalculateCart && config.url?.startsWith(URL_LOGIN) && config.method === 'post') {
        let jsonData = data && typeof data === 'string' ? JSON.parse(data) : data
        jsonData = jsonData || {}

        if (jsonData.cart) {
          const actions = [ACTION_RECALCULATE]
          const data = {
            version: jsonData.cart.version,
            actions,
          }
          const newConfig = {
            url: `${URL_CARTS}`,
            method: 'post',
            data: JSON.stringify(data),
            recalculateCart: true,
          }

          const promiseFn = async (resolve: any) => {
            try {
              console.debug('CTC: Recalculating Cart - [after_login]')
              await axios(newConfig)

              // no catch here, do not block login in case of any error
            } finally {
              resolve(response)
              console.debug('CTC: Recalculating Cart - [after_login] - complete')
            }
          }

          await new Promise(promiseFn)
        }
      }
      return response
    },
  }
}

// (WEBP-8591): DE Cart created for US Customer
export const RemoveCartWithInvalidCountryCode = (client: Client) => {
  const axios = client.axios

  return {
    onResponse(response: CustomAxiosResponse) {
      const { config, data } = response

      if (config.url && [URL_LOGIN, URL_SIGNUP].includes(config.url)) {
        let jsonData = data && typeof data === 'string' ? JSON.parse(data) : data
        jsonData = cloneDeep(jsonData) || {}

        if (jsonData.cart && jsonData.customer) {
          if (
            jsonData.cart.country !== jsonData.customer.custom.fields.registrationOrigin &&
            !(
              jsonData.cart.country === 'US' &&
              client.settings?.b2dCountries &&
              client.settings?.b2dCountries.includes(jsonData.customer.custom.fields.registrationOrigin.toLowerCase())
            )
          ) {
            const promiseFn = async (resolve: any, _reject: any) => {
              try {
                const newConfig = {
                  url: `${URL_CARTS}`,
                  method: 'delete',
                }
                await axios(newConfig)
                delete jsonData.cart
                response.data = jsonData
                // resolve(response)
              } finally {
                // catch (error) {
                // console.error(error)
                // reject(error)
                // }
                resolve(response)
              }
            }

            return new Promise(promiseFn)
          }
        }
      }
      return response
    },
  }
}
