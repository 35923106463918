















































import { storeToRefs } from 'pinia'
import { useRouter, defineComponent, computed } from '@nuxtjs/composition-api'
import { toggleShowBasket } from '@/composables/checkout/useBasket'
import { useCheckoutStore } from '@/store/checkout'
import { useCountry } from '~/composables/country/useCountry'
import { useI18n } from '~/composables/i18n'

export default defineComponent({
  setup() {
    const router = useRouter()

    const { basketItems } = storeToRefs(useCheckoutStore())
    const { isB2DCountry, isJapan, isQuotesCountry } = useCountry()
    const { t } = useI18n()

    const showGenerateQuote = computed(() => isB2DCountry.value || isQuotesCountry.value)
    const showSidebarQuoteText = computed(() => isQuotesCountry.value && !isJapan.value)

    const nextStep = () => {
      router.push('/quote/review')
      toggleShowBasket(false)
    }

    return {
      nextStep,
      basketItems,
      isJapan,
      showSidebarQuoteText,
      showGenerateQuote,
      isB2DCountry,
      t,
    }
  },
})
