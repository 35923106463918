import { URL_SIGNALSTAR, URL_SIGNALSTAR_SEND_EMAIL, URL_SIGNALSTAR_CREATE_EXPERIMENT } from '../util/constants'
import {
  TargetSpecies,
  SignalstarTargetAntibodies,
  CreateExperimentReqPayload,
  CreateExperimentResponse,
  SendExperimentEmailPayload,
} from '../types/signalstar'
import { Client } from '../client'

export const bindClient = (client: Client) => {
  // .. .. Data related
  /**
   * @name getSignalstarProducts
   * Retrieves Signalstar antibodies based on provided target
   * (Used SS Step 2)
   * @param targetSpecies as string H = Human, M = Mouse
   * @param countryCode as string
   *
   * @returns Array of SS Antibodies based on target
   */
  const getSignalstarProducts = (targetSpecies: TargetSpecies, countryCode: string) => {
    return client.axios.get<SignalstarTargetAntibodies>(URL_SIGNALSTAR + '/' + targetSpecies, {
      params: { country: countryCode },
    })
  }

  // .. .. Email Related
  /**
   * @name sendExperimentEmail
   * Send email to customer email address
   * (Used SS Step 4)
   * @param payload as SendExperimentEmailPayload
   */
  const sendExperimentEmail = (payload: SendExperimentEmailPayload) => {
    return client.axios.post(URL_SIGNALSTAR_SEND_EMAIL, payload)
  }

  /**
   * @name createExperiment
   * Create commerce tools experiment object instance, generates pdf, sends email to salesforce
   * (Used between step 3-4)
   * @param payload as CreateExperimentReqPayload
   *
   * @returns Rest of the configuration object CreateExperimentResponse
   */
  const createExperiment = (payload: CreateExperimentReqPayload) => {
    return client.axios.post<CreateExperimentResponse, any>(URL_SIGNALSTAR_CREATE_EXPERIMENT, payload)
  }

  return {
    getSignalstarProducts,
    sendExperimentEmail,
    createExperiment,
  }
}
