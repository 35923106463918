import type { Context } from '@nuxt/types'
import { getEmailDomain } from '~/common/utils/characters'
import { useProfileStore } from '~/store'

export default function (ctx: Context) {
  const profileStore = useProfileStore(ctx.$pinia)

  // For local development, allow all users to access the example pages
  if (process.env.NODE_ENV !== 'production') {
    return true
  }

  // .. Method to enable company domain to access example pages
  const isTrustedLogin = () => {
    return (
      getEmailDomain(profileStore.userInfo.email) === 'cellsignal' ||
      getEmailDomain(profileStore.userInfo.email) === 'globallogic'
    )
  }

  if (!isTrustedLogin()) {
    return ctx.redirect(`/`)
  }
}
