import https from 'https'
import { detectServerBaseUrl, findCookieDomain, findTLD } from '@cstweb/common'
import { Client, OAuthClient } from '@cstweb/commercetools-client'
import { TLD } from '@/common/constants/utils'
import { attachAxiosResponseTimeLoggerInterceptor } from '@/common/utils/logging'
import { useCMSGlobalSettingsStore } from '~/store/cms'

export default function ({ req, $axios, $cookies, $config, $logger, $logService, $toast, redirect }: any, inject: any) {
  const logger = $logger.child({ name: 'log:ct:client' })
  const FEATURE_FULL_DOMAIN_CT_COOKIES = $config.feature.fullDomainCTCookies === 'true'

  const debug = false
  let baseURL = `${$config.cst.apiGatewayUrl}`
  let baseURLAuth = `${$config.cst.apiGatewayUrl}`
  let host
  let cookieDomain: string = ''
  let tldCookieDomain: string = ''
  let fullCookieDomain: string = ''
  let httpsAgent
  let httpsAgentAuth
  let cookieHeader
  let path

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)
    baseURL = `${baseURL}/api`
    // DO NOT REMOVE ▼
    // baseURLAuth = !process.static ? `${host}/api/ct` : `${baseURLAuth}/api`
    baseURLAuth = `${baseURLAuth}/api`
    tldCookieDomain = findCookieDomain(host)
    fullCookieDomain = findCookieDomain(host, true)
    cookieDomain = FEATURE_FULL_DOMAIN_CT_COOKIES ? fullCookieDomain : tldCookieDomain
    cookieHeader = process.static ? '' : req.headers.cookie
    httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    })
    httpsAgentAuth = new https.Agent({
      rejectUnauthorized: false,
    })
    path = process.static ? '' : req.url
  }

  if (process.client) {
    host = location.origin

    if (TLD.CN.toLowerCase() === findTLD(host)?.toLowerCase()) {
      baseURL = $config.cst.cn.apiGatewayUrl || baseURL
    }

    baseURL = `${baseURL}/api`
    // DO NOT REMOVE ▼
    // baseURLAuth = `/api/ct`
    baseURLAuth = `${baseURLAuth}/api`
    tldCookieDomain = findCookieDomain(host)
    fullCookieDomain = findCookieDomain(host, true)
    cookieDomain = FEATURE_FULL_DOMAIN_CT_COOKIES ? fullCookieDomain : tldCookieDomain
    cookieHeader = window.document.cookie
    path = location.pathname
  }

  logger.debug({ baseURL, tld: findTLD(host) }, '[ct-client]: setup')

  const axios = $axios.create({
    // timeout: 5000,
    baseURL,
    httpsAgent,
    debug,
    headers: {
      'Content-Type': 'application/json',
    },
  }) // @nuxtjs/axios

  const authAxios = $axios.create({
    // timeout: 5000,
    baseURL: baseURLAuth,
    httpsAgent: httpsAgentAuth,
    debug,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  attachAxiosResponseTimeLoggerInterceptor({ axios, path, logger })

  const authClient = OAuthClient.buildClient({
    axios: authAxios,
    // DO NOT REMOVE ▼
    // credentials:
    //   process.static && process.server
    //     ? {
    //         clientId: `${$config.commercetools.clientId}`,
    //         clientSecret: `${$config.commercetools.clientSecret}`,
    //       }
    //     : undefined,
    credentials: {
      clientId: `${$config.commercetools.clientId}`,
      clientSecret: `${$config.commercetools.clientSecret}`,
    },
  })

  const logService = process.static ? undefined : $logService

  const ct = Client.buildClient({
    axios,
    auth: {
      client: authClient,
    },
    cookies: {
      provider: $cookies, // cookie-universal-nuxt
      settings: {
        domain: cookieDomain,
        tldDomain: tldCookieDomain,
        fullDomain: fullCookieDomain,
      },
      cookieHeader,
    },
    // settings: {},
    logger, // nuxt-pino-log
    logService,
    hooks: {
      onSessionExpired: ({ isLoggedIn = false, isPunchout = false }) => {
        console.debug('^^^^^^^^  EXPIRED SESSION ^^^^')

        process.server && isLoggedIn && redirect('/account/sign-in')
        // if (process.client && isLoggedIn) {
        //   window.location.href = '/account/sign-in'
        // }

        if (process.client) {
          // When Logged in, but no Punchout: "Your session has expired. Please login again."   "Login Button with link to login page."
          // When Logged in and Punchout: "Your session has expired. Please login again via your punchout provider."
          // When not Logged in : "Your session has expired. Please refresh the page."    "Refresh Button to reload the page"

          let toastMessage
          const action = []

          if (isLoggedIn) {
            if (!isPunchout) {
              toastMessage = 'Your session has expired. Please login again.'
              action.push({
                text: 'Login',
                href: '/account/sign-in',
              })
            } else {
              toastMessage = 'Your session has expired. Please login again via your punchout provider.'
            }
          } else {
            toastMessage = 'Your session has expired. Please refresh the page.'
            action.push({
              text: 'Refresh',
              onClick: (_e: any, toastObject: any) => {
                toastObject.goAway(0)
                window.location.reload()
              },
            })
          }

          $toast.clear()
          $toast.info(toastMessage, {
            action,
          })
        }
      },
      onNetworkError: (error: any) => {
        console.debug('^^^^^^^^  NETWORK ERROR ^^^^')
        // console.log(error)
        if (process.client) {
          // const toastMessage = `Network Error: [${error.code}]:${error.message}. Please try again later ...`;
          const toastMessage = 'Network error. Please contact us with urgent requests.'
          const action = [
            {
              text: 'Contact Us',
              href: '/learn-and-support/support-information',
            },
          ]

          $toast.clear()
          $toast.show(toastMessage, {
            action,
          })
          console.error(error)
        }
      },
      onErrorInvalidToken: ({ isLoggedIn = false }) => {
        if (isLoggedIn) {
          // process.client ? (window.location.href = '/account/sign-in') : redirect('/account/sign-in')
        } else {
          // process.client ? (window.location.href = '/') : redirect('/')
          // process.client ? window.location.reload() : redirect('/')
        }
        console.log('Window Reload')
      },
    },
  })

  /*
  - This has no effect on the server (because it is called by app plugin), but it's necessary so that ct client is correctly initialized on the client.
  - B2dAllowedCountries are fetched and stored in store in with-data middleware. 
  - In the case of Page Generation with-data is executed only once during the page generation 
    and then it's not executed on the client therefore ct.client is not correctly initialized. 
  - ct.client is not serialized and needs to be init on both client and server.
  - Because of that we need to init it again in this plugin which is executed both on client and server. 
  - This is also why it needs fallback value.
  */
  ct.client.updateSettings({
    b2dCountries: useCMSGlobalSettingsStore().getAttributeByCountry('b2dAllowedCountries', 'US')?.value ?? [],
  })

  // Inject to context as $ct
  inject('ct', ct)
}
