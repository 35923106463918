















import { computed, defineComponent } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user'

export default defineComponent({
  setup() {
    const { selectedCountry: currentCountryCode } = storeToRefs(useUserStore())

    const mappedCurrentCountryCode = computed(() => {
      if (currentCountryCode.value === 'US') return 'USA'
      return currentCountryCode.value
    })

    return { currentCountryCode, mappedCurrentCountryCode }
  },
})
