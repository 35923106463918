import { defineStore } from 'pinia'
import { Context } from '@nuxt/types'
import { useCountry } from '@/composables/country/useCountry'
import { parsePage } from '~/modules/contentstack/parser/pageParser'
import { Page } from '@/types/cms/page'
import { Page as CMSPage } from '~/modules/contentstack/types'

interface CMSPageStore {
  data: Page | null
  isLoading: boolean
}

export const useStore = defineStore({
  id: 'cms-page',
  state: (): CMSPageStore => ({
    data: null,
    isLoading: true,
  }),
  actions: {
    async fetchPageByDevKey(devkey: string, _ctx: Context = {} as Context) {
      const { getLocaleCode } = useCountry()

      this.setIsLoading(true)

      const res = await this.$nuxt.$cms.api.fetchPageByDevKey({
        devkey,
        locale: getLocaleCode(),
        fallbackLocale: true,
      })

      // Parse 0 from items array
      if (res.data.all_page?.items?.length) {
        this.$patch({
          data: parsePage(res.data.all_page.items[0] as CMSPage),
          isLoading: false,
        })
        this.setIsLoading(false)
      }
    },
    setIsLoading(value: boolean) {
      this.isLoading = value
    },
  },
})
