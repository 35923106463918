import { CustomAxiosRequestConfig, CustomAxiosResponse, CustomAxiosError } from '@cstweb/common'
import { Client } from '../client'
import { HTTP_STATUS, REQUEST_NUMBER_OF_RETRIES, REQUEST_RETRY_DELAY } from '../util/constants'

const delay = (ms: number) => {
  console.log(`CTC: REQUEST Retry delay: ${ms} miliseconds`)
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * NOTE: has to be registered before any error handling middleware (error or oauth middleware)
 * so that RetryMiddleware.onResponseError would be executed first.
 */
export default (client: Client) => {
  const axios = client.axios

  const shouldRetry = (error: CustomAxiosError) => {
    const statusCode = error.response?.status ?? -1
    return (
      statusCode === HTTP_STATUS.AXIOS_NETWORK_ERROR ||
      (HTTP_STATUS.INTERNAL_SERVER_ERROR <= statusCode && statusCode <= HTTP_STATUS.NETWORK_CONNECT_TIMEOUT_ERROR)
    )
  }

  return {
    onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        // already retrying or sync version request
        return config
      }

      config.numOfRetries = config.numOfRetries || 0
      return config
    },
    onResponse(response: CustomAxiosResponse) {
      return response
    },
    async onResponseError(error: CustomAxiosError): Promise<any> {
      const { config, response }: { config?: CustomAxiosRequestConfig; response?: CustomAxiosResponse } = error

      if (config && response && config.numOfRetries !== undefined) {
        if (config.numOfRetries < REQUEST_NUMBER_OF_RETRIES && shouldRetry(error)) {
          await delay(!config.numOfRetries ? REQUEST_RETRY_DELAY : config.numOfRetries * REQUEST_RETRY_DELAY)
          return axios({
            ...config,
            numOfRetries: config.numOfRetries + 1,
          })
        }
      }

      throw error
    },
  }
}
