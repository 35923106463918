import { Context } from '@nuxt/types'
import { useCheckoutStore } from '~/store'

export function checkoutRedirect(context: Context) {
  const checkoutStore = useCheckoutStore(context.$pinia)

  if (context.route.path !== '/checkout/review' && checkoutStore.totalLineItemQuantity < 1) {
    return context.redirect('/checkout/review')
  }
}
