

















import { defineComponent } from '@nuxtjs/composition-api'
import { ErrorsPropType } from '~/types/errors/errorsTypes'

export default defineComponent({
  name: 'Errors',
  props: {
    errors: {
      type: Array as () => ErrorsPropType,
      required: true,
    },
  },
})
