import { CustomAxiosRequestConfig, CustomAxiosResponse, CustomAxiosError } from '@cstweb/common'
import { Client } from '../client'
import { hasRefreshToken, setAuthHeaders, hasCustomer, hasPunchoutCustomer, hasValidToken } from '../util'

import {
  URL_CARTS,
  URL_ACTIVE_CART,
  URL_ORDERS,
  URL_QUOTES,
  URL_CUSTOMERS,
  URL_CUSTOMERS_ME,
  URL_GROUP_NOT_CUSTOMER_ENTITY,
  HTTP_STATUS,
  AUTH_STORE_FIELD,
} from '../util/constants'

export default (client: Client) => {
  const axios = client.axios
  const authClient = client.auth.client
  const authCookieStore = client.cookies.auth
  const customerCookieStore = client.cookies.customer
  const hooks = client.hooks

  return {
    onRequest(config: CustomAxiosRequestConfig) {
      return config
    },
    onResponse(response: CustomAxiosResponse) {
      return response
    },
    async onResponseError(error: CustomAxiosError): Promise<any> {
      const { config, response }: { config?: CustomAxiosRequestConfig; response?: CustomAxiosResponse } = error

      if (config && response && HTTP_STATUS.AXIOS_NETWORK_ERROR === response.status) {
        hooks.onNetworkError && hooks.onNetworkError(error)
        throw error
      }

      // Refresh cart/customer if HTTP Status 400/409
      if (
        config &&
        response &&
        (HTTP_STATUS.CONFLICT === response.status || HTTP_STATUS.BAD_REQUEST === response.status) &&
        config.syncVersion !== true
      ) {
        let refreshUrl = ''

        if (
          (config.url?.includes(URL_CARTS) &&
            !response.data?.includes('InvalidTypePTMScan') &&
            !response.data?.includes('discontinued')) ||
          config.url?.includes(URL_ORDERS) ||
          config.url?.includes(URL_QUOTES)
        ) {
          refreshUrl = URL_CARTS
        } else if (config.url?.startsWith(URL_CUSTOMERS) && !URL_GROUP_NOT_CUSTOMER_ENTITY.includes(config.url)) {
          refreshUrl = URL_CUSTOMERS_ME
        }

        if (refreshUrl) {
          await axios.get(refreshUrl)
          return axios({
            ...config,
            syncVersion: true,
          })
        }
      }

      // Refresh Token if HTTP Status 401/403
      if (
        config &&
        response &&
        (HTTP_STATUS.UNAUTHORIZED === response.status || HTTP_STATUS.FORBIDDEN === response.status)
      ) {
        if (
          hasRefreshToken(authCookieStore) ||
          (!hasPunchoutCustomer(customerCookieStore) && hasValidToken(authCookieStore, config.url || ''))
        ) {
          try {
            if (hasRefreshToken(authCookieStore)) {
              // in case we have a refresh token available, let's use it to get a new access token
              const refreshToken = authCookieStore.get(AUTH_STORE_FIELD.REFRESH_TOKEN)
              await authClient.fetchRefreshToken(refreshToken as string)
            } else if (
              hasCustomer(customerCookieStore) &&
              !hasPunchoutCustomer(customerCookieStore) &&
              !hasRefreshToken(authCookieStore)
            ) {
              console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@2')
              client.clearCookies()
              client.hooks.onSessionExpired && client.hooks.onSessionExpired({ isLoggedIn: true, isPunchout: false })
              throw error
            } else {
              await authClient.fetchClientCredentialsToken()
            }
            setAuthHeaders({ config, authCookieStore })
            return axios({
              ...config,
            })
          } catch (error) {
            console.debug('🚀 ~ ErrorMiddleware.onResponseError ~ HTTP_STATUS.UNAUTHORIZED/FORBIDDEN')
            // console.error(error)
            throw error
          }
        } else {
          const isLoggedIn = hasCustomer(customerCookieStore)
          const isPunchout = hasPunchoutCustomer(customerCookieStore)
          console.debug(`CTC: Cleaning Cookies...(all)[${response.status}]`)

          client.clearCookies()
          hooks.onSessionExpired && hooks.onSessionExpired({ isLoggedIn, isPunchout })
        }
      }

      // if HTTP Status 404 and carts endpoint
      if (config && response && HTTP_STATUS.NOT_FOUND === response.status && config.url?.includes(URL_CARTS)) {
        const isPunchout = hasPunchoutCustomer(customerCookieStore)
        if (isPunchout) {
          console.debug(`CTC: Cleaning Cookies...(all)[${response.status}]`)

          client.clearCookies()
          hooks.onSessionExpired && hooks.onSessionExpired({ isLoggedIn: true, isPunchout })
        } else {
          console.debug(`CTC: Cleaning Cookies...(cart)[${response.status}]`)

          client.clearCartCookies()

          if (!config.url?.includes(URL_ACTIVE_CART)) {
            return axios.get(URL_ACTIVE_CART)
          }
        }
      }

      throw error
    },
  }
}
