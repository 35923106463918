import https from 'https'
import { Context, Plugin } from '@nuxt/types'
import cloneDeep from 'lodash/fp/cloneDeep'

// Common
import { findTLD } from '@cstweb/common'
import { TLD } from '@/common/constants/utils'
import { attachAxiosResponseTimeLoggerInterceptor } from '@/common/utils/logging'

import {
  CloudflareGetVariantsResponse,
  ProductImage,
  ProductImageVariants,
  CloudflareVariants,
} from '~/types/cloudflare/productImageTypes'

function isChinaDomain(host: string) {
  return TLD.CN.toLowerCase() === findTLD(host)?.toLowerCase()
}

const createCloudflarePlugin = ({ req, $axios, $config, $img, $logger }: Context) => {
  const logger = $logger.child({ name: 'log:cloudflare' })

  const cloudflareAccountId = $config.cst.cloudflare.images.accountId
  const isPreviewEnv = $config.cst.isPreview as boolean
  let baseURL: string = $config.cst.cloudflare.images.cloudflareGetVariantsUrl
  let path

  if (process.client && isChinaDomain(location.origin)) {
    baseURL = $config.cst.cn.cloudflare.images.cloudflareGetVariantsUrl || baseURL
  }

  if (baseURL.startsWith('/')) {
    // proxy requests to our server middleware to pre sign image URLs
    if (process.server) {
      baseURL = `https://${$config.nuxtHost}:${$config.nuxtPort}${baseURL}` // loopback
    } else {
      baseURL = location.origin + baseURL
    }
  }

  if (process.server) {
    path = process.static ? '' : req.url
  }
  if (process.client) {
    path = location.pathname
  }

  logger.debug("Creating cloudflare plugin. BaseUrl: '%s'", baseURL)

  const axios = $axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  })

  attachAxiosResponseTimeLoggerInterceptor({ axios, path, logger })

  // Change URL of the images
  const parseCloudflareImagesURLs = (data: any) => {
    // Todo, make this change in STEP team API response
    if (data?.figures) {
      data.figures.forEach((figure: any) => {
        if (figure.variants && figure.variants.length > 0) {
          figure.variants = Array.isArray(figure.variants)
            ? figure.variants.map((figureVariant: string) => generateImageUrl(figureVariant))
            : figure.variants
        }
      })
    }

    return data
  }

  // Fetch images by ID
  const getCloudflareImagesByID = async (ID: string) => {
    try {
      // Fetch images
      const { data } = await axios.get(`/${ID}`)

      // Change URL
      parseCloudflareImagesURLs(data)

      return data
    } catch (e) {
      console.error(e)
    }
  }

  const parseImageData = (data: CloudflareGetVariantsResponse): ProductImage[] => {
    const parsedFigures = data.figures.map((image) => ({
      caption: image.caption,
      filename: image.filename,
      id: image.id ?? image.filename,
      isProtected: image.isProtected === 'true',
      damId: image['dam-media-id'],
      figureType: image['figure-type'],
      size: {
        height: image.imageheight,
        width: image.imagewidth,
      },
      variants: parseImageVariants(image.variants),
    }))

    if (isPreviewEnv) {
      return parsedFigures
    }

    return parsedFigures.filter((image) => !image.isProtected)
  }

  const initialVariants: ProductImageVariants = {
    PUBLIC: '',
    PUBLIC_MOD: '',
    THUMBNAIL: '',
    X1: '',
    X2: '',
    X4: '',
    X6: '',
  }

  const parseImageVariants = (variants: string[]): typeof CloudflareVariants => {
    return variants.reduce((variants: ProductImageVariants, imageVariant) => {
      let variant: string | undefined
      if (imageVariant.includes('?')) {
        // variant is pre signed
        variant = imageVariant.split('?')[0].split('/').at(-1)
      } else {
        // public variant
        variant = imageVariant.split('/').at(-1)
      }

      switch (variant) {
        case CloudflareVariants.PUBLIC:
          variants.PUBLIC = generateImageUrl(imageVariant)
          break
        case CloudflareVariants.PUBLIC_MOD:
          variants.PUBLIC_MOD = generateImageUrl(imageVariant)
          break
        case CloudflareVariants.THUMBNAIL:
          variants.THUMBNAIL = generateImageUrl(imageVariant)
          break
        case CloudflareVariants.X1:
          variants.X1 = generateImageUrl(imageVariant)
          break
        case CloudflareVariants.X2:
          variants.X2 = generateImageUrl(imageVariant)
          break
        case CloudflareVariants.X4:
          variants.X4 = generateImageUrl(imageVariant)
          break
        case CloudflareVariants.X6:
          variants.X6 = generateImageUrl(imageVariant)
          break
      }
      return variants
    }, cloneDeep(initialVariants))
  }

  const generateImageUrl = (imageVariant: string) => {
    if (imageVariant.includes(cloudflareAccountId)) {
      imageVariant = imageVariant.replace(`${cloudflareAccountId}/`, '')
    }

    return $img(imageVariant, null, { provider: 'mc' })
  }

  const fetchProductImagesByProductId = async (productId: string) => {
    try {
      const { data } = await axios.get(`/${productId}`)
      return parseImageData(data)
    } catch (error) {
      logger.error(error)
    }
  }

  return { getCloudflareImagesByID, fetchProductImagesByProductId }
}

export type CloudflarePlugin = ReturnType<typeof createCloudflarePlugin>

const plugin: Plugin = (ctx, inject) => {
  inject('cloudflare', createCloudflarePlugin(ctx))
}

export default plugin
