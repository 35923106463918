import https from 'https'
import { Context, Plugin } from '@nuxt/types'
import { detectServerBaseUrl, findTLD } from '@cstweb/common'
import { TLD } from '@/common/constants/utils'
import { attachAxiosResponseTimeLoggerInterceptor } from '@/common/utils/logging'

function createCstApiPlugin({ $axios, $config, $logger, req }: Context) {
  const logger = $logger.child({ name: 'log:product:client' })
  let baseURL = $config.cst.isPreview
    ? process.server
      ? $config.cst.cstApiGatewayUrlPrivate
      : $config.cst.cstApiGatewayUrlPrivateClient
    : $config.cst.apiGatewayUrl
  let host
  let path

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)
    path = process.static ? '' : req.url
  }

  if (process.client) {
    host = location.origin
    if (TLD.CN.toLowerCase() === findTLD(host)?.toLowerCase()) {
      baseURL = $config.cst.cn.apiGatewayUrl || baseURL
    }
    path = location.pathname
  }

  baseURL = `${baseURL}/api`

  logger.debug({ baseURL, tld: findTLD(host) }, `[product-client]: setup`)

  const httpsAgent = new https.Agent({
    rejectUnauthorized: false,
  })
  const headers = {
    'Content-Type': 'application/json',
  }

  const axios = $axios.create({
    baseURL,
    httpsAgent,
    headers,
  })

  attachAxiosResponseTimeLoggerInterceptor({ axios, path, logger })

  return {
    getPreviewFullProduct: async (productId: string) => {
      try {
        return await axios.get(`/products/${productId}/preview`)
      } catch (e) {
        logger.error(e)
        throw e
      }
    },
  }
}

const plugin: Plugin = (ctx, inject) => {
  inject('cstApi', createCstApiPlugin(ctx))
}

export type CstApiPlugin = ReturnType<typeof createCstApiPlugin>

export default plugin
