

































import { useRoute, computed, watch, defineComponent, useContext } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/fp/isEmpty'
import { detectServerBaseUrl } from '@cstweb/common'
import { firstCharUpperCase, decodeHTMLEntities } from '@/common/utils/characters'
import { routeMapping } from '@/common/mapping/mapping'
import { BreadcrumbsData } from '~/types/breadcrumbs'

export default defineComponent({
  props: {
    links: {
      type: Array as () => BreadcrumbsData,
      required: false,
      default: () => [],
    },
    includeHomeLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    routeMapPrefix: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup({ links, routeMapPrefix }) {
    // get route
    const route = useRoute()
    const context = useContext()

    let hostUrl

    if (process.server) {
      hostUrl = process.static ? '' : `${detectServerBaseUrl(context.req)}${route.value.fullPath}`
    }
    if (process.client) {
      hostUrl = window.location.toString()
    }

    // console.log(route.value)

    // hide breadcrumbs on these routes
    // const showBreadcrumbs = ref(true)
    // let routername = route.value.name !== null && route.value.name !== undefined ? route.value.name : ''

    watch(
      () => route.value.name,
      () => {
        // do something here to force update component
      }
    )

    // const exlude routes
    const excludeRoutes: String[] = ['cart', 'my', 'account', 'signalstar']

    // create array from fullPath and filter out empty items (home)
    const breadcrumbs = computed(() => {
      if (!isEmpty(links)) {
        return links
      }

      return route.value.fullPath
        .split('/')
        .map((el) => {
          // replace browse by Products
          if (el === 'browse') {
            el = 'Products'
          }

          // Remove Query params
          if (el.includes('?')) {
            el = el.split('?')[0]
          }

          // only return non-excluded routes
          if (!excludeRoutes.includes(el)) {
            // map el to a nicer name in mapping composable called routeMapping
            const label = stripUrlParameters(
              firstCharUpperCase(routeMapping[(routeMapPrefix + el) as keyof typeof routeMapping] ?? el)
            )
            return {
              label,
              url: getPath(label),
            }
          }
          return {
            label: '',
            url: '',
          }
        })
        .filter((el) => el.label !== '')
    })

    // Calculate the route to a nested page
    const getPath = (breadcrumb: string) => {
      const page = breadcrumb.toLowerCase()
      return route.value.fullPath.split(page)[0] + (page === 'products' ? 'browse' : page)
    }

    // strip URL parameters
    const stripUrlParameters = (url: string) => {
      return url.split('?')[0]
    }

    return {
      breadcrumbs,
      getPath,
      isEmpty,
      decodeHTMLEntities,
      hostUrl,
    }
  },
})
