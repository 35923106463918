import { Client } from '../client'
import * as auth from './auth'
import * as cart from './cart'
import * as customer from './customer'
import * as orders from './orders'
import * as products from './products'
import * as quotes from './quotes'
import * as punchout from './punchout'
import * as b2d from './b2d'
import * as signalstar from './signalstar'
import * as pdf from './pdf'

export const buildAPI = (client: Client) => {
  return {
    auth: auth.bindClient(client),
    cart: cart.bindClient(client),
    customer: customer.bindClient(client),
    orders: orders.bindClient(client),
    products: products.bindClient(client),
    quotes: quotes.bindClient(client),
    punchout: punchout.bindClient(client),
    b2d: b2d.bindClient(client),
    signalstar: signalstar.bindClient(client),
    pdf: pdf.bindClient(client),
  }
}
