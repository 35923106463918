import { useContext } from '@nuxtjs/composition-api'
import { detectServerBaseUrl, findTLD } from '@cstweb/common'

// Common
import { SEARCH_TYPES } from '@/common/static/search'
import { TLD } from '@/common/constants/utils'

// types
import { AxiosConfigInterface } from '@/types/api/axiosTypes'

export default () => {
  // variables
  const { $config, req } = useContext()

  /*
    Method to define the Axios headers for Elastic App Search

    RETURN: object
  */
  const getAxiosHeaders = () => {
    const searchKey = process.server
      ? process.env.ELASTICSEARCH_PUBLIC_SEARCHKEY
      : $config.elasticSearch.elasticAppSearchPublicSearchKey

    return {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + searchKey,
    }
  }

  /*
    Method to define the global Axios config

    RETURN: object
  */
  const getAxiosConfig = (data: any, url: string) => {
    return {
      method: 'POST',
      timeout: 10000,
      url,
      headers: getAxiosHeaders(),
      data,
    } as AxiosConfigInterface
  }

  /*
    Variable to figiure out if we need to use the middleware or point to the API directly
    If the request is done server side, we need to use the middleware
    process.server variables
    
    RETURNS: string
  */
  const getElasticBase = (type: string): string => {
    let base = $config.elasticSearch.elasticAppSearchSearchBase
    let host

    if (process.server) {
      host = process.static ? '' : detectServerBaseUrl(req)
    }
    if (process.client) {
      host = location.origin
      if (TLD.CN.toLowerCase() === findTLD(host)?.toLowerCase()) {
        base = $config.elasticSearch.cn.elasticAppSearchSearchBase || base
      }
    }

    // Append middle part
    base += '/api/as/v1/engines/'

    // variation per type
    // Products
    if (type === SEARCH_TYPES.PRODUCT) {
      base += process.server
        ? process.env.ELASTICSEARCH_ENGINE_NAME_PRODUCT
        : $config.elasticSearch.elasticAppSearchEngineNameProduct
    }
    // Resources
    else if (type === SEARCH_TYPES.RESOURCE) {
      base += process.server
        ? process.env.ELASTICSEARCH_ENGINE_NAME_RESOURCES
        : $config.elasticSearch.elasticAppSearchEngineNameResources
    }
    // Knowledge Base
    else if (type === SEARCH_TYPES.KNOWLEDGE_BASE) {
      base += process.server
        ? process.env.ELASTICSEARCH_ENGINE_NAME_KNOWLEDGE_BASE
        : $config.elasticSearch.elasticAppSearchEngineNameKnowledgeBase
    }

    return base
  }

  /*
    Get endpoint for the regular search requests.
    This is used in typeahead
    
    RETURNS: string
  */
  const getElasticSearchEndpoint = (type: string) => {
    return getElasticBase(type) + '/search'
  }

  /*
    Get endpoint for the multi_search requests.
    This is used on the PLP
    
    RETURNS: string
  */
  const getElasticMultiSearchEndpoint = (type: string) => {
    return getElasticBase(type) + '/multi_search'
  }

  /*
    Get endpoint for the query suggestions
    This is used in typeahead and the no results page
    
    RETURNS: string
  */
  const getElasticQuerySuggestionsEndpoint = (type: string) => {
    return getElasticBase(type) + '/query_suggestion'
  }

  return {
    getElasticQuerySuggestionsEndpoint,
    getAxiosHeaders,
    getElasticBase,
    getElasticSearchEndpoint,
    getElasticMultiSearchEndpoint,
    getAxiosConfig,
  }
}
