import { CustomAxiosRequestConfig, CustomAxiosResponse } from '@cstweb/common'
import { addProductExpandParams } from '../util'
import { URL_PRODUCTS } from '../util/constants'

export default () => {
  // FIXME: needs to be reviewed and refactored accordingly
  return {
    onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        return config
      }

      if (config.url?.includes(URL_PRODUCTS) && !config.url?.includes('cached')) {
        if (!config.syncVersion) {
          config.params = addProductExpandParams(config.params)
        }
      }
      return config
    },
    onResponse(response: CustomAxiosResponse) {
      return response
    },
  }
}
