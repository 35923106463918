import { computed, ComputedRef } from '@nuxtjs/composition-api'
import { Pinia } from 'pinia'
import { EvaluatedFeatures, FeatureDefinitions } from '~/features/features'
import { useFeatureStore } from '~/store/featureFlags'

type Options = {
  staleTime?: number // in seconds
  pinia?: Pinia
}

// TODO: Documentation + examples
export function useFeatureFlag<TFeatureKey extends keyof FeatureDefinitions>(
  featureKey: TFeatureKey,
  options?: Options
): ComputedRef<EvaluatedFeatures[TFeatureKey]['value']> {
  const featureStore = useFeatureStore(options?.pinia)

  if (process.client) {
    // If stale, revalidate on client side
    featureStore.fetchIfStale(featureKey, options?.staleTime)
  }

  return computed(() => {
    try {
      return featureStore.featureFlags![featureKey].value as EvaluatedFeatures[TFeatureKey]['value'] // Return stale data
    } catch (e) {
      if (process.static) {
        return false as EvaluatedFeatures[TFeatureKey]['value']
      } else {
        throw e
      }
    }
  })
}
