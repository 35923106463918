import { Context } from '@nuxt/types'

const SEMICOLON = ';'
// .. Fire redirect with correct redirect code
export default function ({ route, redirect }: Context) {
  let path = route.path
  if (path.includes(SEMICOLON)) {
    path = path.split(SEMICOLON)[0]
    redirect(301, path)
  }
}
