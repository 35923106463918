import { useContext, computed, ComputedRef } from '@nuxtjs/composition-api'
import { useProfileStore, useUserStore, useCheckoutStore } from '~/store'

export default function () {
  const { $ct: ct, $logService } = useContext()
  const userStore = useUserStore()
  const profileStore = useProfileStore()
  const checkoutStore = useCheckoutStore()

  const isPunchout: ComputedRef<boolean> = computed(() => profileStore.isPunchout)
  const welcomeMessage: ComputedRef<string> = computed(() => profileStore.punchoutWelcomeMsg)

  // ..
  const generatePunchoutBasket = () => {
    return ct.punchout.generatePunchoutCart()
  }

  const submitPunchoutBasket = (activeCartId: string) => {
    return ct.punchout.submitPunchout(activeCartId)
  }

  const submitPunchoutOrder = async () => {
    // adds cart type: punchout
    await generatePunchoutBasket()

    const logContext = {
      lgroup: 'punchout',
    }

    $logService.sendInfo({
      logContext,
      message: '[punchout_submit]: submit-basket-start',
    })

    // send basket to 3rd party punchout provider
    const response = await submitPunchoutBasket(checkoutStore.cartId)

    $logService.sendInfo({
      logContext,
      message: '[punchout_submit]: submit-basket-end',
    })

    const returnUrl = response?.data?.cart?.custom?.fields?.returnUrl || '/'
    // const returnUrl = '/debugging'

    $logService.sendInfo({ logContext, message: '[punchout_submit]: user-logout' })
    $logService.sendInfo({ logContext, returnUrl, message: '[punchout_submit]: user-redirect' })

    // .. on success, revoke token, clear cartStore, authStore & customerStore
    await userStore.logout()

    // .. on success redirect to 3rd party punchout provider (checkout) page
    window.location.href = returnUrl
  }

  return { isPunchout, welcomeMessage, submitPunchoutOrder }
}
