









































































































































import kebabCase from 'lodash/fp/kebabCase'
import { defineComponent, computed, reactive } from '@nuxtjs/composition-api'
import { useSidebarStore } from '@/store'
import { useCMSGlobalSettingsStore, useCMSNavigationStore } from '@/store/cms'
import { useMobile } from '@/composables/ui/useMobile'
import { DEVKEY } from '~/common/constants/cms'

export default defineComponent({
  setup() {
    const mobile = reactive(useMobile())
    const cmsNavigationStore = useCMSNavigationStore()
    const { getAttributeByCountry, setShowMainNav } = useCMSGlobalSettingsStore()
    const sidebarStore = useSidebarStore()
    const brandLogo = getAttributeByCountry('headerBrandLogo')
    const menu = cmsNavigationStore.byKey(DEVKEY.GLOBAL_HEADER)
    const sidebar = sidebarStore.sidebar

    let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {})
    const debounceTime: number = 300

    const hover = computed(() => {
      return sidebar.submenu
    })

    const onHover = (event: any, value?: string) => {
      switch (event.type) {
        case 'mouseover':
          if (!mobile.isTablet) {
            if (debounceTimeout) {
              clearTimeout(debounceTimeout)
            }
            debounceTimeout = setTimeout(() => {
              setShowMainNav(true)
            }, debounceTime)

            sidebarStore.toggleSubmenu(value)
          }
          break
        case 'mouseout':
          if (!mobile.isTablet) {
            if (debounceTimeout) clearTimeout(debounceTimeout)
            debounceTimeout = setTimeout(() => {
              setShowMainNav(false)
            }, debounceTime)

            sidebarStore.toggleSubmenu()
          }
          break
        case 'click':
          if (mobile.isTablet) {
            sidebarStore.toggleSubmenu(value)
          }
          break
      }
    }

    const genNavItemClass = (item: string) => {
      return `nav-item-${kebabCase(item)}`
    }

    return {
      brandLogo,
      menu,
      hover,
      onHover,
      kebabCase,
      genNavItemClass,
      mobile,
    }
  },
})
