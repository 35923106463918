






















































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

import { storeToRefs } from 'pinia'
import countries from '~/static/countries.json'
import IconFa6SolidXmark from '~icons/fa6-solid/xmark'

import { useUserStore } from '@/store/user'
import { useUserStatus } from '@/composables/user/useUser'
import { useCMSGlobalSettingsStore } from '@/store/cms'

export default defineComponent({
  components: { IconFa6SolidXmark },
  setup(_, { emit }) {
    const { selectedCountry: oldCountry } = storeToRefs(useUserStore())
    const { isLoggedIn, hasCart } = useUserStatus()
    const { getAttributeByCountry } = useCMSGlobalSettingsStore()
    const b2dAllowedCountries = getAttributeByCountry('b2dAllowedCountries')

    const countryCode = ref('')

    const onSelect = (country: string) => {
      countryCode.value = country
    }

    const onSubmit = () => {
      emit('country-change', { oldCountry: oldCountry.value, newCountry: countryCode.value })
    }

    const isB2DCountryChange = computed(
      () =>
        b2dAllowedCountries.value.includes(oldCountry.value.toLowerCase()) &&
        b2dAllowedCountries.value.includes(countryCode.value.toLowerCase())
    )

    return {
      onSelect,
      onSubmit,
      countryCode,
      countries,
      oldCountry,
      hasCart,
      isLoggedIn,
      isB2DCountryChange,
    }
  },
})
