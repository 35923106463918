import type {
  Navigation as CMSNavigation,
  NavigationItems as CMSNavigationItem,
  NavigationLink as CMSNavigationLink,
  Link as CMSLink,
  NavigationLinkUiIcon as CMSNavigationLinkUiIcon,
} from '~/modules/contentstack/types'

import type { Navigation, NavigationItem, NavigationLink, Link, LinkIcon } from '~/types/cms/navigation'

export const parseNavigation = (navigation: CMSNavigation): Navigation => {
  // console.dir(navigation)
  const uid = navigation.system?.uid ?? ''
  const title = navigation.title ?? ''
  const devkey = navigation.devkey ?? ''
  const link =
    (navigation.navigation_linkConnection?.edges &&
      navigation.navigation_linkConnection.edges[0]?.node &&
      parseNavigationLink(navigation.navigation_linkConnection.edges[0]?.node)) ??
    undefined
  const links: NavigationLink[] =
    navigation.linksConnection?.edges?.reduce((links: NavigationLink[], link) => {
      if (link?.node) {
        links.push(parseNavigationLink(link.node))
      }
      return links
    }, []) ?? []

  const items =
    navigation.items?.reduce((items: NavigationItem[], item) => {
      if (item) {
        items.push(parseNavigationItem(item))
      }
      return items
    }, []) ?? []

  return {
    uid,
    title,
    devkey,
    link,
    links,
    items,
  }
}

export const parseNavigationItem = (navigationItem: CMSNavigationItem): NavigationItem => {
  // console.dir(navigationItem)
  const title = navigationItem.title ?? ''

  const link =
    (navigationItem.navigation_linkConnection?.edges &&
      navigationItem.navigation_linkConnection.edges[0]?.node &&
      parseNavigationLink(navigationItem.navigation_linkConnection.edges[0]?.node)) ??
    undefined

  const links: NavigationLink[] =
    navigationItem.linksConnection?.edges?.reduce((links: NavigationLink[], link) => {
      if (link?.node) {
        links.push(parseNavigationLink(link.node))
      }
      return links
    }, []) ?? []

  const items =
    navigationItem.items?.reduce((items: NavigationItem[], item) => {
      if (item) {
        items.push(parseNavigationItem(item as CMSNavigationItem))
      }
      return items
    }, []) ?? []

  return {
    title,
    link,
    links,
    items,
  }
}

export const parseNavigationLink = (navigationLink: CMSNavigationLink): NavigationLink => {
  // console.dir(navigationLink)
  const title = navigationLink.title ?? ''
  const label = navigationLink.label ?? ''
  const link = (navigationLink.link && parseLink(navigationLink.link)) ?? undefined
  const isExternalLink = navigationLink.is_external_link ?? false
  const openInNewWindow = navigationLink.open_in_new_window ?? false
  const icon = (navigationLink.ui_icon && parseLinkIcon(navigationLink.ui_icon)) ?? undefined
  const devkey = navigationLink.devkey ?? ''
  return {
    title,
    label,
    link,
    isExternalLink,
    openInNewWindow,
    icon,
    devkey,
  }
}

export const parseLink = (link: CMSLink): Link => {
  // console.dir(link)
  const title = link.title ?? ''

  const url = link.href ?? ''
  return {
    title,
    url,
  }
}
export const parseLinkIcon = (linkIcon: CMSNavigationLinkUiIcon): LinkIcon => {
  // console.dir(linkIcon)
  const className = linkIcon.icon_class_name ?? ''
  const image = ''
  return {
    className,
    image,
  }
}
