import { render, staticRenderFns } from "./MenuHolder.vue?vue&type=template&id=d79da8bc&scoped=true&"
import script from "./MenuHolder.vue?vue&type=script&lang=ts&"
export * from "./MenuHolder.vue?vue&type=script&lang=ts&"
import style0 from "./MenuHolder.vue?vue&type=style&index=0&id=d79da8bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d79da8bc",
  null
  
)

export default component.exports