import { Client } from '../client'
import { URL_ORDERS } from '../util/constants'

export const bindClient = (client: Client) => {
  const placeOrder = () => {
    return client.axios.post(URL_ORDERS)
  }
  return {
    placeOrder,
  }
}
