import { CustomAxiosError, CustomAxiosInstance, CustomAxiosRequestConfig, CustomAxiosResponse } from '@cstweb/common'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { Client } from '../client'
import { OAuthClient } from '../client/oauth-client'

/**
 * Request deduplication middleware
 * Prevents having multiple identical requests on the fly at the same time
 */
export const DedupeMiddleware = ({ axios }: Client | OAuthClient) => {
  const pendingRequests = new Map<string, AxiosPromise<any>>()

  function generateKey(config: AxiosRequestConfig) {
    return config.method! + ' ' + axios.getUri(config) + ' ' + JSON.stringify(config.data)
  }

  return {
    onRequest(config: CustomAxiosRequestConfig): CustomAxiosRequestConfig {
      config.customAdapter = (config, originalAdapter) => {
        const requestKey = generateKey(config)
        const pendingRequest = pendingRequests.get(requestKey)
        if (pendingRequest) {
          // request is pending, don't send another one, reuse the previous one
          return pendingRequest
        }

        const request = originalAdapter(config)
        pendingRequests.set(requestKey, request)

        return request
      }

      return config
    },
    onResponse(response: CustomAxiosResponse) {
      pendingRequests.delete(generateKey(response.config))
      return response
    },
    onResponseError(error: CustomAxiosError) {
      pendingRequests.delete(generateKey(error.config))
      throw error
    },
  }
}

/**
 * Has to be called before dedupe middleware is used
 * @param axios Custom axios instance
 */
export function prepareDedupe(axios: CustomAxiosInstance) {
  const originalAdapter = axios.defaults.adapter!
  axios.defaults.adapter = (config: CustomAxiosRequestConfig) => {
    if (config.customAdapter) {
      return config.customAdapter(config, originalAdapter)
    }
    return originalAdapter(config)
  }
}
