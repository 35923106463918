






















import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api'
import Breadcrumbs from '../components/global/navigation/Breadcrumbs.vue'

export default defineComponent({
  components: {
    Breadcrumbs,
  },
  props: ['error'],
  setup() {
    const breadcrumbLinks = [
      {
        label: 'Page Not Found',
        url: '/',
      },
    ]
    const searchPrase = ref('')
    const router = useRouter()

    const onSearch = () => {
      if (searchPrase.value) {
        router.push(`/browse?tab=resources&search=${searchPrase.value}`)
      }
    }

    return { breadcrumbLinks, searchPrase, onSearch }
  },
})
