import { defineStore } from 'pinia'
import { EvaluatedFeatures, FeatureDefinitions, FEATURES } from '~/features/features'
import { FetchFeatureResult } from '~/types/featureFlags/featureFlagsPlugin'
import { useCountry } from '~/composables/country/useCountry'
const DEFAULT_STALE_TIME = 60 * 5 // 5 minutes

/**
 * We need to prioritize usage of evaluateFeature over batchEvaluateFeature
 *
 * TPS for batchEvaluate 50
 * TPS for evaluateFeature 1000
 */

type FeatureStatus = 'fetching' | 'error' | 'success'
type EvaluatedFeaturesWithTimestamp = {
  [K in keyof EvaluatedFeatures]: EvaluatedFeatures[K] & {
    fetchedAt: number
    value: string | boolean | null
    status: FeatureStatus
  }
}
export const useFeatureStore = defineStore({
  id: 'features',
  state: () => ({
    featureFlags: null as EvaluatedFeaturesWithTimestamp | null,
  }),

  actions: {
    async fetchAll() {
      try {
        if (!this.featureFlags) {
          // .. If CN do not call evidently, but use hardcoded value for flags
          if (useCountry().isCN.value) {
            const dummyCnFlags = Object.values(FEATURES).map((f) => ({
              name: f.name,
              evaluateBasedOn: f.evaluateBasedOn,
              value: f.cnFlag,
            }))
            return this._patch(dummyCnFlags)
          }
          // .. Fetch all using batchEvaluate when store is empty
          const evaluatedFeatures = await this._fetchAllFlags()
          return this._patch(evaluatedFeatures)
        } else {
          // .. When features already present, revalidate all of them using evaluateFeature
          const featureRequests = Object.keys(FEATURES).map((key) => this.fetchIfStale(key as keyof FeatureDefinitions))

          await Promise.all(featureRequests)
          return this.featureFlags
        }
      } catch (error) {
        console.error(error)

        // .. TODO: Remove after we fix batchEvaluate Quote limit
        return this._fakePatch()
      }
    },

    _fetchAllFlags() {
      return this.$nuxt.$featureFlags.fetchMany(
        Object.values(FEATURES).map((f) => ({ name: f.name, evaluateBasedOn: f.evaluateBasedOn }))
      )
    },

    _patch(data: FetchFeatureResult[]) {
      const newFeatureFlags = {} as EvaluatedFeaturesWithTimestamp
      for (const feature of data) {
        const featureKey = Object.entries(FEATURES).find(([_key, value]) => value.name === feature.name)![0]

        // @ts-ignore
        newFeatureFlags[featureKey] = {
          ...feature,
          fetchedAt: Date.now(),
          status: 'success',
        }
      }

      this.featureFlags = newFeatureFlags

      return this.featureFlags
    },

    // .. Will patch store with fake values to prevent errors
    _fakePatch() {
      const newFeatureFlags = {} as EvaluatedFeaturesWithTimestamp

      Object.entries(FEATURES).forEach(([_key, featureContent]) => {
        const lastFeatureValue = this.featureFlags?.[_key as keyof FeatureDefinitions]?.value
        const featureValue: boolean | string = lastFeatureValue ?? false

        // @ts-ignore
        newFeatureFlags[_key] = {
          ...featureContent,
          value: featureValue,
          status: 'error',
          // .. Set time for -4 minutes, so we can retry in 1 minute
          fetchedAt: new Date(new Date().getTime() - 4 * 60000).valueOf(),
        }
      })

      this.featureFlags = newFeatureFlags

      return this.featureFlags
    },

    async fetch<TFeatureKey extends keyof FeatureDefinitions>(
      featureKey: TFeatureKey
    ): Promise<EvaluatedFeatures[TFeatureKey]['value']> {
      const feature = FEATURES[featureKey]
      const featureName = feature.name
      const evaluationOption = feature.evaluateBasedOn

      if (!this.featureFlags) {
        console.warn('You are trying to write to featureFlags before they are fetched.')
        this.featureFlags = {} as EvaluatedFeaturesWithTimestamp
      }

      // Initialize fetching status, prevent duplicate requests
      this.featureFlags[featureKey] = {
        ...this.featureFlags[featureKey],
        status: 'fetching',
      }

      try {
        let revalidatedFeature: FetchFeatureResult = this.featureFlags[featureKey]

        // .. If NOT CN call evidently and use evidently response.
        // For CN use hardcoded value for flag (which was patched to store previously with hardcoded value)
        if (!useCountry().isCN.value) {
          revalidatedFeature = await this.$nuxt.$featureFlags.fetch(featureName, evaluationOption)
        }
        // @ts-ignore
        this.featureFlags[featureKey] = {
          ...revalidatedFeature,
          fetchedAt: Date.now(),
          status: 'success',
        }
      } catch (error) {
        console.error(error)

        const lastFeatureValue = this.featureFlags?.[featureKey]?.value
        const featureValue: boolean | string = lastFeatureValue ?? false

        // .. Fake patch of single feature
        // @ts-ignore
        this.featureFlags[featureKey] = {
          name: featureName,
          evaluateBasedOn: evaluationOption,
          value: featureValue,
          status: 'error',
          // .. Set time for -4 minutes, so we can retry in 1 minute
          fetchedAt: new Date(new Date().getTime() - 4 * 60000).valueOf(),
        }
      }

      return this.featureFlags[featureKey].value as EvaluatedFeatures[TFeatureKey]['value']
    },

    /**
     * @param staleTime Stale time in seconds
     */
    async fetchIfStale<TFeatureKey extends keyof FeatureDefinitions>(
      featureKey: TFeatureKey,
      staleTime: number = DEFAULT_STALE_TIME
    ): Promise<EvaluatedFeatures[TFeatureKey]['value']> {
      const feature = this.featureFlags?.[featureKey]

      // prevent duplicate requests by checking also for status
      if (!feature || (feature.status !== 'fetching' && Date.now() - feature.fetchedAt > staleTime * 1000)) {
        await this.fetch(featureKey)
      }

      return this.featureFlags![featureKey].value as EvaluatedFeatures[TFeatureKey]['value']
    },
  },
})
