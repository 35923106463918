// Composition
import { computed } from '@nuxtjs/composition-api'

import isEmpty from 'lodash/fp/isEmpty'

// Common
// import { slugify } from '@/common/utils/characters'

// Store
import { useCheckoutStore } from '@/store/checkout'
import { CommerceToolsClient } from '~/types/nuxtInjectTypes'

// Types
import { BasketItemInterface } from '~/types/checkout/checkoutTypes'

const fetchingData = computed<boolean>(() => {
  return useCheckoutStore().isLoading
})

// basket items
const basketItems = computed(() => {
  return useCheckoutStore().basketItems
})

// edit basket item
const changeBasketItemQuantity = (id: string, qty: number): void => {
  useCheckoutStore().changeBasketItemQuantity(id, qty)
}

// check if this id is on the basket
const isInBasket = (id: string) => {
  // check in basketItems.itemsData and loop over objects
  if (Object.keys(basketItems.value).length > 0) {
    for (const key in basketItems.value.itemsData) {
      if (basketItems.value.itemsData[key].productKey === id) {
        return true
      }
    }
  }

  return false
}

// see if basket is empty or not
const isBasketEmpty = computed<boolean>(() => {
  return isEmpty(basketItems.value.itemsData)
})

// show basket or not
const showBasket = computed<boolean>((): any => {
  return useCheckoutStore().showBasket
})

// change showBasketState
const toggleShowBasket = (state: boolean) => {
  useCheckoutStore().toggleShowBasket(state)
}

const generateBasketitemID = (id: string = '', sku: string = '', experimentId?: string) => {
  return [id, sku, experimentId].filter((x) => !!x).join('-')
}

const getListPrice = (basketItem: any) => {
  return basketItem.price.value.centAmount
}
const getNetPrice = (basketItem: any) => {
  if (basketItem._isFreeLine) {
    return 0
  } else if (basketItem.discountedPrice) {
    return basketItem.discountedPrice.value.centAmount
  } else return basketItem.totalPrice.centAmount / basketItem.quantity
}
const getTotalComputedPrice = (basketItem: any) => {
  if (basketItem._isFreeLine) {
    return 0
  }
  return basketItem.totalPrice.centAmount
}

// compute total promo price
const getTotalDiscountPrice = (cart: any) => {
  // It's possible this will be undefined for old orders, that were created before BE started adding this custom field
  return cart.custom.fields.totalDiscountValue?.centAmount
}

const getSubtotal = (cart: any) => {
  // It's possible this will be undefined for old orders, that were created before BE started adding this custom field
  return cart.custom.fields.subTotal?.centAmount
}

const getShippingPrice = (cart: any) => {
  return cart.shippingInfo && cart.shippingInfo.discountedPrice
    ? cart.shippingInfo.discountedPrice.value.centAmount
    : cart.shippingInfo && cart.shippingInfo.price.centAmount
}

const setCartToRegular = async (ct: CommerceToolsClient) => {
  const { data } = await ct.cart.setCartToRegular()
  useCheckoutStore()._updateStore(data)
}

const resetBasket = () => useCheckoutStore().$reset()

// const hasProductType = (productType: string, item: BasketItemInterface): boolean => {
//   if (!item.variant.attributes) {
//     return false
//   }

//   const productTypeObj = item.variant.attributes?.find((el) => el.name === 'productType')?.value
//   return productTypeObj !== undefined && Object.entries(productTypeObj)[0][1] === productType
// }

const hasSignalStarExperimentID = (item: BasketItemInterface): boolean => {
  if (!item.custom?.fields?.experimentId) {
    return false
  }

  return item.custom?.fields?.experimentId.length > 0
}

// TODO: Refactoring needed: Please move all needed module functions above into this function
export default function () {
  const checkoutStore = useCheckoutStore()

  const basketTotalString = computed(() => {
    const totalBasketItems = checkoutStore.totalLineItemQuantity
    if (!totalBasketItems) {
      return '(0)'
    }
    if (totalBasketItems > 99) {
      return '(99+)'
    }
    return `(${totalBasketItems})`
  })

  return {
    basketTotalString,
  }
}

export {
  fetchingData,
  basketItems,
  isBasketEmpty,
  showBasket,
  toggleShowBasket,
  changeBasketItemQuantity,
  useCheckoutStore,
  generateBasketitemID,
  getListPrice,
  getNetPrice,
  getTotalComputedPrice,
  getTotalDiscountPrice,
  getSubtotal,
  getShippingPrice,
  isInBasket,
  setCartToRegular,
  resetBasket,
  hasSignalStarExperimentID,
}
