import { defineStore } from 'pinia'
import { useCountry } from '@/composables/country/useCountry'

import { DynadminCMSStore, RegionalSettingInterface } from '@/types/cms/admin'

export const useStore = defineStore({
  id: 'cms-dynadmin',
  state: (): DynadminCMSStore => ({
    regionalSettings: {},
  }),
  actions: {
    async fetchRegionalSettings(devkeys: string[]) {
      try {
        const { getLocaleCode, selectedCountry } = useCountry()

        const { data } = await this.$nuxt.$cms.api.fetchRegionalSettings({
          countryCode: selectedCountry.value,
          devkeys,
          locale: getLocaleCode(),
          fallbackLocale: true,
        })

        // If no items, return
        if (!data?.all_regional_settings?.items) {
          // TODO: Send error to logservice about missing regional settings for this country
          return
        }

        // Group everything on devkey
        const regionalSettingsArray = devkeys.map((key) => {
          return data?.all_regional_settings?.items?.filter((item: any) => item.devkey === key)
        }) as RegionalSettingInterface[][]

        // Set in store
        regionalSettingsArray.forEach((regionalSettingsArrayItem) => {
          // check if we have a regional setting for this devkey
          // Find the default_setting false first, when not present take the default version
          const regionalSetting =
            regionalSettingsArrayItem?.find((item) => !item?.is_default_setting) ??
            (regionalSettingsArrayItem?.find((item) => item?.is_default_setting) as RegionalSettingInterface)

          if (regionalSetting) {
            this.$patch({
              regionalSettings: { [regionalSetting.devkey as keyof typeof regionalSetting]: regionalSetting },
            })
          }
        })
      } catch (error: any) {
        console.error(error)
      }
    },
  },
  getters: {
    getRegionalSetting: (state) => {
      return (devkey: string) => state.regionalSettings[devkey as keyof typeof state.regionalSettings]
    },
  },
})
