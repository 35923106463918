















import { useRouter, defineComponent, computed } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { toggleShowBasket } from '@/composables/checkout/useBasket'
import { formatPrice } from '@/common/utils/numbers'
import { useCheckoutStore } from '@/store/checkout'
import { useCountry } from '@/composables/country/useCountry'
import { useI18n } from '~/composables/i18n'

export default defineComponent({
  props: {
    forwardLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { basketItems } = storeToRefs(useCheckoutStore())
    const router = useRouter()
    const { isJapan, isB2DCountry } = useCountry()
    const { t } = useI18n()

    const nextStep = () => {
      router.push(props.forwardLink)
      toggleShowBasket(false)
    }

    const price = computed(() =>
      formatPrice(
        basketItems.value.data?.subtotalPrice ?? 0,
        basketItems.value.data.currencyCode ?? 'USD',
        basketItems.value.data.fractionDigits ?? 2
      )
    )

    return {
      basketItems,
      nextStep,
      isJapan,
      price,
      isB2DCountry,
      t,
    }
  },
})
