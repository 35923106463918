import { URL_PDF } from '../util/constants'

import { BufferKitInterface, SignalstarAssignedAntibody } from '../types/signalstar'
import { Client } from '../client'

type PdfResponse = { url: string }
export type QuoteRequestType = 'quoteUS' | 'quoteJP' | 'quoteB2D' | 'quoteEMEA'

export type QuotePdfPayload = {
  countryCode: string
  language: string
  requestItemType: QuoteRequestType
  data: any
}

export type SignalstarPdfPayload = {
  countryCode: string
  language: string
  requestItemType: 'panelBuilder'
  data: {
    imagingRounds: SignalstarAssignedAntibody[]
    bufferKit: BufferKitInterface
    experimentId: string
    generate?: boolean
  }
}

export const bindClient = (client: Client) => {
  const createSignalstarPdf = (payload: SignalstarPdfPayload) => {
    return client.axios.post<PdfResponse>(URL_PDF, payload)
  }

  const createQuotePdf = (payload: QuotePdfPayload) => {
    return client.axios.post<PdfResponse>(URL_PDF, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  return { createSignalstarPdf, createQuotePdf }
}
