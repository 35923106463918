import { CustomAxiosRequestConfig, CustomAxiosResponse } from '@cstweb/common'
import { Client } from '../client'
import {
  CUSTOMER_STORE_FIELD,
  URL_CUSTOMERS,
  URL_LOGIN,
  URL_SIGNUP,
  URL_REQUEST_PASSWORD_RESET,
  URL_PASSWORD_RESET,
  URL_PASSWORD_CHANGE,
  URL_VERIFY_EMAIL_TOKEN,
  URL_RESEND_VERIFICATION_EMAIL,
  URL_PAYMENTS,
} from '../util/constants'
import { hasCustomer } from '../util'
import { CookieComplexStore } from '../util/cookie'

interface ActionInterface {
  action: string
}

interface RequestDataInterface {
  actions: ActionInterface[]
}

function getRequestPayload(data: RequestDataInterface, customerCookieStore: CookieComplexStore) {
  if (hasCustomer(customerCookieStore)) {
    return {
      version: customerCookieStore.get(CUSTOMER_STORE_FIELD.CUSTOMER_VERSION) || 0,
      actions: data.actions,
    }
  }

  return data
}

export default (client: Client) => {
  const customerCookieStore = client.cookies.customer
  const hybridCountryCodeCookieStore = client.cookies.hybridCountryCode

  return {
    onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        return config
      }

      if (
        config.url?.startsWith(URL_CUSTOMERS) &&
        ![
          URL_LOGIN,
          URL_SIGNUP,
          URL_REQUEST_PASSWORD_RESET,
          URL_PASSWORD_RESET,
          URL_PASSWORD_CHANGE,
          URL_VERIFY_EMAIL_TOKEN,
          URL_RESEND_VERIFICATION_EMAIL,
          URL_PAYMENTS,
        ].includes(config.url)
      ) {
        const jsonData = config.data && JSON.parse(config.data)

        if (jsonData) {
          const newData = getRequestPayload(jsonData, customerCookieStore)
          config.data = JSON.stringify(newData)
        }
      }
      return config
    },
    onResponse(response: CustomAxiosResponse) {
      const { config, data } = response

      if (
        config.url &&
        [URL_VERIFY_EMAIL_TOKEN].includes(config.url) &&
        customerCookieStore.get(CUSTOMER_STORE_FIELD.CUSTOMER_ID)
      ) {
        // only for case when user email is verified and user is logged in
        customerCookieStore.set(CUSTOMER_STORE_FIELD.CUSTOMER_VERIFIED, true)
        customerCookieStore.persist()
      }

      if (
        config.url?.startsWith(URL_CUSTOMERS) &&
        ![URL_VERIFY_EMAIL_TOKEN, URL_PASSWORD_RESET, URL_PASSWORD_CHANGE, URL_PAYMENTS].includes(config.url)
      ) {
        let jsonData = data && typeof data === 'string' ? JSON.parse(data) : data
        jsonData = jsonData || {}

        const isPunchout = customerCookieStore.get(CUSTOMER_STORE_FIELD.IS_PUNCHOUT) ?? false

        let customerId, version, isEmailVerified, registrationOrigin
        if (jsonData.customer) {
          customerId = jsonData.customer.id
          version = jsonData.customer.version
          isEmailVerified = jsonData.customer.isEmailVerified
          registrationOrigin = jsonData.customer.custom?.fields?.registrationOrigin
        } else if (jsonData.id) {
          customerId = jsonData.id
          version = jsonData.version
          isEmailVerified = jsonData.isEmailVerified
          registrationOrigin = jsonData.custom?.fields?.registrationOrigin
        }

        if (customerId) {
          customerCookieStore.set(CUSTOMER_STORE_FIELD.CUSTOMER_ID, customerId)
          customerCookieStore.set(CUSTOMER_STORE_FIELD.CUSTOMER_VERSION, version)
          customerCookieStore.set(CUSTOMER_STORE_FIELD.CUSTOMER_VERIFIED, isEmailVerified)

          if (registrationOrigin) {
            hybridCountryCodeCookieStore.set(getCountryCodeCode(registrationOrigin))
            hybridCountryCodeCookieStore.persist()
          }
        }

        if (isPunchout) {
          customerCookieStore.set(CUSTOMER_STORE_FIELD.IS_PUNCHOUT, isPunchout)
        }

        customerCookieStore.persist()
      }

      return response
    },
  }
}

const getCountryCodeCode = (countryCode: string = 'US') => {
  const cc = countryCode !== 'US' ? countryCode : 'USA' // FIXME: remove 'USA' after ATG Sunset
  return cc.toUpperCase()
}
