import { computed, useContext } from '@nuxtjs/composition-api'
import { Context } from '@nuxt/types'
import { useCountry } from '~/composables/country/useCountry'
import { useCMSGlobalSettingsStore } from '@/store/cms'
import { useI18n } from '~/composables/i18n'
import { MappedSKU, PriceBrickSkuData } from '~/types/product/productTypes'
import { PRODUCT_CATEGORIES, PRODUCT_TYPES } from '~/common/utils/product/constants'
import { filterSkuWithNonZeroPrice } from '~/common/utils/product/product'
import { decodeHTMLEntities } from '~/common/utils/characters'
import { useProfileStore } from '~/store'

const ADD_TO_BASKET_BUTTON = {
  BUY_ON_BIOKE: 'BUY ON BIOKÉ',
  BUY_ON_OZYME: 'BUY ON OZYME',
  BUY_ON_NEB_X: 'BUY ON NEB %X',
  REQUEST_A_PRICE_QUOTE: 'REQUEST A PRICE QUOTE',
} as const

export function usePriceBrick(context?: Context) {
  const { isUS, isJapan, isAT, isB2DCountry, isCN, isDE, isGB, isBENELUX, isAU, isCA, isNZ, isFR } = useCountry()
  const { getAttributeByCountry } = useCMSGlobalSettingsStore()
  const ctx = (context || useContext()) as Context
  const { t } = useI18n(ctx)

  const profileStore = useProfileStore()
  const isPunchout = computed(() => profileStore.isPunchout)

  const showPrices = (productType?: string) => {
    return (
      isPunchout.value ||
      isUS.value ||
      isJapan.value ||
      (isCN.value && productType !== PRODUCT_TYPES.PTM_SCAN) ||
      isAT.value ||
      isGB.value ||
      isDE.value
    )
  }

  const showOldPriceBrick = computed(() => {
    // China is only exception: it uses old price brick and it shows prices
    return isCN.value || (!showPrices() && !isB2DCountry.value)
  })

  const mapSkuData = (skuData: MappedSKU[]): PriceBrickSkuData[] => {
    skuData = showPrices() && !ctx?.$config.cst.isPreview ? filterSkuWithNonZeroPrice(skuData) : skuData
    const qty = skuData.length === 1 ? 1 : 0

    // Sort primarily by price, secondary by amount (in case products have no price)
    return skuData
      .map((sku) => {
        const { centAmount, currencyCode, fractionDigits } = sku.priceForCurrentCountry || {}
        return { ...sku, centAmount, currencyCode, fractionDigits, qty }
      })
      .sort((a, b) => (a.attributes.amount ?? Number.MAX_VALUE) - (b.attributes.amount ?? Number.MAX_VALUE))
      .sort((a, b) => (a.centAmount ?? Number.MAX_VALUE) - (b.centAmount ?? Number.MAX_VALUE))
  }

  const isForbiddenToSell = (productId: string) => {
    if (isUS.value) {
      return getAttributeByCountry('excludedProducts', 'US').value.includes(productId)
    }
    if (isJapan.value) {
      return getAttributeByCountry('excludedProducts', 'JP').value.includes(productId)
    }
    return false
  }

  const addToBasketButtonText = computed(() => {
    if (isCN.value) {
      return ADD_TO_BASKET_BUTTON.REQUEST_A_PRICE_QUOTE
    }
    if (showPrices() || isB2DCountry.value) {
      return t('addToBasket')
    }
    if (isBENELUX.value) {
      return ADD_TO_BASKET_BUTTON.BUY_ON_BIOKE
    }
    if (isFR.value) {
      return ADD_TO_BASKET_BUTTON.BUY_ON_OZYME
    }
    if (isCA.value) {
      return ADD_TO_BASKET_BUTTON.BUY_ON_NEB_X.replace('%X', 'CANADA')
    }
    if (isAU.value) {
      return ADD_TO_BASKET_BUTTON.BUY_ON_NEB_X.replace('%X', 'AUSTRALIA')
    }
    if (isNZ.value) {
      return ADD_TO_BASKET_BUTTON.BUY_ON_NEB_X.replace('%X', 'NEW ZEALAND')
    }
    return ADD_TO_BASKET_BUTTON.REQUEST_A_PRICE_QUOTE
  })

  const showInventory = computed(() => isJapan.value || isCN.value)

  const getCustomFormulationPath = (productId: string, productName: string) =>
    `/services/carrier-free-and-customized-formulations/custom-formulations-request?product_number=${productId}&product_name=${decodeHTMLEntities(
      productName
    )}`

  const getCustomFormulationLabel = (category: string | undefined, isPdpPriceBrick: boolean = false) => {
    if (
      isPdpPriceBrick &&
      (category === PRODUCT_CATEGORIES.PRIMARY_ANTIBODIES || category === PRODUCT_CATEGORIES.ANTIBODY_CONJUGATES)
    ) {
      return t('bulkAndCustomFormulation')
    }
    if (category === PRODUCT_CATEGORIES.PRIMARY_ANTIBODIES) {
      return t('carrierFreeAndCustomFormulation')
    }
    return t('customFormulation')
  }

  return {
    isForbiddenToSell,
    mapSkuData,
    addToBasketButtonText,
    showPrices,
    showOldPriceBrick,
    showInventory,
    getCustomFormulationPath,
    getCustomFormulationLabel,
    isPunchout,
  }
}
