import { useProfileStore } from '~/store/profile'
import { useConfigStore } from '~/store/config'
import { getCustomDimensions } from '~/common/utils/analytics'

export default function (ctx: any) {
  // Init Global GTM 4 Container
  ctx.$gtm.init(ctx.$config.gtm.global.id)

  // Manually insert page_view event
  ctx.app.router.afterEach((to: any) => {
    const profileStore = useProfileStore()
    const configStore = useConfigStore()

    ctx.$gtm.push({
      event: 'page_view',
      user_id: profileStore.userInfo.id,
      page_title: document.title,
      page_url: to.path,
      ...getCustomDimensions(profileStore, configStore).ga4,
    })
  })
}
