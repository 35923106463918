import { PRODUCT_CATEGORIES } from '../utils/product/constants'

// Settings

/**
 * https://www.elastic.co/guide/en/app-search/current/precision-tuning.html#precision-tuning-values
 *
 * @abstract Exact spelling matches only
 * @description Every tokenized term must appear in the same field. NOTE: This is not an exact match against the field value (e.g. a search for "PART-123" can return documents that contain both tokenized "PART" and "123" terms such as "PART-123-456"). To exactly match a field value, use Search API filters.
 */
export const SEARCH_PRODUCT_PRECISION = 11

// CONSTANT
export const SEARCH_TYPES = {
  RESOURCE: 'resources',
  PRODUCT: 'product',
  CATEGORIES: 'categories',
  HISTORY: 'history',
  SUGGESTIONS: 'suggestions',
  KNOWLEDGE_BASE: 'knowledge-base',
} as const

export const SEARCH_RESULT_FIELDS_PRODUCT = {
  productnumber: { raw: {} },
  clickurl: { raw: {} },
  categories: { raw: {} },
  name: { raw: {}, snippet: {} },
  namenotags: { raw: {}, snippet: {} },
  applications: { raw: {} },
  numberofchiclets: { raw: {} },
  speciesreactivity: { raw: {} },
  speciesreactivitycodes: { raw: {} },
  chiclets: { raw: {} },
  status: { raw: {} },
  releaseddate: { raw: {} },
  numberofapplications: { raw: {} },
  totalcitationcount: { raw: {} },
  producttype: { raw: {} },
}

export const SEARCH_RESULT_FIELDS_RESOURCES = {
  url: { raw: {} },
  title: { raw: {}, snippet: {} },
  meta_description: { raw: {} },
  url_path: { raw: {} },
  headings: { raw: {} },
}

export const NESTED_FACET_NAMES = {
  other: {
    key: 'other',
    value: 'Other',
  },
}

export const STOP_WORDS = ['antibody', 'anti-', 'anti', 'mab', 'and', '&', 'in']

export const RESULTS_PER_PAGE_NUMBERS = [30, 60, 100, 200]

const searchRedirects = {
  simpleWestern: {
    applications: 'Simple Western™',
  },
  signalStar: {
    categories: [
      PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS,
      PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS_HTML,
    ],
  },
}
export const SEARCH_REDIRECT_PHRASES = {
  'protein simple': searchRedirects.simpleWestern,
  proteinsimple: searchRedirects.simpleWestern,
  'simple protein': searchRedirects.simpleWestern,
  simpleprotein: searchRedirects.simpleWestern,
  simplewestern: searchRedirects.simpleWestern,
  westernsimple: searchRedirects.simpleWestern,
  'simple western': searchRedirects.simpleWestern,
  'western simple': searchRedirects.simpleWestern,
  'signal star': searchRedirects.signalStar,
}

export const BLOG_URL = 'blog.cellsignal'
export const CAREERS_URL = 'careers.cellsignal'
