import https from 'https'
import { Context, Plugin } from '@nuxt/types'
import { attachAxiosResponseTimeLoggerInterceptor } from '@/common/utils/logging'

const createPlugin = ({ req, $axios, $config, $logger }: Context) => {
  const logger = $logger.child({ name: 'log:analytics' })

  let baseURL: string = '/api/user'
  let path

  if (process.server) {
    baseURL = `https://${$config.nuxtHost}:${$config.nuxtPort}${baseURL}` // loopback
    path = process.static ? '' : req.url
  }

  if (process.client) {
    path = location.pathname
  }

  const axios = $axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  })

  attachAxiosResponseTimeLoggerInterceptor({ axios, path, logger })

  const fetchSettings = async () => {
    try {
      const { data } = await axios.get(`/settings`)
      return data
    } catch (error) {
      logger.error(error)
    }
  }

  return {
    fetchSettings,
  }
}
const plugin: Plugin = (ctx, inject) => {
  inject('user', createPlugin(ctx))
}

export type UserAPIClientPlugin = ReturnType<typeof createPlugin>

export default plugin
