import { lightFormat } from 'date-fns'
import { convertStringToEuDate } from './dates'
import { APPLICATIONS } from '~/common/utils/product/constants'
import {
  generateBasketitemID,
  getListPrice,
  getNetPrice,
  getShippingPrice,
  getSubtotal,
  getTotalComputedPrice,
  getTotalDiscountPrice,
} from '~/composables/checkout/useBasket'
import { BasketItemsDataInterface } from '~/types/checkout/checkoutTypes'
import { OrderInterface, PaymentDetailInterface } from '~/types/profile/orderHistoryTypes'
import { MyQuoteInterface, ProfileAddressesInterface, ProfileUserInfoInterface } from '~/types/profile/profileTypes'
import { QuoteInterface } from '~/types/quotes/quotesTypes'
import { useCountry } from '~/composables/country/useCountry'

// Line Item Statuses coming from CT
const CT_LINE_ITEM_STATUS = {
  AWAITING_SHIPPING: 'Awaiting Shipping',
  PICKED: 'Picked',
  FULFILLED: 'Fulfilled',
  CANCELLED: 'Cancelled',
  ADVISED: 'Advised',
  SHIPPED: 'Shipped',
  CLOSED: 'Closed',
  RETURNED: 'Returned',
} as const

// Order Statuses coming from CT
const CT_ORDER_STATUS = {
  OPEN: 'Open',
  CONFIRMED: 'Confirmed',
  CANCELLED: 'Cancelled',
} as const

// Line Item Statuses rendering on UI
const LINE_ITEM_STATUS = {
  AWAITING_SHIPPING: 'Awaiting Shipping',
  CANCELLED: 'Cancelled',
  AWAITING_RETURN: 'Awaiting Return',
  SHIPPED: 'Shipped',
  RECEIVED: 'Received',
  RETURNED: 'Returned',
} as const

// Order Statuses rendering on UI
const ORDER_STATUS = {
  RECEIVED: 'Received',
  COMPLETE: 'Complete',
  CANCELLED: 'Cancelled',
  SHIPPED_X_OF_Y: 'Shipped %X of %Y',
  PROCESSING: 'Processing',
} as const

const parseElasticTextObject = (data: any) => {
  return data && JSON.parse(data)
}

/* Request Parsers/Builders */
const userInfoPayloadFactory = (storeUserInfo: ProfileUserInfoInterface, newUserInfo: ProfileUserInfoInterface) => {
  const actionsWithParams: any = [] // Please check libs/commercetools/client/src/api/customer for type

  // Compare FirstName
  if (storeUserInfo.firstName !== newUserInfo.firstName) {
    actionsWithParams.push({
      action: 'setFirstName',
      firstName: newUserInfo.firstName,
    })
  }

  // Compare LastName
  if (storeUserInfo.lastName !== newUserInfo.lastName) {
    actionsWithParams.push({
      action: 'setLastName',
      lastName: newUserInfo.lastName,
    })
  }

  // Compare Company
  if (storeUserInfo.company !== newUserInfo.company) {
    actionsWithParams.push({
      action: 'setCompanyName',
      companyName: newUserInfo.company,
    })
  }

  // Compare Mobile
  if (storeUserInfo.mobile !== newUserInfo.mobile) {
    actionsWithParams.push({
      action: 'setCustomField',
      name: 'mobilePhone',
      value: newUserInfo.mobile,
    })
  }

  // Compare Mobile ext
  if (storeUserInfo.mobileExt !== newUserInfo.mobileExt) {
    actionsWithParams.push({
      action: 'setCustomField',
      name: 'mobilePhoneExtension',
      value: newUserInfo.mobileExt,
    })
  }

  // Compare VAT ID
  // if (storeUserInfo.vatId !== newUserInfo.vatId) {
  //   actionsWithParams.push({
  //     action: 'setVatId',
  //     vatId: newUserInfo.vatId,
  //   })
  // }

  return actionsWithParams
}

/* Response Parsers */
const parsePaymentInfoResponse = (paymentInfoObj: any): PaymentDetailInterface => {
  return {
    amountPlanned: paymentInfoObj?.amountPlanned,
    customFields: paymentInfoObj?.custom?.fields,
    paymentMethodInfo: paymentInfoObj?.paymentMethodInfo,
  } as PaymentDetailInterface
}

// ..
const getSizeFromVariantAttriibutes = (attributes: any[]): string => {
  let size: string = ''

  for (let i = 0; i < attributes.length; i++) {
    if (attributes[i].name === 'amount') {
      size = attributes[i].value + ' '
    }
    if (attributes[i].name === 'uom') {
      size += attributes[i].value
    }
  }

  return size
}

// ..
const generateAppliedPromoNamesForItem = (
  basketDiscounts: any,
  itemDiscountsPerQty: any[],
  selectedLocale: string
): string[] => {
  const appliedPromoNames = new Set<string>()

  if (itemDiscountsPerQty) {
    itemDiscountsPerQty.forEach((item: any) => {
      if (item.discountedPrice.includedDiscounts) {
        item.discountedPrice.includedDiscounts.forEach((discount: any) => {
          if (discount.discount.obj && discount.discountedAmount.centAmount > 0) {
            if (basketDiscounts[discount.discount.id]) {
              basketDiscounts[discount.discount.id].forEach((value: any) => {
                // Add promotion code + promotion name
                appliedPromoNames.add(value.toUpperCase() + ': ' + discount.discount.obj.name[selectedLocale])
              })
            } else {
              // Institutional discounts don't have promotion code, add only their name
              appliedPromoNames.add(discount.discount.obj.name[selectedLocale])
            }
          }
        })
      }
    })
  }
  return [...appliedPromoNames]
}

// ..
const generateEstShipDate = (estShipDate: string, currentDate: Date) => {
  return estShipDate === lightFormat(currentDate, 'yyyy-MM-dd')
    ? 'Today'
    : useCountry().isEU.value
    ? convertStringToEuDate(estShipDate)
    : estShipDate
}

// ..
const mapLineItemStatus = (name: string) => {
  // mapLineItemStatus and mapOrderStatus methods are done based on the documentation in the WEBP-4167 description
  switch (name) {
    case CT_LINE_ITEM_STATUS.AWAITING_SHIPPING:
    case CT_LINE_ITEM_STATUS.PICKED:
    case CT_LINE_ITEM_STATUS.FULFILLED: {
      return LINE_ITEM_STATUS.AWAITING_SHIPPING
    }
    case CT_LINE_ITEM_STATUS.CANCELLED: {
      return LINE_ITEM_STATUS.CANCELLED
    }
    case CT_LINE_ITEM_STATUS.ADVISED: {
      return LINE_ITEM_STATUS.AWAITING_RETURN
    }
    case CT_LINE_ITEM_STATUS.RETURNED: {
      return LINE_ITEM_STATUS.RETURNED
    }
    case CT_LINE_ITEM_STATUS.SHIPPED:
    case CT_LINE_ITEM_STATUS.CLOSED: {
      return LINE_ITEM_STATUS.SHIPPED
    }
    default: {
      return LINE_ITEM_STATUS.RECEIVED
    }
  }
}

// ..
const mapOrderStatus = (orderStatus: string, lineItems: BasketItemsDataInterface) => {
  const lineItemStatuses = Object.values(lineItems).map((item) => item.lineItemStatus)
  const numberOfLines = Object.values(lineItems).length.toString()

  // lineStatus is null when the status is not in the en-US locale, mostly the status is 'Initial' in this case
  if (lineItemStatuses.every((status) => status === null) && orderStatus === CT_ORDER_STATUS.OPEN) {
    return ORDER_STATUS.RECEIVED
  } else if (
    lineItemStatuses.every(
      (status) => status === CT_LINE_ITEM_STATUS.SHIPPED || status === CT_LINE_ITEM_STATUS.ADVISED
    ) &&
    orderStatus === CT_ORDER_STATUS.CONFIRMED
  ) {
    return ORDER_STATUS.COMPLETE
  } else if (
    lineItemStatuses.every((status) => status === CT_LINE_ITEM_STATUS.CANCELLED) &&
    orderStatus === CT_ORDER_STATUS.CANCELLED
  ) {
    return ORDER_STATUS.CANCELLED
  } else if (lineItemStatuses.includes(LINE_ITEM_STATUS.SHIPPED) && orderStatus === CT_ORDER_STATUS.CONFIRMED) {
    const numOfShippedItems = lineItemStatuses
      .filter((status) => status === CT_LINE_ITEM_STATUS.SHIPPED)
      .length.toString()
    return ORDER_STATUS.SHIPPED_X_OF_Y.replace('%X', numOfShippedItems).replace('%Y', numberOfLines)
  } else {
    return ORDER_STATUS.PROCESSING
  }
}

// ..
const parseLineItemToBasketItem = (
  lineItem: any,
  selectedLocale: string,
  basketDiscounts: any,
  isImportedOrder?: boolean
) => {
  return {
    [generateBasketitemID(lineItem.productKey, lineItem.variant.sku, lineItem?.custom?.fields?.experimentId)]: {
      id: lineItem?.id,
      addedAt: lineItem?.addedAt,
      productId: lineItem?.productId,
      productKey: lineItem?.productKey,
      quantity: lineItem?.quantity,
      productSlug: lineItem?.productSlug ? lineItem?.productSlug[selectedLocale] ?? '' : '',
      name: lineItem?.name[selectedLocale] ?? lineItem?.name['en-US'],

      hasPromotion: !!lineItem?.discountedPrice,
      _isFreeLine: lineItem?._isFreeLine ?? false,
      _isGiftLine: lineItem?.lineItemMode === 'GiftLineItem',

      price: lineItem?.price,
      listPrice: isImportedOrder ? lineItem?.custom?.fields?.legacyListPrice?.centAmount : getListPrice(lineItem),
      netPrice: isImportedOrder ? lineItem?.custom?.fields?.legacyNetPrice?.centAmount : getNetPrice(lineItem),
      totalPriceComputed: isImportedOrder
        ? lineItem?.custom?.fields?.legacyTotalPrice?.centAmount
        : getTotalComputedPrice(lineItem),
      totalPrice: lineItem?.totalPrice,
      taxedPrice: lineItem?.taxedPrice,

      discountedPricePerQuantity: lineItem?.discountedPricePerQuantity,
      discountedPrice: lineItem?.discountedPrice,
      custom: lineItem?.custom,

      variant: { ...lineItem?.variant, size: getSizeFromVariantAttriibutes(lineItem?.variant?.attributes ?? []) },
      productType: lineItem?.productType,

      appliedPromoNames: isImportedOrder
        ? lineItem?.custom?.fields?.promotionsApplied
        : generateAppliedPromoNamesForItem(basketDiscounts, lineItem?.discountedPricePerQuantity, selectedLocale),

      lineItemStatus: lineItem?.state?.at(0)?.state.obj?.name['en-US']
        ? mapLineItemStatus(lineItem?.state?.at(0)?.state.obj?.name['en-US'])
        : null,
      trackingNumber: lineItem.custom?.fields?.trackingNumber,
      estShipDate: lineItem?.custom?.fields.estShipDate ?? '',
    },
  }
}

// ..
const parseOrderResponse = (order: any, selectedLocale: string): OrderInterface => {
  const isImportedOrder =
    order?.state.obj?.key === 'imported' || (order?.state.obj?.key === 'dropped' && order.orderState !== 'Open')
  const lineItems = order.lineItems.reduce(
    (prevLineItem: any, currLineItem: any) => ({
      ...prevLineItem,
      ...parseLineItemToBasketItem(currLineItem, selectedLocale, order._discountCodes ?? {}, isImportedOrder),
    }),
    {}
  ) as BasketItemsDataInterface

  return {
    id: order?.id,
    orderNumber: order?.custom.fields.webOrderNumber,
    createdAt: order?.custom.fields.createdAt,
    lastModifiedAt: order?.lastModifiedAt,
    deliveredAt: order.custom.fields.deliveryDate,
    orderStatus: mapOrderStatus(order?.orderState, lineItems),
    specialInsructionNotes: order?.custom?.fields?.specialHandling,
    shippingAddress: order?.shippingAddress
      ? parseAddressesResponse([order?.shippingAddress])?.[0]
      : order?.shippingAddress,
    billingAddress: order?.billingAddress
      ? parseAddressesResponse([order?.billingAddress])?.[0]
      : order?.billingAddress,
    shippingInfo: order?.shippingInfo,
    totalPrice: order?.custom.fields.total,
    shippingPrice: getShippingPrice(order),
    currencyCode: order?.totalPrice.currencyCode,
    fractionDigits: order?.totalPrice.fractionDigits,
    tax: order?.custom.fields.tax,
    vatId: order?.custom.fields.vatId ?? '',
    totalPromo: getTotalDiscountPrice(order), // For Summary Block
    subtotalPrice: getSubtotal(order), // For Summary Block
    lineItems,
    payments: order?.paymentInfo?.payments
      ?.filter((payment: any) => payment.obj)
      ?.map((payment: any) => parsePaymentInfoResponse(payment.obj)),
    discountCodes: order._discountCodes ?? {},
  }
}

// ..
const parseQuoteResponse = (quote: QuoteInterface): MyQuoteInterface => {
  return {
    ...quote,
    createdAt: quote.cart.lastModifiedAt!,
  }
}

// ..
const parseAddressesResponse = (response: any[]): ProfileAddressesInterface[] => {
  return response.map((address) => ({
    id: address?.id,
    state: address?.state,
    city: address?.city,
    country: address?.country,
    streetName: address?.streetName,
    additionalStreetInfo: address?.additionalStreetInfo,
    postalCode: address?.postalCode,
    company: address?.company,
    phone: address?.phone,
    phoneNumberExt: address?.custom?.fields?.phoneNumberExt ?? '',
    taxExempt: address?.custom?.fields?.taxExempt ?? false,
    collectAccountNumber: address?.custom?.fields?.fedExCollectNumber,
    vatId: address?.custom?.fields?.vatId,

    typeId: address?.custom?.type?.id,

    // These values are not shown to users, but are used when debugging export/import from ATG to CT:
    legacyId: address?.custom?.fields?.legacyId,
    nickname: address?.custom?.fields?.nickname,

    // These values are not shown to users, but are used when dropping the Orders to EBS.
    // Unmapped addresses (without an EBS value) are sent to EBS Corrections Queue.
    // Mapped addresses (with an EBS value) go directly to EBS system for processing.
    // These values displayed on test pages to help with Back End debugging.
    ebsBillToId: address?.custom?.fields?.ebsBillToId,
    ebsOrgAccountNumber: address?.custom?.fields?.ebsOrgAccountNumber,
    ebsShipToId: address?.custom?.fields?.ebsShipToId,
    ebsSoldToId: address?.custom?.fields?.ebsSoldToId,
    billToDiscountCode: address?.custom?.fields?.billToDiscountCode,
    shipToDiscountCode: address?.custom?.fields?.shipToDiscountCode,

    // These values aren't show to users, but determine the cost of Shipping user will pay:
    freightTerm: address?.custom?.fields?.freightTerm,
  }))
}

const getDisplayNameForApplicationCode = (applicationCode: string | undefined) => {
  if (applicationCode === undefined) return ''

  // find the application code in the APPLICATIONS object and return the codeForDisplay
  return Object.values(APPLICATIONS).find((app) => app.group === applicationCode)?.codeForDisplay ?? applicationCode
}

export {
  parseElasticTextObject,
  parsePaymentInfoResponse,
  generateAppliedPromoNamesForItem,
  parseLineItemToBasketItem,
  parseAddressesResponse,
  userInfoPayloadFactory,
  parseOrderResponse,
  parseQuoteResponse,
  generateEstShipDate,
  getDisplayNameForApplicationCode,
}
