import { URL_PUNCHOUT_PLACE_ORDER } from '../util/constants'
import { Client } from '../client'
import { setCartCheckoutType } from './cart'

export const bindClient = (client: Client) => {
  const submitPunchout = (activeCartId: string) => {
    return client.axios.post(URL_PUNCHOUT_PLACE_ORDER, { id: activeCartId })
  }

  // ..
  const generatePunchoutCart = () => setCartCheckoutType(client)('punchout')

  return {
    submitPunchout,
    generatePunchoutCart,
  }
}
