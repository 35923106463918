import type { Context } from '@nuxt/types'
import { constants } from '@cstweb/commercetools-client'
import { useProfileStore } from '@/store/profile'

interface AuthMiddlewareConfig {
  goto?: string
}

export default function (config?: AuthMiddlewareConfig) {
  return (ctx: Context) => {
    const customerIdCookie = ctx.$cookies.get(constants.COOKIES.CUSTOMER)?.id
    const profileStore = useProfileStore(ctx.$pinia)
    const isLoggedIn = profileStore.userInfo.id !== '' || customerIdCookie !== undefined
    if (!isLoggedIn) {
      return ctx.redirect(`/account/sign-in`, { goto: config?.goto ?? ctx.route.path })
    }
  }
}
