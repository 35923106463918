



































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import IconFa6SolidXmark from '~icons/fa6-solid/xmark'

// Composable
import useEcommerceAnalytics from '@/composables/analytics/useEcommerceAnalytics'
import { hasSignalStarExperimentID } from '@/composables/checkout/useBasket'
import { useI18n } from '~/composables/i18n'

// Store
import { useCheckoutStore } from '@/store/checkout'

export default defineComponent({
  components: {
    IconFa6SolidXmark,
  },
  props: {
    type: {
      type: String as () => 'sidebar' | 'body',
      require: false,
      default: () => 'body',
    },
  },
  setup(props) {
    const errors = ref([{ msg: '' }])
    const { trackRemoveFromCart } = useEcommerceAnalytics()
    const { deleteBasketItem, toggleChangeItemPopup } = useCheckoutStore()
    const checkoutStore = useCheckoutStore()
    const { t } = useI18n()

    const isSignalStarProductType = computed(() => {
      return checkoutStore.itemToChange && hasSignalStarExperimentID(checkoutStore.itemToChange)
    })

    const deleteItem = async () => {
      if (!checkoutStore.itemToChange) {
        return
      }

      errors.value = [{ msg: '' }]
      try {
        // send a remove from basket event to GTM
        // This needs to be before deleting the item from the store, otherwise, we can't find all product details
        trackRemoveFromCart('basket_sidebar', { itemId: checkoutStore.itemToChange.id })

        await deleteBasketItem(checkoutStore.itemToChange)
      } catch (error: any) {
        ;(error.response?.data?.errors || []).forEach((e: any) => errors.value.push({ msg: e.message }))
      } finally {
        toggleChangeItemPopup(false)
      }
    }

    const handleAction = () => {
      // handle delete first
      if (checkoutStore.changeItemModalMode === 'delete') {
        deleteItem()
      } else if (checkoutStore.changeItemModalMode === 'quantity') {
        // the modalMode was quantity and the user clicked no, no dot change

        // find delete item in the basket and reset the old quantiy
        const quantity = checkoutStore.itemToChange?.newQuantity
        const deleteItemId = checkoutStore.itemToChange?.id
        if (quantity && deleteItemId) {
          checkoutStore.changeBasketItemQuantity(deleteItemId, quantity)
          toggleChangeItemPopup(false)
        }
      }
    }

    // If sidebar is already opened, don't show another popup
    const showPopup = computed(
      () =>
        checkoutStore.showItemChangePopup &&
        ((props.type === 'sidebar' && checkoutStore.showBasket) ||
          (props.type !== 'sidebar' && !checkoutStore.showBasket))
    )

    return {
      errors,
      toggleChangeItemPopup,
      handleAction,
      showPopup,
      isSignalStarProductType,
      t,
    }
  },
})
