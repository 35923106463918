import isEmpty from 'lodash/fp/isEmpty'
import cloneDeep from 'lodash/fp/cloneDeep'
import groupBy from 'lodash/fp/groupBy'
import { compareDesc } from 'date-fns'

// TODO: try to rework this a bit
function parseDiscountCodesToMap(data: any) {
  const obj = new Map()
  if (data.discountCodes) {
    data.discountCodes.forEach((code: any) => {
      if (code.discountCode.obj) {
        const discCode = code.discountCode.obj.code
        if (code.discountCode.obj.cartDiscounts) {
          code.discountCode.obj.cartDiscounts.forEach((cartDiscount: any) => {
            if (obj.has(cartDiscount.id)) {
              obj.get(cartDiscount.id).push(discCode)
            } else obj.set(cartDiscount.id, [discCode])
          })
        }
      }
    })
  }
  // Since Map can't be stringified, convert to an Object
  return Object.fromEntries(obj)
}

/* 
check if there is an active promotion on cart
  if any of the discountCode state MatchesCart, there is an active promo
  if totalDiscountValue has non zero amount, there is an active promo
  if there is discountedPrice in shippingInfo, there is an active promo
*/
function isPromoActive(data: any) {
  if (data.discountCodes?.some((code: any) => code.state.toLowerCase() === 'matchescart')) return true
  if (data.custom?.fields.totalDiscountValue?.centAmount > 0) return true
  if (data.shippingInfo?.discountedPrice) return true
  return false
}

export function editResponseForPromotions(data: any) {
  const newData = cloneDeep(data)
  newData._rawData = data
  newData._discountCodes = parseDiscountCodesToMap(data)
  newData._isPromoActive = isPromoActive(data)
  const newLineItems: any[] = []

  if (newData.lineItems) {
    newData.lineItems.forEach((lineItem: any) => {
      let newFreeLineItem: any = null

      // if true there is a discount for a line item
      if (lineItem.discountedPrice) {
        let freeItemQty = 0
        // condition for BXGX promotion type
        if (lineItem.lineItemMode.toLowerCase() === 'standard' && lineItem.discountedPricePerQuantity) {
          lineItem.discountedPricePerQuantity.forEach((item: any, i: number) => {
            // if true this is a free line item
            if (item.discountedPrice.value.centAmount === 0) {
              freeItemQty = item.quantity
              newFreeLineItem = { ...lineItem }

              // make changes for non free line item
              lineItem._isFreeLine = false
              lineItem.quantity -= freeItemQty
              lineItem.discountedPricePerQuantity.splice(i, 1)

              // make changes for free line item
              newFreeLineItem._isFreeLine = true
              newFreeLineItem.quantity = freeItemQty
              newFreeLineItem.totalPrice = item.discountedPrice.value
              newFreeLineItem.productKey += 'freeLineItem'
              newFreeLineItem.discountedPricePerQuantity = [item]
            }
          })
        }
        // condition for BXGY promotion type
        else if (lineItem.lineItemMode.toLowerCase() === 'giftlineitem') {
          lineItem._isFreeLine = true
        }
        // to prevent possible quantity being < 0 because of substracting qty for free line item
        if (lineItem.quantity > 0) {
          newLineItems.push(lineItem)
        }
        if (lineItem._isReturnedItem) {
          newLineItems.push(lineItem)
        }
        if (newFreeLineItem) {
          newLineItems.push(newFreeLineItem)
        }
      } else {
        newLineItems.push(lineItem)
      }
    })
  }
  newData.lineItems = newLineItems
  return newData
}

/**
 * Returns modified data from response when there are returnInfo records, else returns non modified data
 * @param data either cart or order response
 * @returns parsed response with return items implementation
 */
export function editResponseForReturnedItems(data: any) {
  if (isEmpty(data.returnInfo)) {
    return data
  } else {
    // create a new date to sort by later
    data.returnInfo.map((info: any) => (info.items[0].formattedLastModifiedAt = new Date(info.items[0].lastModifiedAt)))

    // flat map returnInfo and group it by lineItemId
    const groupedReturnedInfoByLineItemId = groupBy(
      (item: any) => item.lineItemId,
      data.returnInfo.flatMap((info: any) => info.items)
    )

    // iterate through grouped values and sort them desc by formattedLastModifiedAt and get only first value (most latest date)
    const parsedReturnInfo = Object.values(groupedReturnedInfoByLineItemId).map(
      (item: any) => item.sort((a: any, b: any) => compareDesc(a.formattedLastModifiedAt, b.formattedLastModifiedAt))[0]
    )

    // iterate through parsed returnInfo and create a new line Item with the changes needed
    parsedReturnInfo.forEach((returnItem: any) => {
      data.lineItems.push(createLineItemFromReturnItem(data.lineItems, returnItem))
    })
    return data
  }
}

/**
 * Returns cloned lineItem based on the id from the returnItem.lineItemId with changes to match return item requirements
 * @param lineItems array of lineItems objects
 * @param returnItem returnItem object
 * @returns newLineItem with changes for return item
 */
function createLineItemFromReturnItem(lineItems: any, returnItem: any) {
  const newLineItem = cloneDeep(lineItems.find((item: any) => item.id === returnItem.lineItemId))

  newLineItem._isReturnedItem = true
  newLineItem.quantity = returnItem.quantity * -1
  newLineItem.totalPrice.centAmount *= -1
  if (newLineItem.custom.fields.legacyTotalPrice) {
    newLineItem.custom.fields.legacyTotalPrice.centAmount *= -1
  }
  newLineItem.productKey += 'returnedLineItem'
  newLineItem.state[0].state.obj.name['en-US'] = returnItem.shipmentState
  newLineItem.custom.fields.trackingNumber = ''
  return newLineItem
}
