import { defineStore } from 'pinia'

export interface SidebarStore {
  sidebar: {
    show: boolean
    submenu?: string
  }
}

export const useSidebarStore = defineStore({
  id: 'mobile',
  state: (): SidebarStore => ({
    sidebar: {
      show: false,
      submenu: undefined,
    },
  }),
  actions: {
    toggleSidebar() {
      this.$patch({
        sidebar: {
          show: !this.sidebar.show,
          submenu: !this.sidebar.show ? undefined : this.sidebar.submenu,
        },
      })
    },
    toggleSubmenu(value?: string) {
      if (this.sidebar.submenu !== value) {
        // console.log(value)
        this.$patch({
          sidebar: {
            submenu: value,
          },
        })
      }
    },
  },
})
