





























































import { defineComponent, computed } from '@nuxtjs/composition-api'

// Components
import QuantityInput from '../quick-add/quantityInput.vue'

// Stores
import { useCheckoutStore } from '@/store/checkout'

// Composables
import { basketItems, hasSignalStarExperimentID } from '@/composables/checkout/useBasket'
import { useI18n } from '~/composables/i18n'
import { useCountry } from '@/composables/country/useCountry'

// Common
import { formatPrice } from '@/common/utils/numbers'
import { decodeHTMLEntities } from '@/common/utils/characters'
import { sortLineDataItems } from '~/common/utils/sorting'

export default defineComponent({
  components: { QuantityInput },
  emits: ['onDeleteItem', 'onInputValueChanged'],
  setup() {
    const { changeBasketItemQuantity } = useCheckoutStore()
    const { isB2DCountry } = useCountry()
    const { t } = useI18n()

    // .. New items should be on top
    const sortedLineItems = computed(() => sortLineDataItems(basketItems.value.itemsData, true))

    return {
      sortedLineItems,
      basketItems,
      decodeHTMLEntities,
      changeBasketItemQuantity,
      isB2DCountry,
      formatPrice,
      t,
      hasSignalStarExperimentID,
    }
  },
})
