






import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'InfoBubble',
  props: {
    colorTheme: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const colors = computed(() => {
      // default color-theme = 'blue'
      let color = 'tw-border-blue-800 tw-bg-blue-200 tw-text-blue-800'

      if (!props.colorTheme) {
        return color
      }
      if (props.colorTheme === 'green') {
        color = 'tw-border-green-800 tw-bg-lime-200 tw-text-lime-800'
      }

      if (props.colorTheme === 'red') {
        color = 'tw-border-red-800 tw-bg-red-200 tw-text-red-800'
      }

      return color
    })

    return {
      colors,
    }
  },
})
