




























// Composition
import { defineComponent } from '@nuxtjs/composition-api'

// Composable
import {
  basketItems,
  isBasketEmpty,
  toggleShowBasket,
  hasSignalStarExperimentID,
} from '@/composables/checkout/useBasket'
import usePunchout from '~/composables/punchout/usePunchout'
import { useI18n } from '~/composables/i18n'

// Stores
import { useCheckoutStore } from '@/store/checkout'

// Components
import BasketSidebarHeader from '@/components/feature/basket/BasketSidebarHeader.vue'
import BasketSidebarTopMessage from '@/components/feature/basket/BasketSidebarTopMessage.vue'
import BasketSidebarItems from '@/components/feature/basket/BasketSidebarItems.vue'
import BasketSidebarCheckout from '@/components/feature/basket/BasketSidebarCheckout.vue'
import BasketSidebarBasketToQuote from '@/components/feature/basket/BasketSidebarBasketToQuote.vue'
import ChangeItemPopup from '~/components/feature/basket/ChangeItemPopup.vue'
import SearchInputField from '@/components/global/search/Searchinputfield.vue'
import { BasketItemInterface } from '~/types/checkout/checkoutTypes'

export default defineComponent({
  components: {
    BasketSidebarHeader,
    BasketSidebarTopMessage,
    BasketSidebarItems,
    BasketSidebarCheckout,
    BasketSidebarBasketToQuote,
    ChangeItemPopup,
    SearchInputField,
  },

  setup() {
    const { toggleChangeItemPopup } = useCheckoutStore()
    const { isPunchout } = usePunchout()
    const { t } = useI18n()

    const onDeleteItem = (item: BasketItemInterface) => {
      toggleChangeItemPopup(true, item)
    }

    const onInputValueChanged = (item: BasketItemInterface) => {
      if (hasSignalStarExperimentID(item)) {
        toggleChangeItemPopup(true, item, 'quantity')
      }
    }

    const onClickOutside = () => {
      toggleChangeItemPopup(false)
      toggleShowBasket(false)
    }

    return {
      isPunchout,
      basketItems,
      isBasketEmpty,
      toggleChangeItemPopup,
      onDeleteItem,
      onInputValueChanged,
      onClickOutside,
      t,
    }
  },
})
