


























































import { computed, defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api'
import Navigation from '@/components/global/navigation/Navigation.vue'
import SignInAndRegistrationLinks from '@/components/feature/account/SignInAndRegistrationLinks.vue'
import CountryCodeModal from '@/components/feature/country-selector/CountryCodeModal.vue'

import { useUserStore } from '@/store/user'
import { useSidebarStore } from '@/store'
import { useCMSGlobalSettingsStore } from '@/store/cms'

import usePunchout from '~/composables/punchout/usePunchout'
import { useCountrySelector } from '~/composables/countrySelector'
import { useRenderPerCountry } from '@/composables/country/useRenderPerCountry'

export default defineComponent({
  components: { Navigation, SignInAndRegistrationLinks, CountryCodeModal },
  setup() {
    const { isPunchout } = usePunchout()
    const { getAttributeByCountry } = useCMSGlobalSettingsStore()
    const sidebarStore = useSidebarStore()
    const route = useRoute()
    const { showForCountry: showRegLinksForCountry } = useRenderPerCountry(['US', 'DE', 'AT', 'GB'])

    const { changeCountry } = useCountrySelector()

    const isModalOpen = ref(false)

    const links = getAttributeByCountry('topbarLinks')
    const sidebar = sidebarStore.sidebar

    const currentCountryCode = ref(useUserStore().selectedCountry ?? '')
    const mappedCurrentCountryCode = computed(() => {
      if (currentCountryCode.value === 'US') return 'USA'
      return currentCountryCode.value
    })

    const toggleSidebar = () => {
      sidebarStore.toggleSidebar()
    }

    const setModal = (value: boolean = true) => {
      isModalOpen.value = value
    }

    watch(route, () => {
      // Closing sidebar when nuxt-link redirection
      if (sidebar.show) {
        sidebarStore.toggleSidebar()
      }
    })

    return {
      links,
      sidebar,
      toggleSidebar,
      isPunchout,
      mappedCurrentCountryCode,
      isModalOpen,
      setModal,
      changeCountry,
      showRegLinksForCountry,
    }
  },
})
