import { Context } from '@nuxt/types'
import { useQuotePageType } from '~/composables/quotes/useQuotesPageType'
import { MiddlewareFunction } from '~/types/common/commonTypes'

export function quotePageMiddleware({
  forB2Q,
  forB2D,
  forOther,
}: {
  forB2Q: MiddlewareFunction | null
  forB2D: MiddlewareFunction | null
  forOther: MiddlewareFunction | null
}) {
  return (ctx: Context) => {
    const pageType = useQuotePageType(ctx)

    const emptyMiddleware = () => {}

    let middleware
    if (pageType.value === 'lead') {
      middleware = forB2D ?? emptyMiddleware
    } else if (pageType.value === 'quote') {
      middleware = forB2Q ?? emptyMiddleware
    } else {
      middleware = forOther ?? emptyMiddleware
    }
    return middleware(ctx)
  }
}
