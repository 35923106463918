import cloneDeep from 'lodash/fp/cloneDeep'
import { CustomAxiosRequestConfig, CustomAxiosResponse } from '@cstweb/common'
import { Client } from '../client'
import { addExpandParams } from '../util'
import { CART_STORE_FIELD, URL_QUOTES } from '../util/constants'
import { editResponseForPromotions } from '../util/parser'
import { CookieComplexStore } from '../util/cookie'

function hasProperty<T extends string>(obj: unknown, propertyName: T): obj is { [_ in T]: unknown } {
  return typeof obj === 'object' && !!obj && propertyName in obj
}

interface QuotePayloadInterface {
  id: string
  version: number
}

function getRequestPayload(cartCookieStore: CookieComplexStore): QuotePayloadInterface {
  return {
    id: String(cartCookieStore.get(CART_STORE_FIELD.CART_ID)),
    version: Number(cartCookieStore.get(CART_STORE_FIELD.CART_VERSION)),
  }
}

export default (client: Client) => {
  const cartCookieStore = client.cookies.cart

  return {
    onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        return config
      }

      // Include additional information in response
      if (config.url === URL_QUOTES) {
        if (config.method === 'post') {
          const jsonData = config.data ? JSON.parse(config.data) : {}
          const newData = getRequestPayload(cartCookieStore)
          config.data = JSON.stringify({ ...jsonData, ...newData })
        }

        if (!config.syncVersion) {
          config.params = addExpandParams(config.params)
        }
      }
      return config
    },
    onResponse(response: CustomAxiosResponse) {
      const { config, data } = response

      // Clear cartId and cartVesion after submiting a quote
      if (config.method === 'post' && config.url === URL_QUOTES) {
        client.clearCartCookies()
      }

      try {
        if (config.url === URL_QUOTES) {
          // Parse and add promotion data
          let jsonData: unknown = data && typeof data === 'string' ? JSON.parse(data) : data
          jsonData = jsonData || {}

          if (hasProperty(jsonData, 'results') && Array.isArray(jsonData.results)) {
            // my quotes request
            response.data = JSON.stringify({
              ...jsonData,
              results: jsonData.results.map((quote) => ({
                ...quote,
                cart: editResponseForPromotions(quote.cart),
              })),
            })
          } else if (hasProperty(jsonData, 'cart')) {
            // basket to quote request
            response.data = JSON.stringify({ ...cloneDeep(jsonData), cart: editResponseForPromotions(jsonData.cart) })
          }
        }
      } catch (error) {
        console.error(error)
      }

      return response
    },
  }
}
