import { Client } from '../client'
import { URL_CARTS, URL_QUOTES } from '../util/constants'
import { setCartCheckoutType } from './cart'

export type Distributor = {
  id: string
  distributorUrl: string
  distributorPhone: string
  distributorName: string
  distributorFax: string
  distributorEmailCustomer: string
  addressStreet: string
  addressState: string
  addressPostalCode: string
  addressCountry: string
  addressCity: string
}

export type CustomerInfo = {
  country: string
  firstName: string
  lastName: string
  company: string
  email: string

  isQuoteNewsletterOptIn: boolean
  distributor: Distributor
}

export const bindClient = (client: Client) => {
  function setCustomerInfo(customerInfo: CustomerInfo) {
    const body = {
      actions: [
        {
          action: 'setShippingAddress',
          address: {
            country: customerInfo.country,
            firstName: customerInfo.firstName,
            lastName: customerInfo.lastName,
            company: customerInfo.company,
            custom: {
              type: {
                typeId: 'type',
                key: 'address-type',
              },
              fields: {
                isQuoteNewsletterOptIn: customerInfo.isQuoteNewsletterOptIn,
                quoteEmailAddress: customerInfo.email,
              },
            },
          },
        },
        {
          action: 'setCustomField',
          name: 'privacyAgreedToDateTime',
          value: new Date().toISOString(),
        },
        {
          action: 'setCustomField',
          name: 'distributorId',
          value: customerInfo.distributor.id,
        },
        {
          action: 'setCustomField',
          name: 'distributorUrl',
          value: customerInfo.distributor.distributorUrl,
        },
        {
          action: 'setCustomField',
          name: 'distributorPhone',
          value: customerInfo.distributor.distributorPhone,
        },
        {
          action: 'setCustomField',
          name: 'distributorName',
          value: customerInfo.distributor.distributorName,
        },
        {
          action: 'setCustomField',
          name: 'distributorFax',
          value: customerInfo.distributor.distributorFax,
        },
        {
          action: 'setCustomField',
          name: 'distributorEmailCustomer',
          value: customerInfo.distributor.distributorEmailCustomer,
        },
        {
          action: 'setCustomField',
          name: 'distributorAddressStreet',
          value: customerInfo.distributor.addressStreet,
        },
        {
          action: 'setCustomField',
          name: 'distributorAddressState',
          value: customerInfo.distributor.addressState,
        },
        {
          action: 'setCustomField',
          name: 'distributorAddressPostalCode',
          value: customerInfo.distributor.addressPostalCode,
        },
        {
          action: 'setCustomField',
          name: 'distributorAddressCountry',
          value: customerInfo.distributor.addressCountry,
        },
        {
          action: 'setCustomField',
          name: 'distributorAddressCity',
          value: customerInfo.distributor.addressCity,
        },
      ],
    }
    return client.axios.post(URL_CARTS, body)
  }

  function addComment(cartComment: string) {
    const body = {
      actions: [
        {
          action: 'setCustomField',
          name: 'cartComment',
          value: cartComment,
        },
      ],
    }

    return client.axios.post(URL_CARTS, body)
  }

  const generateB2DQuote = () => setCartCheckoutType(client)('quoteb2d')

  function submitB2DQuote(recaptchaToken: string) {
    const body = {
      recaptchaToken,
    }

    return client.axios.post(URL_QUOTES, body)
  }

  return {
    setCustomerInfo,
    addComment,
    generateB2DQuote,
    submitB2DQuote,
  }
}
