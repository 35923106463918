import Vue from 'vue'
import VueBrowserUpdate from '@sum.cumo/vue-browserupdate'

Vue.use(VueBrowserUpdate, {
  required: { i: 12, e: 92, f: 90, o: 78, s: 15, c: 92 },
  options: {
    // must be empty to render as first element in body DOM
    container: '',
  },
  test: false,
})
