import { withHttps } from 'ufo'
import { COA } from '~/types/product/productTypes'

export const getRelativeUrl = (url: string) => {
  try {
    const urlObj = new URL(url)
    return urlObj.pathname + urlObj.search
  } catch (e) {
    return url
  }
}

export const buildCoaUrl = (mediaUrl: string, coa: COA) => {
  return `${mediaUrl}/coa/${coa['url-key']}/${coa['pdf-file']}`
}

export const buildPdfDatasheetUrl = (mediaUrl: string, productId: string) => {
  return `${mediaUrl}/pdf/${productId}.pdf`
}

export const buildAntibodyFeatureReferenceCsvLink = (productId: string) => {
  return `/references/products/intraseq/${productId}_InTraSeq_feature_reference.csv`
}

export const buildDatasheetUrl = (
  productId: string,
  includeImage: boolean,
  includeProtocol: boolean,
  useA4format: boolean
) => {
  return `/products/${productId}/datasheet?images=${includeImage ? 1 : 0}&protocol=${includeProtocol ? 1 : 0}${
    useA4format ? '&size=A4' : ''
  }`
}

export const URLify = (string: string) => {
  // Find urls in string and replace them with anchor tags but ignore the urls inside anchor tags & images
  const urlRegex =
    /(<img\s[^>]*>|<a(?:\s[^>]*)?>[\s\S]*?<\/a>)|(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi

  return string.replace(urlRegex, (url, tag) => {
    // Check if this is link to a cellsignal page, if so, apply the relative URL logic
    const isInternal = url.match(/(https?:\/\/)?(www\.)?cellsignal\./gi)

    // If this link already is an HTML anchor link, return it
    if (tag) {
      // If external, return the anchor tag as is
      if (!isInternal) return url

      // find the URL in this anchor link string to make it relative
      const findLinkRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
      const findLink = url.match(findLinkRegex)
      return findLink ? url.replace(findLink[0], getRelativeUrl(findLink[0])) : url
    }

    // Otherwise, wrap it in an anchor tag
    let hyperlink = isInternal ? getRelativeUrl(url) : url
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink
    }
    return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
  })
}

export const customWithHttps = (url: string, pathName?: string) => {
  if (url.startsWith('//')) {
    //Double slashes url are used datasheets SDS COA, we need to remove the extra slashes
    return withHttps(url.slice(2))
  }

  if (url.startsWith('/')) {
    if (!window?.location && !pathName) {
      console.warn('Cannot get pathName. PathName was not provided!')
    }
    //Handling for relative urls
    return withHttps(window?.location?.pathname ?? pathName ?? '' + url)
  }

  return withHttps(url)
}
