import { Client } from '../client'
import { URL_PRODUCTS } from '../util/constants'

export const bindClient = (client: Client) => {
  // @deprecated
  /**
   * USED ONLY IN ATG MEDIA REPO
   *
   * Get a single product by productId.
   * @param productId as a string
   */
  const getSingleProductInfoByProductId = (productId: string) => {
    // string in param for the API call has to be without any quotes
    return client.axios.get(URL_PRODUCTS + '/key=' + productId.replace(/['"]+/g, ''))
  }

  // @deprecated
  /**
   * USED ONLY IN ATG MEDIA REPO
   *
   * Get multiple products where the SKU is either in masterVariant or variant.
   * Can get no results, single result or multiple results. By default limit is 20, offset is 0.
   * Can return all products when the parameter is empty.
   * @param SKUs as string[]
   * @returns
   */
  const getProductsInfoByProductSKUsInVariants = (SKUs: string[]) => {
    // string in param for the API call has to be string of SKU or SKUs in quotes and separated by a comma
    const stringOfSkus = SKUs.map((sku: string) => `"${sku}"`).join(',')
    const queryPredicate =
      '?where=masterVariant(sku in (' + stringOfSkus + ')) or variants(sku in (' + stringOfSkus + '))'
    return client.axios.get(URL_PRODUCTS + queryPredicate)
  }

  const getFullProduct = (productId: string) => {
    return client.axios.get(URL_PRODUCTS + `/${productId}/cached`)
  }

  return {
    getSingleProductInfoByProductId,
    getProductsInfoByProductSKUsInVariants,
    getFullProduct,
  }
}
