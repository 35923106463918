export const valutaMapping = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '￥',
  CNY: '¥',
} as const

export const routeMapping = {
  accountsettings: 'Account settings',
  addresses: 'Addresses & contacts',
  labnotebook: 'Lab notebook',
  orderhistory: 'Order history',
  myquotes: 'My Quotes',
  shippinginformation: 'Shipping information',
  paymentinformation: 'Payment information',
  'sign-in': 'Sign In',
  'request-confirm': 'Request Confirmation',
  'signalstar-targetspecies': 'SignalStar™ Multiplex IHC Kits & Reagents',
  'signalstar-selectantibodies': 'SignalStar™ Multiplex IHC Kits & Reagents',
  'signalstar-paneldesign': 'SignalStar™ Multiplex IHC Kits & Reagents',
  'signalstar-confirmation': 'SignalStar™ Multiplex IHC Kits & Reagents',
  'signalstar-quote-request': 'SignalStar™ Multiplex IHC Quote Request',
  'learn-and-support': 'Learn & Support',
  'videos-and-webinars': 'Videos & Webinars',
}

export const sortMapping = {
  Relevance: '_score',
  'Product name': 'namenotags',
  '# of Applications': 'numberofchiclets',
  '# of Citations': 'totalcitationcount',
  Newest: 'releaseddate',
}

export const applicationMapping = {
  'Western Blotting': {
    code: 'W',
    group: 'W',
    chiclet: 'WB',
  },
  'Fluorescent Western': {
    code: 'W-F',
    group: 'W',
    chiclet: 'WB',
  },
  'Simple Western™': {
    code: 'W-SW',
    group: 'W',
    chiclet: 'WB',
  },
  Immunoprecipitation: {
    code: 'IP',
    group: 'IP',
    chiclet: 'IP',
  },
  'Immunofluorescence (Frozen)': {
    code: 'IF-F',
    group: 'IF',
    chiclet: 'IF',
  },
  'Immunofluorescence (Immunocytochemistry)': {
    code: 'IF-IC',
    group: 'IF',
    chiclet: 'IF',
  },
  Immunocytochemistry: {
    code: 'IF',
    group: 'IF',
    chiclet: 'IF-IC',
  },
  'Flow Cytometry': {
    code: 'F',
    group: 'F',
    chiclet: 'F',
  },
  'Flow Cytometry (Fixed/Permeabilized)': {
    code: 'FC-FP',
    group: 'F',
    chiclet: 'F',
  },
  'Flow Cytometry (Live)': {
    code: 'FC-L',
    group: 'F',
    chiclet: 'F',
  },
  'Chromatin IP-seq': {
    code: 'ChIP-seq',
    group: 'ChIP',
    chiclet: 'ChIP',
  },
  'Chromatin Immunoprecipitation': {
    code: 'ChIP',
    group: 'ChIP',
    chiclet: 'ChIP',
  },
  'Chromatin IP': {
    code: 'ChIP',
    group: 'ChIP',
    chiclet: 'ChIP',
  },
  'IHC-Leica® Bond™': {
    code: 'IHC-Bond',
    group: 'IHC',
    chiclet: 'IHC',
  },
  'Immunohistochemistry (Frozen)': {
    code: 'IHC-F',
    group: 'IHC',
    chiclet: 'IHC',
  },
  'Immunohistochemistry (Paraffin)': {
    code: 'IHC-P',
    group: 'IHC',
    chiclet: 'IHC',
  },
}
