import { useContext } from '@nuxtjs/composition-api'

// Composables
import useAnalytics from '@/composables/analytics/useAnalytics'

export default () => {
  const context = useContext()
  const analytics = useAnalytics()

  /*
    Track sign-up for a particular user. Send the Merchant centre ID along with the push
  */
  const trackSignUp = () => {
    context.$gtm.push({
      event: 'sign_up',
      method: 'website',
      ...analytics.getCustomDimensions().ga4,
    })
  }

  /*
    Track login
  */
  const trackLogin = () => {
    context.$gtm.push({
      event: 'login',
      method: 'website',
      ...analytics.getCustomDimensions().ga4,
    })
  }

  /*
    Track logout
  */
  const trackLogout = () => {
    context.$gtm.push({
      event: 'logout',
      ...analytics.getCustomDimensions().ga4,
    })
  }

  return {
    trackSignUp,
    trackLogin,
    trackLogout,
  }
}
