import { Context } from '@nuxt/types'
import { detectServerBaseUrl, findCookieDomain, mergeCookieSettings } from '@cstweb/common'

import { FetchManyGlobalSettingsQuery } from '@/modules/contentstack/types'

import { useCMSNavigationStore, useCMSGlobalSettingsStore } from '~/store/cms'

import { performance } from '@/common/utils/performance'

export default async function (context: Context) {
  const start = performance.now()
  const { req, $pinia, route, $logger, $ct } = context
  let host

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)
  }
  if (process.client) {
    host = location.origin
  }

  const cookieDomain = findCookieDomain(host ?? '', false) // Do not enable full domain cookies for c_country/c_language cookies
  const cookieSettings = mergeCookieSettings({ domain: cookieDomain })

  $logger.debug({ host, cookieSettings }, '[application settings]')

  const navigationStore = useCMSNavigationStore($pinia)
  const globalSettingsStore = useCMSGlobalSettingsStore($pinia)

  const performanceLogger = $logger.child({
    lgroup: 'perf',
    path: route.fullPath,
  })

  try {
    await Promise.all([
      (async () => {
        let globalSettingsData
        if (context.payload?.globalSettingsData) {
          // SSG
          globalSettingsData = context.payload?.globalSettingsData
          globalSettingsStore.setGlobalSettingsData(globalSettingsData)
        } else {
          // SSR
          globalSettingsData = await fetchManyGlobalSettings()
        }
        navigationStore.setNavigationData(globalSettingsData)

        $ct.client.updateSettings({
          b2dCountries: globalSettingsStore.getAttributeByCountry('b2dAllowedCountries').value,
        })
      })(),
    ])
  } catch (error) {
    // console.error(error)
    $logger.error(error)
  }

  const totalTime = (performance.now() - start).toFixed(2)
  performanceLogger.debug(
    { perf: { totalTime: `${totalTime}ms` } },
    '[middleware: with-data] Total time: %d ms',
    totalTime
  )
}

export function fetchManyGlobalSettings(): Promise<FetchManyGlobalSettingsQuery> {
  return useCMSGlobalSettingsStore().fetchManyGlobalSettings()
}
