











































































// Composition
import { defineComponent, ref, watch, useRoute, computed, onUnmounted } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

// Compossable
import useSearch from '@/composables/search/useSearch'

// Components
import SearchInputField from '@/components/global/search/Searchinputfield.vue'
import SearchSuggestions from '@/components/global/search/Suggestions.vue'

// Store
import { useCMSGlobalSettingsStore, useSearchStore } from '@/store'

export default defineComponent({
  components: {
    SearchInputField,
    SearchSuggestions,
  },

  props: {
    showLogo: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSuggestion: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDefaultState: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { getAttributeByCountry, showMainNav } = useCMSGlobalSettingsStore()
    const brandLogo = getAttributeByCountry('headerBrandLogo')
    const percentLogo = getAttributeByCountry('percentLogo')
    const searchStore = useSearchStore()
    const { typeahead } = storeToRefs(searchStore)
    const { setDefaultSearchSettings } = useSearch()
    const showOnePercentLogo = ref(props.showLogo)
    const route = useRoute()
    const showMenuOverlay = computed(() => showMainNav)
    const showSearchOverlay = computed(() => {
      return typeahead.value.showSuggests && !showMenuOverlay.value
    })

    function resetSearchSettings() {
      searchStore.setSearchInput('')
      setDefaultSearchSettings()
    }

    onUnmounted(() => {
      resetSearchSettings()
    })

    watch(typeahead, () => {
      if (!props.showLogo) {
        showOnePercentLogo.value = false
      } else {
        showOnePercentLogo.value = window.innerWidth < 414 ? !typeahead.value.showSuggests : true
      }
    })

    watch(route, () => {
      if (route.value.path !== '/browse') {
        resetSearchSettings()
      }
    })

    return {
      brandLogo,
      percentLogo,
      showMenuOverlay,
      showSearchOverlay,
      searchStore,
      showOnePercentLogo,
      typeahead,
    }
  },
})
