import { defineStore } from 'pinia'
import { useCMSContentFragmentsStore } from '.'
import { getContentComponentsFromContentTarget } from '~/modules/contentstack/contentRulesEvaluator'
import {
  ContentTarget,
  parseContentTargetResponse,
  parseManyContentTargetsResponse,
} from '~/common/utils/parsers/cms/contentTarget'
import { useCountry } from '~/composables/country/useCountry'
import { ProductInterface } from '~/types/product/productTypes'

interface ContentTargetsCMSStore {
  ct: Record<string, ContentTarget>
}

export const useStore = defineStore({
  id: 'cms-content-targets',
  state: (): ContentTargetsCMSStore => ({ ct: {} }),
  getters: {
    getByDevKey: (state) => (devKey: string, product: ProductInterface) => {
      const contentTarget = state.ct[devKey]
      if (!contentTarget) {
        return null
      }

      return getContentComponentsFromContentTarget(contentTarget, {
        currentProduct: product,
        selectedCountry: useCountry().selectedCountry.value,
      })
    },
  },
  actions: {
    async fetchContentTarget(devkey: string, forceFetch = false) {
      if (!forceFetch && this.ct[devkey]) {
        return
      }

      const contentFragmentStore = useCMSContentFragmentsStore()
      const data = await this.$nuxt.$cms.api.fetchContentTarget({
        devkey,
        locale: useCountry().getLocaleCode(),
        fallbackLocale: true,
      })

      const parsedData = parseContentTargetResponse(data)

      this.ct[devkey] = parsedData
      contentFragmentStore.updateUsingContentTarget(parsedData)
    },
    async fetchManyContentTargets(devKeys: string[], forceFetch = false) {
      if (!devKeys.length) {
        return
      }

      if (!forceFetch) {
        devKeys = devKeys.filter((key) => !this.ct[key])
      }

      const data = await this.$nuxt.$cms.api.fetchManyContentTargets({
        fallbackLocale: true,
        devkeys: devKeys,
      })

      this._update(data)
    },
    _update(data: Record<string, any>) {
      const contentFragmentStore = useCMSContentFragmentsStore()
      const parsedContentTargets = parseManyContentTargetsResponse(data)
      parsedContentTargets.forEach((parsedTarget) => {
        this.ct[parsedTarget.devkey] = parsedTarget
        contentFragmentStore.updateUsingContentTarget(parsedTarget)
      })
    },
  },
})
