import { APIError } from 'src/middleware/error-mapping'

export interface InvalidInputError extends APIError {
  code: 'InvalidInput'
  // Everything is optional, because I could not find any documentation
  // whether these fields are always present in InvalidInput error
  extensionExtraInfo?: {
    errors?: Partial<APIError>[]
  }
}

function isInvalidInputError(error: APIError): error is InvalidInputError {
  return error.code === 'InvalidInput'
}

export function mapInvalidInputError(error: APIError): string {
  if (
    isInvalidInputError(error) &&
    error.extensionExtraInfo?.errors?.some((e) => e?.code === 'MaxCartLineitemLimitReached')
  ) {
    return 'Basket maximum reached. Please complete your current order.'
  }

  return error.message
}
