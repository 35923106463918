import { format, isAfter, isToday, isTomorrow, startOfToday } from 'date-fns'
import { defineStore } from 'pinia'
import { GOP_SUPPORTED_COUNTRIES } from '~/common/static/gop'
import { useCountry } from '~/composables/country/useCountry'
import { useFeatureFlag } from '~/composables/featureFlags/useFeatureFlag'
import { BasketItemsDataInterface } from '~/types/checkout/checkoutTypes'

export interface GOPRequestItem {
  sku: string
  quantity: number
}

export interface GOPResponseItem {
  organizationId: string
  sku: string
  quantity: string
  finalShipDate: string
}

export interface GOPItem {
  organizationId: string
  sku: string
  quantity: number
  finalShipDate: string
  displayFinalShipDate: string
}

export const useEbsStore = defineStore({
  id: 'ebs',
  state: () => ({
    gopItems: {} as Record<string, GOPItem>,
    previousFetchData: {} as {
      countryCode: string
      items: GOPRequestItem[]
    },
  }),
  getters: {
    showGOPAvailability() {
      const showGop = useFeatureFlag('showGop')
      const { selectedCountry } = useCountry()
      return showGop.value && GOP_SUPPORTED_COUNTRIES.includes(selectedCountry.value.toUpperCase())
    },
    showGOPAvailabilityItems(state): boolean {
      return this.showGOPAvailability && Object.keys(state.gopItems).length > 0
    },
  },
  actions: {
    async fetchShipDates(countryCode: string, items: GOPRequestItem[]) {
      if (GOP_SUPPORTED_COUNTRIES.includes(countryCode.toUpperCase()) === false || !items?.length) {
        return
      }
      const response = await this.$nuxt.$axios.post<{ items: GOPResponseItem[] }>(`/api/ebs/gop/${countryCode}/sku`, {
        items,
      })
      this.previousFetchData = {
        countryCode,
        items,
      }
      this.gopItems = response.data.items
        .filter((item) => !!item.finalShipDate && isAfter(new Date(item.finalShipDate), startOfToday()))
        .reduce(
          (acc, item) => ({
            ...acc,
            [item.sku]: {
              ...item,
              quantity: +item.quantity,
              displayFinalShipDate: generateShipDateString(item.finalShipDate),
            },
          }),
          this.gopItems
        )
    },
    async refetchShipDates() {
      const { countryCode, items } = this.previousFetchData
      if (countryCode && items) {
        await this.fetchShipDates(countryCode, items)
      }
    },
    buildGopRequestItems(lineItems: BasketItemsDataInterface) {
      const gopRequestItems: GOPRequestItem[] = []
      for (const itemId in lineItems) {
        const item = lineItems[itemId]
        const gopRequestItem: GOPRequestItem = {
          sku: item.variant.sku ? item.variant.sku : '',
          quantity: item.quantity,
        }
        gopRequestItems.push(gopRequestItem)
      }
      return gopRequestItems
    },
  },
})

function generateShipDateString(shipDate: string) {
  const localDate = new Date(shipDate)

  if (isToday(localDate)) {
    return 'Today'
  }

  if (isTomorrow(localDate)) {
    return 'Tomorrow'
  }

  return format(localDate, 'MMMM d')
}
