import { defineStore } from 'pinia'
import type { FetchManyByDevKeysNavigationQuery, Navigation as CMSNavigation } from '~/modules/contentstack/types'
import type { Navigation } from '~/types/cms/navigation'
import CMSParsers from '~/common/utils/parsers/cms'
import { useCountry } from '~/composables/country/useCountry'

interface CMSNavigationStore {
  byId: {
    [key: string]: Navigation
  }
}

export const useStore = defineStore({
  id: 'cms-navigation',
  state: (): CMSNavigationStore => ({
    byId: {},
  }),
  getters: {
    byKey: (state) => (devkey: string) => Object.values(state.byId).find((navigation) => navigation.devkey === devkey),
  },
  actions: {
    async fetchNavigationByDEVKey(devkey: string) {
      const { getLocaleCode } = useCountry()
      const { data } = await this.$nuxt.$cms.api.fetchNavigationByDEVKey({
        devkey,
        locale: getLocaleCode(),
        fallbackLocale: true,
      })

      this.setNavigationData(data)
    },
    async fetchManyByDevKeysNavigation(devkeys: string[]) {
      const { getLocaleCode } = useCountry()
      const { data } = await this.$nuxt.$cms.api.fetchManyByDevKeysNavigation({
        devkeys,
        limit: devkeys.length,
        locale: getLocaleCode(),
        fallbackLocale: true,
      })

      this.setNavigationData(data)
    },
    setNavigationData(data: FetchManyByDevKeysNavigationQuery) {
      if (data.all_navigation?.items?.length) {
        data.all_navigation?.items?.forEach((item) => {
          const navigation = CMSParsers.Navigation.parseNavigation(item as CMSNavigation)
          if (navigation.uid) {
            this.$patch({ byId: { [navigation.uid]: navigation } })
          }
        })
      }
    },
  },
})
