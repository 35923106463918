import { AudienceConnection } from '~/store/cms/global-settings'

interface SettingParam {
  audienceConnection: AudienceConnection
  parsed: any
}

/**
 * Will return global setting instance by country code. Country code parameter is optional and when not specified, fallback global setting will be returned.
 * Fallback global setting is for US/ROW and has neither country nor audience defined.
 * @param settings - Array of Global Settings items
 * @param countryCode(optional) - String of current country code
 */
export function getSettingByCountryCode(settings: SettingParam[], countryCode?: string) {
  // fallback default (US) Setting is when the country is not set in the CMS
  let setting = settings.find((item) => !item.audienceConnection?.edges?.length)
  if (!countryCode) {
    return setting
  } else {
    setting =
      settings.find(
        (item) =>
          (item.audienceConnection?.edges?.[0]?.node?.__typename === 'Country' &&
            item.audienceConnection?.edges?.[0]?.node.country_code === countryCode) ||
          (item.audienceConnection?.edges?.[0]?.node?.__typename === 'Audience' &&
            item.audienceConnection?.edges?.[0]?.node.countriesConnection?.edges?.some(
              (audience) => audience?.node?.country_code === countryCode
            ))
      ) ?? setting
    return setting
  }
}
