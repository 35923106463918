interface CloudflareGetVariantsResponseFigure {
  caption: string
  filename: string
  imageheight: string
  imagewidth: string
  variants: string[]
  'figure-type'?: string
  id?: string
  'dam-media-id': string
  isProtected?: string
}
export interface CloudflareGetVariantsResponse {
  figures: CloudflareGetVariantsResponseFigure[]
}

export const CloudflareVariants = {
  PUBLIC: 'public',
  THUMBNAIL: 'thumbnail',
  PUBLIC_MOD: 'publicmod',
  X1: '1X',
  X2: '2X',
  X4: '4X',
  X6: '6X',
}

export type ProductImageVariants = {
  [key in keyof typeof CloudflareVariants]: string
}

export interface ProductImage {
  caption: string
  filename: string
  id: string
  isProtected: boolean
  damId: string
  figureType?: string
  size: {
    height: string
    width: string
  }
  variants: ProductImageVariants
}
