import type { ConfigStore } from '~/store/config'

export const getCustomDimensions = (profileStore: any, configStore: ConfigStore) => {
  const userIsLoggedIn = profileStore.userInfo.email.length > 0

  const getUserType = () => {
    if (profileStore.isPunchout) {
      return 'punchout'
    }

    return userIsLoggedIn && profileStore.isVerified === true ? 'Registered' : 'Guest'
  }

  const loginStatus = userIsLoggedIn ? '1' : '0'

  return {
    ga4: {
      country_site: configStore.countryCode,
      login_status: loginStatus,
      user_type: getUserType(),
      mc_id: profileStore.userInfo.id,
      client_address: configStore.clientIP,
    },
  }
}
