import { CustomAxiosRequestConfig, CustomAxiosResponse } from '@cstweb/common'
import { Client } from '../client'
import { addExpandParams } from '../util'
import { editResponseForPromotions, editResponseForReturnedItems } from '../util/parser'
import { CART_STORE_FIELD, URL_ORDERS } from '../util/constants'
import { CookieComplexStore } from '../util/cookie'

interface OrdersPayloadInterface {
  id: string
  version: number
}

function getRequestPayload(cartCookieStore: CookieComplexStore): OrdersPayloadInterface {
  return {
    id: String(cartCookieStore.get(CART_STORE_FIELD.CART_ID)),
    version: Number(cartCookieStore.get(CART_STORE_FIELD.CART_VERSION)),
  }
}

export default (client: Client) => {
  const cartCookieStore = client.cookies.cart

  return {
    onRequest(config: CustomAxiosRequestConfig) {
      if (config.numOfRetries && !config.syncVersion) {
        return config
      }

      if (config.url?.includes(URL_ORDERS)) {
        if (config.method === 'post') {
          const newData = getRequestPayload(cartCookieStore)
          config.data = JSON.stringify(newData)
        }

        if (!config.syncVersion) {
          config.params = addExpandParams(config.params)
        }
      }
      return config
    },
    onResponse(response: CustomAxiosResponse) {
      const { config, data } = response

      if (config.url?.includes(URL_ORDERS)) {
        if (config.method === 'get') {
          // Parssing for order history
          const parsedJsonData = (data && JSON.parse(data)) || {}
          const results = parsedJsonData?.results ?? []

          response.data = JSON.stringify({
            ...parsedJsonData, // Pagination attributtes
            results: results?.map((result: any) => editResponseForPromotions(editResponseForReturnedItems(result))),
          })
        }

        // Clear cart only when posting order
        if (config.method === 'post') {
          client.clearCartCookies()
        }
      }
      return response
    },
  }
}
