import { Ref, onMounted, onUnmounted, reactive, toRefs } from '@nuxtjs/composition-api'

interface WindowSize {
  x: Ref<number>
  y: Ref<number>
}

export function useWindowSize(): WindowSize {
  const windowSize = reactive({ x: 0, y: 0 })
  const resizeListener = () => {
    if (process.client) {
      ;({ innerWidth: windowSize.x, innerHeight: windowSize.y } = window)
    } else {
      ;({ innerWidth: windowSize.x, innerHeight: windowSize.y } = { innerWidth: 0, innerHeight: 0 })
    }
  }

  onMounted(() => {
    resizeListener()
    window.addEventListener('resize', resizeListener)
  })

  onUnmounted(() => window.removeEventListener('resize', resizeListener))

  return toRefs(windowSize)
}

export default useWindowSize
