import type { Logger } from 'pino'
import { Service } from 'axios-middleware'
import type { CustomAxiosInstance, CustomAxiosError } from '@cstweb/common'
import { mergeCookieSettings } from '@cstweb/common'

import { buildAPI } from './api'
import { CookieStoreConfig } from './types'
import AuthMiddleware from './middleware/auth/auth'
import OAuthMiddleware from './middleware/auth/oauth'
import {
  CartMiddleware,
  CartRecalcMiddleware,
  CartRecalcAfterLoginMiddleware,
  RemoveCartWithInvalidCountryCode,
} from './middleware/cart'
import CustomerMiddleware from './middleware/customer'
import OrderMiddleware from './middleware/order'
import ErrorMiddleware from './middleware/error'
import ErrorMappingMiddleware from './middleware/error-mapping'
// import { CookiesPersistMiddleware } from './middleware/cookie'
import ProductMiddleware from './middleware/product'
import QuoteMiddleware from './middleware/quote'
import RetryMiddleware from './middleware/retry'
import { LogRequestMiddleware, LogResponseMiddleware } from './middleware/log'
import { CookieSimpleStore, CookieComplexStore } from './util/cookie'
import { COOKIES } from './util/constants'
import { hasCart, hasRefreshToken, hasCustomer, hasPunchoutCustomer } from './util'
import { DedupeMiddleware, prepareDedupe } from './middleware/dedupe'
import { OAuthClient } from './client/oauth-client'

export interface ClientSettings {
  b2dCountries: string[]
}

export interface ClientHooks {
  onErrorInvalidToken?: ({ isLoggedIn, isPunchout }: { isLoggedIn?: boolean; isPunchout?: boolean }) => void
  onSessionExpired?: ({ isLoggedIn, isPunchout }: { isLoggedIn?: boolean; isPunchout?: boolean }) => void
  onNetworkError?: (error: CustomAxiosError) => void
}

interface ClientCookieStore {
  auth: CookieComplexStore
  cart: CookieComplexStore
  customer: CookieComplexStore
  hybridMigration?: CookieSimpleStore
  hybridCartQuantity: CookieSimpleStore
  hybridCountryCode: CookieSimpleStore
}

type LogService = {
  sendError: (error: any) => Promise<any>
  sendInfo: (message: any) => Promise<any>
}

export type ClientOptions = {
  axios: CustomAxiosInstance
  auth: {
    client: OAuthClient
  }
  cookies: CookieStoreConfig
  logger?: Logger
  logService?: LogService
  settings?: ClientSettings
  hooks?: ClientHooks
}

export class Client {
  axios: CustomAxiosInstance
  settings?: ClientSettings
  cookiesProvider: CookieStoreConfig
  cookies: ClientCookieStore
  logService?: LogService
  logger?: Logger
  hooks: ClientHooks
  auth: {
    client: OAuthClient
  }

  static buildClient(options: ClientOptions) {
    const client = new this(options)
    return {
      client,
      ...buildAPI(client),
    }
  }

  constructor(options: ClientOptions) {
    this.axios = options.axios
    this.auth = {
      client: options.auth.client,
    }
    this.settings = options.settings
    this.cookiesProvider = options.cookies
    this.cookies = {
      auth: CookieComplexStore.namespace(COOKIES.AUTH, options.cookies),
      cart: CookieComplexStore.namespace(COOKIES.CART, options.cookies),
      customer: CookieComplexStore.namespace(COOKIES.CUSTOMER, options.cookies),
      hybridCartQuantity: CookieSimpleStore.namespace(COOKIES.HYBRID_CART_QUANTITY, options.cookies),
      hybridCountryCode: CookieSimpleStore.namespace(COOKIES.HYBRID_COUNTRY_CODE, {
        ...options.cookies,
        settings: { ...options.cookies.settings, domain: options.cookies.settings.tldDomain },
      }),
    }
    this.logService = options.logService
    this.logger = options.logger
    this.hooks = {
      ...options.hooks,
    }

    /* Required for the dedupe middleware to work properly */
    prepareDedupe(this.axios)
    prepareDedupe(this.auth.client.axios)

    const service = new Service(this.axios)
    const oauthService = new Service(this.auth.client.axios)

    this.migrateCookies()
    this.removeCookies()

    this.correctCookies()

    /**
     * Order of execution:
     *    Request: bottom -> top
     *    Response: top -> bottom
     *    ErrorResponse: top -> bottom
     */
    service.register([
      DedupeMiddleware(this),
      QuoteMiddleware(this),
      ProductMiddleware(),
      OrderMiddleware(this),
      CustomerMiddleware(this),
      CartMiddleware(this),
      CartRecalcMiddleware(this),
      AuthMiddleware(this),
      RemoveCartWithInvalidCountryCode(this),
      CartRecalcAfterLoginMiddleware(this),
      ErrorMappingMiddleware(this),
      LogResponseMiddleware(this),
      RetryMiddleware(this),
      ErrorMiddleware(this),
      // CookiesPersistMiddleware(this),
      LogRequestMiddleware(this),
    ])

    oauthService.register([DedupeMiddleware(this.auth.client), RetryMiddleware(this), OAuthMiddleware(this)])
  }

  updateSettings(settings: ClientSettings) {
    this.settings = Object.assign({}, this.settings, settings)
  }

  private correctCookies() {
    if (
      hasCustomer(this.cookies.customer) &&
      !hasPunchoutCustomer(this.cookies.customer) &&
      !hasRefreshToken(this.cookies.auth)
    ) {
      this.clearCookies()
      this.hooks.onSessionExpired && this.hooks.onSessionExpired({ isLoggedIn: true, isPunchout: false })
    }
  }

  private migrateCookies() {
    if (this.shouldMigrateCookies()) {
      this.cookies.auth.migrate()
      this.cookies.cart.migrate()
      this.cookies.customer.migrate()
      this.cookies.hybridCartQuantity.migrate()
      this.removeMigratedCookies()
    }
  }

  clearCookies() {
    this.cookies.auth.clear()
    this.cookies.cart.clear()
    this.cookies.customer.clear()
    this.cookies.hybridCartQuantity.clear()
  }

  clearCartCookies() {
    this.cookies.cart.clear()
    this.cookies.hybridCartQuantity.clear()
  }

  private hasDuplicateCookies(cookies: string[]) {
    const { cookieHeader } = this.cookiesProvider

    let hasDuplicateCookies = false

    hasDuplicateCookies = cookies.some((cookie) => {
      const value = cookieHeader?.split('; ').filter((existingCookie) => existingCookie.includes(cookie)).length > 1
      // console.log('Duplicates:', cookie, value)
      return value
    })

    return hasDuplicateCookies
  }

  private shouldMigrateCookies() {
    const cookiesToMigrate = [COOKIES.AUTH, COOKIES.CART, COOKIES.CUSTOMER, COOKIES.HYBRID_CART_QUANTITY]
    return this.hasDuplicateCookies(cookiesToMigrate)
  }

  private removeMigratedCookies() {
    const { provider, settings } = this.cookiesProvider
    const { domain, tldDomain, fullDomain } = settings
    const cookiesToRemove = [COOKIES.AUTH, COOKIES.CART, COOKIES.CUSTOMER, COOKIES.HYBRID_CART_QUANTITY]
    const cookieDomain = domain === fullDomain ? tldDomain : fullDomain

    cookiesToRemove.forEach((cookie) =>
      provider.set(
        cookie,
        undefined,
        mergeCookieSettings({
          domain: cookieDomain,
          expires: new Date(0),
        })
      )
    )
  }

  private removeCookies() {
    const { provider, settings, cookieHeader } = this.cookiesProvider
    const { fullDomain } = settings
    const cookieDomain = fullDomain
    const cookies: string[] = [COOKIES.HYBRID]

    const cookiesToRemove = cookies.reduce((acc, cookie) => {
      const value = cookieHeader?.split('; ').find((existingCookie) => existingCookie.includes(cookie))
      if (value) {
        acc.push(cookie)
      }
      return acc
    }, [] as string[])

    if (cookiesToRemove.length) {
      cookiesToRemove.forEach((cookie) => {
        const cookieSettings = mergeCookieSettings({
          domain: cookieDomain,
          expires: new Date(0),
          maxAge: new Date(0).getTime() / 1000,
        })
        provider.set(cookie, undefined, cookieSettings)
      })
    }
  }

  public hasCart() {
    return hasCart(this.cookies.cart)
  }
}
