import { onMounted, onUnmounted } from '@nuxtjs/composition-api'

/**
 * Helper functions to work with javascript events using composition API.
 */

/**
 * Registers a listener to a global event
 * @param eventName name of the global event
 * @param eventHandler event handler
 */
export function useCustomEventListener(eventName: string, eventHandler: (data: any) => void): void {
  const handleEvent = (event: CustomEvent | Event) => {
    const data = (event as CustomEvent).detail
    eventHandler(data)
  }

  onMounted(() => {
    document.addEventListener(eventName, handleEvent, false)
  })

  onUnmounted(() => {
    document.removeEventListener(eventName, handleEvent, false)
  })
}

/**
 * Emits a global event. Supported only on the client.
 * @param eventName name of the event to be emitted
 * @param data optional data
 */
export function emitCustomEvent(eventName: string, data: any = null): void {
  // Nuxt 2 supports global event bus using `this.$nuxt.emit` and `this.$nuxt.on`
  // but it was deprecated in Nuxt 3.

  if (!process.client) {
    console.warn('Emitting custom event is supported only on the client. Emit is ignored.')
    return
  }

  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}
