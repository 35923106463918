














































































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { useUserStore } from '@/store'

// Composables
import useMyAccountAnalytics from '@/composables/analytics/useMyAccountAnalytics'
import { useUserStatus } from '@/composables/user/useUser'
import { useMobile } from '~/composables/ui/useMobile'

// import NuxtLink from '@cstweb/storefront-vue/src/helpers/NuxtLink.vue'

export default defineComponent({
  props: {
    mode: {
      type: String as () => 'sidebar' | 'topbar',
      default: 'topbar',
    },
  },
  setup() {
    const { isLoggedIn } = useUserStatus()
    const { isTablet: isTabletOrMobile } = useMobile()
    const { trackLogout } = useMyAccountAnalytics()

    const onLogout = async () => {
      // set state in store
      await useUserStore().logout()

      // analytics
      trackLogout()

      /**
       * redirect
       * - full page reload because of removed cookies on logout.
       * - when using just router.push and then sign in again,
       *   the cookies are not created for some reason.
       */
      // window.location.href = '/account/sign-in'

      // FIXME: remove this after ATG sunset
      // if (process.client) {
      //   try {
      //     const atgSession = sessionStorage.getItem('_dynSessConf')
      //     if (atgSession) {
      //       await context.$atg.logout()
      //     }
      //   } catch (e) {}
      // }
      // FIXME: redirect to homepage (https://cellsignal.atlassian.net/browse/WEBP-3417)
      window.location.href = '/'
    }

    return { isTabletOrMobile, isLoggedIn, onLogout }
  },
})
