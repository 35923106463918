export const URL_REVOKE_TOKEN = '/oauth/revoke/token'
export const URL_CLIENT_TOKEN = '/oauth/client/token'
export const URL_ANONYMOUS_TOKEN = '/oauth/anonymous/token'
export const URL_CUSTOMERS_TOKEN = '/oauth/customers/token'
export const URL_REFRESH_TOKEN = '/oauth/refresh/token'

export const URL_CUSTOMERS = '/customers'
export const URL_LOGIN = '/customers/login'
export const URL_SIGNUP = '/customers/signup'
export const URL_PAYMENTS = '/customers/payments'
export const URL_CUSTOMERS_ME = '/customers/me'
export const URL_CUSTOMERS_LABNOTEBOOK = 'customers/me/lab-notebook'
export const URL_CUSTOMERS_CONTACTS = '/customers/me/contacts'
export const URL_REQUEST_PASSWORD_RESET = '/customers/password-token'
export const URL_PASSWORD_RESET = '/customers/reset-password'
export const URL_PASSWORD_CHANGE = '/customers/password'
export const URL_VERIFY_EMAIL_TOKEN = '/customers/email-verify'
export const URL_RESEND_VERIFICATION_EMAIL = '/customers/resend-verification'

export const URL_GROUP_NOT_CUSTOMER_ENTITY = [
  URL_LOGIN,
  URL_SIGNUP,
  URL_REQUEST_PASSWORD_RESET,
  URL_PASSWORD_RESET,
  URL_PASSWORD_CHANGE,
  URL_VERIFY_EMAIL_TOKEN,
  URL_RESEND_VERIFICATION_EMAIL,
]

export const URL_CARTS = '/carts'
export const URL_ACTIVE_CART = '/carts/active-cart'

export const URL_PRODUCTS = '/products'

export const URL_ORDERS = '/orders'

export const URL_QUOTES = '/quotes'

export const URL_SIGNALSTAR = '/signalstar'
export const URL_SIGNALSTAR_SEND_EMAIL = '/signalstar/send-experiment'
export const URL_SIGNALSTAR_CREATE_EXPERIMENT = '/signalstar/create-experiment'

export const URL_PDF = '/pdf'

export const URL_PUNCHOUT_PLACE_ORDER = '/punchout-carts'

export const REQUEST_NUMBER_OF_RETRIES = 3
export const REQUEST_RETRY_DELAY = 1500

export const HTTP_STATUS = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  NETWORK_CONNECT_TIMEOUT_ERROR: 599,
  AXIOS_NETWORK_ERROR: 0,
} as const

export const TOKEN_FLOW = {
  CLIENT: 'client',
  ANONYMOUS: 'anonymous',
  CUSTOMER: 'customer',
  PASSWORD: 'password', // FIXME: remove once ATG/Netrex turn off
}

export const AUTH_STORE_FIELD = {
  ACCESS_TOKEN: 'accessToken',
  EXPIRES_IN: 'expiresIn',
  EXPIRES_AT: 'expiresAt',
  REFRESH_TOKEN: 'refreshToken',
  FLOW: 'flow',
} as const

export const CART_STORE_FIELD = {
  CART_ID: 'id',
  CART_VERSION: 'version',
  IS_CREATING: 'isCreating',
  COUNTRY: 'country',
  CURRENCY: 'currency',
  LAST_MODIFIED_AT: 'lastModifiedAt',
} as const

export const CUSTOMER_STORE_FIELD = {
  CUSTOMER_ID: 'id',
  CUSTOMER_VERSION: 'version',
  CUSTOMER_VERIFIED: 'isVerified',
  IS_CREATING: 'isCreating',
  IS_PUNCHOUT: 'isPunchout',
} as const

export const COOKIES = {
  AUTH: 'ct-auth',
  CART: 'ct-cart',
  CUSTOMER: 'ct-customer',

  // below only for hybrid environment, do not use in Nuxt code !
  HYBRID: '72a1476d-2d3c-44a5-b1b2-7895e2f72fb9',
  HYBRID_CART_QUANTITY: 'cart-quantity',
  HYBRID_COUNTRY_CODE: 'c_country',
  HYBRID_LANGUAGE_CODE: 'c_language',
} as const

export const EXPAND_LINE_ITEM_DISCOUNT_PER_QTY =
  'lineItems[*].discountedPricePerQuantity[*].discountedPrice.includedDiscounts[*].discount'
export const EXPAND_LINE_ITEM_DISCOUNT_PRICE = 'lineItems[*].discountedPrice.includedDiscounts[*].discount'
export const EXPAND_DISCOUNT_CODE = 'discountCodes[*].discountCode'
export const EXPAND_PAYMENTINFO = 'paymentInfo.payments[*]'
export const EXPAND_STATE = 'state'
export const EXPAND_LINE_ITEM_STATE = 'lineItems[*].state[*].state'
export const EXPAND_LINE_ITEM_VARIANT_ATTRIBUTES_VALUE = 'lineItems[*].variant.attributes[*].value'

export const EXPAND_MASTER_VARIANT_ATTRIBUTES_VALUE = 'masterVariant.attributes[*].value'
export const EXPAND_MASTER_VARIANT_ATTRIBUTES_VALUES = 'masterVariant.attributes[*].value[*]'
export const EXPAND_VARIANT_ATTRIBUTES_VALUE = 'variants[*].attributes[*].value'
export const EXPAND_VARIANT_ATTRIBUTES_VALUES = 'variants[*].attributes[*].value[*]'
export const EXPAND_CATEGORIES = 'categories[*]'

export const ACTION_RECALCULATE = {
  action: 'recalculate',
  updateProductData: true,
}
