import { NuxtConfig } from '@nuxt/types'

// GraphQL Queries
import fetchPDPTemplateQuery from './queries/fetchPDPTemplate.gql'
import fetchPDPPageQuery from './queries/fetchPDPPage.gql'
import fetchPageByDevKey from './queries/fetchPageByDevKey.gql'
import fetchContentFragment from './queries/fetchContentFragment.gql'
import fetchContentTarget from './queries/fetchContentTarget.gql'
import fetchPDPTemplateAndPDPPage from './queries/fetchPDPTemplateAndPDPPage.gql'
import fetchNavigationByDevKey from './queries/navigation/fetchNavigationByDevKey.gql'
import fetchManyByDevKeysNavigation from './queries/navigation/fetchManyByDevKeysNavigation.gql'
import fetchRegionalSettings from './queries/fetchRegionalSettings.gql'
import fetchManyContentTargets from './queries/fetchManyContentTargets.gql'
import fetchManyGlobalSettings from './queries/fetchManyGlobalSettings.gql'
import fetchTestHTML from './queries/fetchTestHTML.gql'
import fetchContentVideoById from './queries/fetchContentVideoById.gql'
import fetchContentVideoPageByUrl from './queries/fetchContentVideoPageByUrl.gql'
import fetchPathwaysAndNewsPageByUrl from './queries/fetchPathwaysAndNewsPageByUrl.gql'
import fetchNewsPageByNavigationLink from './queries/fetchNewsPageByNavigationLink.gql'
import fetchProteinDomainPageByNavigationLink from './queries/fetchProteinDomainPageByNavigationLink.gql'
import fetchContentPageByUrl from './queries/fetchContentPageByUrl.gql'
import fetchProtocolPageByUrl from './queries/fetchProtocolPageByUrl.gql'

// GraphQL Queries Types
import type {
  FetchNavigationByDevKeyQueryVariables,
  FetchNavigationByDevKeyQuery,
  FetchManyByDevKeysNavigationQueryVariables,
  FetchManyByDevKeysNavigationQuery,
  FetchPageByDevKeyQueryVariables,
  FetchPageByDevKeyQuery,
  FetchPdpTemplateByProductIdAndTypeQueryVariables,
  FetchPdpTemplateByProductIdAndTypeQuery,
  FetchPdpPageByProductIdQueryVariables,
  FetchPdpPageByProductIdQuery,
  FetchContentFragmentByUidQueryVariables,
  FetchContentFragmentByUidQuery,
  FetchContentTargetByDevKeyQueryVariables,
  FetchContentTargetByDevKeyQuery,
  FetchPdpTemplateAndPdpPageByProductIdAndTypeQuery,
  FetchPdpTemplateAndPdpPageByProductIdAndTypeQueryVariables,
  FetchRegionalSettingsQueryVariables,
  FetchRegionalSettingsQuery,
  FetchManyContentTargetByDevKeysQueryVariables,
  FetchManyContentTargetByDevKeysQuery,
  FetchManyGlobalSettingsQuery,
  FetchManyGlobalSettingsQueryVariables,
  FetchTestHtmlQueryVariables,
  FetchTestHtmlQuery,
  FetchContentVideoByIdQueryVariables,
  FetchContentVideoByIdQuery,
  FetchPathwaysAndNewsPageByUrlQueryVariables,
  FetchPathwaysAndNewsPageByUrlQuery,
  FetchNewsPageByUrlQueryVariables,
  FetchNewsPageByUrlQuery,
  FetchContentVideoPageByUrlQueryVariables,
  FetchContentVideoPageByUrlQuery,
  FetchProteinDomainPageByUrlQueryVariables,
  FetchProteinDomainPageByUrlQuery,
  FetchContentPageByUrlQueryVariables,
  FetchContentPageByUrlQuery,
  FetchProtocolPageByUrlQuery,
  FetchProtocolPageByUrlQueryVariables,
} from './types/generated/types'

const queries = {
  fetchPDPTemplateQuery,
  fetchPDPPageQuery,
  fetchContentFragment,
  fetchContentTarget,
  fetchNavigationByDevKey,
  fetchManyByDevKeysNavigation,
  fetchPageByDevKey,
  fetchPDPTemplateAndPDPPage,
  fetchRegionalSettings,
  fetchManyContentTargets,
  fetchManyGlobalSettings,
  fetchTestHTML,
  fetchContentVideoById,
  fetchContentVideoPageByUrl,
  fetchPathwaysAndNewsPageByUrl,
  fetchNewsPageByNavigationLink,
  fetchProteinDomainPageByNavigationLink,
  fetchContentPageByUrl,
  fetchProtocolPageByUrl,
}

function buildCMSPlugin(config: NuxtConfig) {
  const client = config.app.apolloProvider?.clients.cms

  const buildQuery =
    <V = unknown, Q = unknown>(query: any) =>
    (variables?: V) =>
      client.query({
        query,
        variables,
      }) as Promise<{ data: Q; errors?: Error[] }>

  const api = {
    fetchPDPTemplate: buildQuery<
      FetchPdpTemplateByProductIdAndTypeQueryVariables,
      FetchPdpTemplateByProductIdAndTypeQuery
    >(queries.fetchPDPTemplateQuery),

    fetchPDPPageQuery: buildQuery<FetchPdpPageByProductIdQueryVariables, FetchPdpPageByProductIdQuery>(
      queries.fetchPDPPageQuery
    ),

    fetchPageByDevKey: buildQuery<FetchPageByDevKeyQueryVariables, FetchPageByDevKeyQuery>(queries.fetchPageByDevKey),

    fetchTestHTML: buildQuery<FetchTestHtmlQueryVariables, FetchTestHtmlQuery>(queries.fetchTestHTML),

    fetchRegionalSettings: buildQuery<FetchRegionalSettingsQueryVariables, FetchRegionalSettingsQuery>(
      queries.fetchRegionalSettings
    ),

    fetchContentFragment: buildQuery<FetchContentFragmentByUidQueryVariables, FetchContentFragmentByUidQuery>(
      queries.fetchContentFragment
    ),

    fetchContentTarget: buildQuery<FetchContentTargetByDevKeyQueryVariables, FetchContentTargetByDevKeyQuery>(
      queries.fetchContentTarget
    ),

    fetchNavigationByDEVKey: buildQuery<FetchNavigationByDevKeyQueryVariables, FetchNavigationByDevKeyQuery>(
      queries.fetchNavigationByDevKey
    ),

    fetchManyByDevKeysNavigation: buildQuery<
      FetchManyByDevKeysNavigationQueryVariables,
      FetchManyByDevKeysNavigationQuery
    >(queries.fetchManyByDevKeysNavigation),

    fetchPDPTemplateAndPDPPage: buildQuery<
      FetchPdpTemplateAndPdpPageByProductIdAndTypeQueryVariables,
      FetchPdpTemplateAndPdpPageByProductIdAndTypeQuery
    >(queries.fetchPDPTemplateAndPDPPage),

    fetchManyContentTargets: buildQuery<
      FetchManyContentTargetByDevKeysQueryVariables,
      FetchManyContentTargetByDevKeysQuery
    >(queries.fetchManyContentTargets),

    fetchManyGlobalSettings: buildQuery<FetchManyGlobalSettingsQueryVariables, FetchManyGlobalSettingsQuery>(
      queries.fetchManyGlobalSettings
    ),

    fetchContentVideoById: buildQuery<FetchContentVideoByIdQueryVariables, FetchContentVideoByIdQuery>(
      queries.fetchContentVideoById
    ),

    fetchContentVideoPageByUrl: buildQuery<FetchContentVideoPageByUrlQueryVariables, FetchContentVideoPageByUrlQuery>(
      queries.fetchContentVideoPageByUrl
    ),
    fetchPathwaysAndNewsPageByUrl: buildQuery<
      FetchPathwaysAndNewsPageByUrlQueryVariables,
      FetchPathwaysAndNewsPageByUrlQuery
    >(queries.fetchPathwaysAndNewsPageByUrl),

    fetchNewsPageByNavigationLink: buildQuery<FetchNewsPageByUrlQueryVariables, FetchNewsPageByUrlQuery>(
      queries.fetchNewsPageByNavigationLink
    ),
    fetchProteinDomainPageByNavigationLink: buildQuery<
      FetchProteinDomainPageByUrlQueryVariables,
      FetchProteinDomainPageByUrlQuery
    >(queries.fetchProteinDomainPageByNavigationLink),

    fetchContentPageByUrl: buildQuery<FetchContentPageByUrlQueryVariables, FetchContentPageByUrlQuery>(
      queries.fetchContentPageByUrl
    ),

    fetchProtocolPageByUrl: buildQuery<FetchProtocolPageByUrlQueryVariables, FetchProtocolPageByUrlQuery>(
      queries.fetchProtocolPageByUrl
    ),
  }

  return {
    do: () => {
      config.$logger.info('CMS Plugin Execute')
    },
    client,
    queries,
    api,
  }
}

export type CMSPlugin = ReturnType<typeof buildCMSPlugin>

export default function (config: any, inject: any) {
  inject('cms', buildCMSPlugin(config))
}
