import { URL_CARTS, URL_QUOTES } from '../util/constants'
import { Client } from '../client'
import { setCartCheckoutType } from './cart'

export interface GetQuotesParams {
  limit: number
  offset: number
  sort?: string
}

export const bindClient = (client: Client) => {
  const generateQuote = () => setCartCheckoutType(client)('quote')

  function submitQuote(recaptchaToken?: string, language?: string) {
    const body = {
      recaptchaToken,
      language,
    }

    return client.axios.post(URL_QUOTES, body)
  }

  function getQuotes(reqParams?: GetQuotesParams) {
    const params = {
      sort: 'createdAt desc',
      ...reqParams,
    }

    return client.axios.get(URL_QUOTES, { params })
  }

  // only for Japan quotes
  function setCustomerInfo(data: {
    firstNameK: string
    lastNameK: string
    firstNameF: string
    lastNameF: string
    quoteEmailAddress: string
    isQuoteNewsletterOptIn: boolean
    orgName: string
    depName: string
  }) {
    const { orgName, depName, ...rest } = data
    const body = {
      actions: [
        {
          action: 'setShippingAddress',
          address: {
            country: 'JP',
            building: depName,
            company: orgName,
            custom: {
              type: {
                typeId: 'type',
                key: 'address-type',
              },
              fields: rest,
            },
          },
        },
        {
          action: 'setCustomField',
          name: 'privacyAgreedToDateTime',
          value: new Date().toISOString(),
        },
      ],
    }
    return client.axios.post(URL_CARTS, body)
  }

  function sendQuoteEmail(quoteNumber: string, anonymousId?: string) {
    return client.axios.post(URL_QUOTES + '/send-email', { quoteNumber, anonymousId })
  }

  return {
    generateQuote,
    submitQuote,
    getQuotes,
    setCustomerInfo,
    sendQuoteEmail,
  }
}
