// FIXME: Remove this plugin after ATG sunset
export default function ({ $axios }: any, inject: any) {
  const client = $axios.create({
    baseURL: '',
  })

  const logout = (): Promise<any> => {
    console.log('ATG Logout !')

    const atgSession = sessionStorage.getItem('_dynSessConf')

    if (atgSession) {
      const formData = new FormData()
      formData.append('_dyncharset', 'UTF-8')
      formData.append('_dynSessConf', atgSession)
      formData.append('_DARGS', '/include/header.jsp.logoutForm')
      formData.append('_D:/atg/userprofiling/ProfileFormHandler.logoutSuccessURL', ' ')
      formData.append('_D:/atg/userprofiling/ProfileFormHandler.logout', ' ')
      formData.append('/atg/userprofiling/ProfileFormHandler.logoutSuccessURL', '/')
      formData.append('/atg/userprofiling/ProfileFormHandler.logout', '/')

      return client.post('/?_DARGS=/include/header.jsp.logoutForm', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } else {
      return new Promise((resolve) => {
        resolve(false)
      })
    }
  }

  const atg = {
    logout,
  }

  inject('atg', atg)
}
