import { Context } from '@nuxt/types'
import { PANEL_BUILDER_COOKIE } from '~/common/utils/signalstar'

export function signalstarRedirect(context: Context) {
  // If cookie not present it means that configuration is not initialized
  const experimentId = context.$cookies.get(PANEL_BUILDER_COOKIE)
  const startPath = '/products/signalstar/targetspecies'

  // ..
  if (context.route.path !== startPath && !experimentId) {
    return context.redirect(startPath)
  }
}
