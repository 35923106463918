import { Context } from '@nuxt/types'
import { MiddlewareFunction } from '~/types/common/commonTypes'

export function ifNotJapan(middleware: MiddlewareFunction | MiddlewareFunction[]) {
  return (context: Context) => {
    if (context.$cookies.get('c_country') !== 'JP') {
      if (Array.isArray(middleware)) {
        middleware.forEach((m) => m(context))
      } else {
        middleware(context)
      }
    }
  }
}
