



















































































import { storeToRefs } from 'pinia'
import { defineComponent } from '@nuxtjs/composition-api'
import IconFaSolidBars from '~icons/fa-solid/bars'

// Stores
import { useSidebarStore } from '@/store'
import { useCMSGlobalSettingsStore } from '@/store/cms'

// Composables
import usePunchout from '~/composables/punchout/usePunchout'

// Components
import QuickAddPopup from '@/components/feature/quick-add/quickAddPopup.vue'
import Basket from '@/components/feature/basket/Basket.vue'
import SignInAndRegistrationLinks from '@/components/feature/account/SignInAndRegistrationLinks.vue'
import { useRenderPerCountry } from '~/composables/country/useRenderPerCountry'

import Skeleton from '@/components/global/skeletons/Skeleton.vue'
import BasketSkeleton from '@/components/global/skeletons/BasketSkeleton.vue'
export default defineComponent({
  components: {
    IconFaSolidBars,
    QuickAddPopup,
    Basket,
    SignInAndRegistrationLinks,
    Skeleton,
    BasketSkeleton,
  },
  setup() {
    const { getAttributeByCountry } = useCMSGlobalSettingsStore()
    const b2dAllowedAddToBasket = getAttributeByCountry('b2dAllowedCountries')
    const { showForCountry: showBasketForCountry } = useRenderPerCountry([
      'US',
      'DE',
      'AT',
      'JP',
      'GB',
      ...b2dAllowedAddToBasket.value.map((country: string) => country.toUpperCase()),
    ])
    const { showForCountry: showRegLinksForCountry } = useRenderPerCountry(['US', 'DE', 'AT', 'GB'])

    const { isPunchout, welcomeMessage } = usePunchout()
    const sidebarStore = useSidebarStore()

    const links = getAttributeByCountry('topbarLinks')
    const brandLogo = getAttributeByCountry('headerBrandLogo')

    const toggleSidebar = () => {
      sidebarStore.toggleSidebar()
    }

    return {
      links,
      brandLogo,
      toggleSidebar,
      isPunchout,
      welcomeMessage,
      showBasketForCountry,
      showRegLinksForCountry,
    }
  },
})
