








import { defineComponent } from '@nuxtjs/composition-api'
import { useSidebarStore } from '@/store'

export default defineComponent({
  setup() {
    const sidebarStore = useSidebarStore()
    const sidebar = sidebarStore.sidebar

    const toggleSidebar = () => {
      sidebarStore.toggleSidebar()
    }

    return { sidebar, toggleSidebar }
  },
})
