import { defineStore } from 'pinia'
import { jsonToHtml } from '@contentstack/json-rte-serializer'
import { useCountry } from '@/composables/country/useCountry'
import { ContentFragment } from '~/modules/contentstack/types'
import { ContentTarget } from '~/common/utils/parsers/cms/contentTarget'

interface ContentFragmentsCMSStore {
  cf: {
    [key: string]: ContentFragment
  }
}

export const useStore = defineStore({
  id: 'cms-content-fragments',
  state: (): ContentFragmentsCMSStore => ({ cf: {} }),
  getters: {
    html: (state) => (uid: string) =>
      state.cf[uid] && jsonToHtml(state.cf[uid]?.content?.json, { allowNonStandardTypes: true }),
  },
  actions: {
    async fetchContentFragment(uid: string) {
      const { getLocaleCode } = useCountry()

      try {
        const { data } = await this.$nuxt.$cms.api.fetchContentFragment({
          uid,
          locale: getLocaleCode(),
          fallbackLocale: true,
        })

        if (data?.content_fragment?.system?.uid) {
          this.$patch({
            cf: {
              [data?.content_fragment?.system.uid]: data.content_fragment,
            },
          })
        }
      } catch (error: any) {
        console.log(error.message)
      }
    },
    updateUsingContentTarget(contentTarget: ContentTarget) {
      for (const component of contentTarget.contentComponents) {
        this.cf[component.uid] = component.contentFragment
      }
      for (const component of contentTarget.contentRules.flatMap((rule) => rule.contentComponents)) {
        this.cf[component.uid] = component.contentFragment
      }
    },
  },
})
