import { Client } from '../client'
import { URL_CARTS } from '../util/constants'

type CartItemPayload = {
  id: string // sku or line item id
  quantity: number
  experimentId?: string | null
}

type GopItemPayload = {
  lineId: string // line item id
  promiseDate: string //gopDate
}

export type DiscountCodesOnCart = {
  id: string
  code: string
  name: string
}

export const bindClient = (client: Client) => {
  // FIXME: remove when ATG removed, keeping for compatibility with media
  const active = (): Promise<any> => {
    return get()
  }

  // FIXME: remove when ATG removed, keeping for compatibility with media
  const byCookieId = (): Promise<any> => {
    return get()
  }

  const get = (): Promise<any> => {
    return client.axios.get(URL_CARTS)
  }

  const changeQuantity = (id: string, productQty: number) => {
    const body = {
      actions: [
        {
          action: 'changeLineItemQuantity',
          lineItemId: id,
          quantity: productQty,
        },
      ],
    }
    return client.axios.post(URL_CARTS, body)
  }

  const removeItem = (id: string) => {
    const body = {
      actions: [
        {
          action: 'removeLineItem',
          lineItemId: id,
        },
      ],
    }
    return client.axios.post(URL_CARTS, body)
  }

  const applyPromo = (promoCode: string) => {
    const body = {
      actions: [
        {
          action: 'addDiscountCode',
          code: promoCode,
        },
      ],
    }
    return client.axios.post(URL_CARTS, body)
  }

  // Funciton to extract day, month, and year and build date in format YYYY-MM-DD
  function formatDate(date: Date) {
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    return year + '-' + month + '-' + day
  }

  // set setLineItemCustomField 'estShipDate' with global order promise date
  const addGopInfoToOrder = (gopItems: GopItemPayload[]): Promise<any> => {
    const action = 'setLineItemCustomField'
    const data = {
      actions: gopItems.map((item) => ({
        action,
        lineItemId: item.lineId,
        name: 'estShipDate',
        value: formatDate(new Date(item.promiseDate)),
      })),
    }
    return client.axios.post(URL_CARTS, data)
  }

  const add = (cartItems: CartItemPayload[]): Promise<any> => {
    const action = 'addLineItem'
    // cart middleware will handle the data transformation based on cartId presence
    const data = {
      actions: cartItems.map((item) => ({
        action,
        sku: item.id,
        quantity: item.quantity,
        ...(item.experimentId && {
          custom: {
            typeKey: 'line-item-type',
            fields: {
              experimentId: item.experimentId || '',
            },
          },
        }),
      })),
    }

    return client.axios.post(URL_CARTS, data)
  }

  const remove = () => {
    return client.axios.delete(URL_CARTS)
  }

  const setShippingAddressToCart = (
    addressId: string,
    contactId: string,
    country: string,
    vatId: string | null
  ): Promise<any> => {
    const data = {
      actions: [
        { action: 'setCustomField', name: 'contactId', value: contactId },
        {
          action: 'setShippingAddress',
          address: {
            id: addressId,
            country,
          },
        },
      ],
    }

    if (vatId) {
      data.actions.push({
        action: 'setCustomField',
        name: 'vatId',
        value: vatId,
      })
    }

    return client.axios.post(URL_CARTS, data)
  }

  const setBillingAddressToCart = (addressId: string, country: string): Promise<any> => {
    const action = 'setBillingAddress'
    const data = {
      actions: [
        {
          action,
          address: {
            id: addressId,
            country,
          },
        },
      ],
    }
    return client.axios.post(URL_CARTS, data)
  }

  const paymentOperation = (operation: string, id: string): Promise<any> => {
    const action = operation
    const payment = {
      id,
      typeId: 'payment',
    }
    const data = {
      actions: [
        {
          action,
          payment,
        },
      ],
    }
    return client.axios.post(URL_CARTS, data)
  }

  const setAdditionalInfo = (additionalInfo: any): Promise<any> => {
    const action = 'setCustomField'
    const name = 'specialHandling'
    const data = {
      actions: [
        {
          action,
          name,
          value: additionalInfo,
        },
      ],
    }
    return client.axios.post(URL_CARTS, data)
  }

  const setCartToRegular = () => setCartCheckoutType(client)('regular')

  const removeDiscountCodesFromCart = (discountCodes: DiscountCodesOnCart[]): Promise<any> => {
    const action = 'removeDiscountCode'
    const typeId = 'discount-code'

    const actions = discountCodes.map((discountCode: DiscountCodesOnCart) => ({
      action,
      discountCode: {
        typeId,
        id: discountCode.id,
      },
    }))

    const data = {
      actions,
    }
    return client.axios.post(URL_CARTS, data)
  }

  const syncCartAfterAddressOrContactDeletion = (deletion: 'address' | 'contact'): Promise<any> => {
    let data
    if (deletion === 'address') {
      data = {
        actions: [
          {
            action: 'setShippingMethod',
          },
          {
            action: 'setShippingAddress',
          },
          {
            action: 'setBillingAddress',
          },
        ],
      }
    } else if (deletion === 'contact') {
      data = {
        actions: [
          {
            action: 'setCustomField',
            name: 'contactId',
          },
        ],
      }
    }
    return client.axios.post(URL_CARTS, data)
  }

  return {
    get,
    active,
    byCookieId,
    changeQuantity,
    removeItem,
    applyPromo,
    add,
    remove,
    setShippingAddressToCart,
    setBillingAddressToCart,
    paymentOperation,
    setAdditionalInfo,
    setCartToRegular,
    setCartCheckoutType,
    removeDiscountCodesFromCart,
    syncCartAfterAddressOrContactDeletion,
    addGopInfoToOrder,
  }
}

export const setCartCheckoutType =
  (client: Client) => (checkoutType: 'regular' | 'quote' | 'punchout' | 'quoteb2d') => {
    const body = {
      actions: [
        {
          action: 'setCustomField',
          name: 'checkoutType',
          value: checkoutType,
        },
      ],
    }

    return client.axios.post(URL_CARTS, body)
  }
