import { useContext } from '@nuxtjs/composition-api'

// stores
import { useConfigStore } from '~/store/config'
import { useProfileStore } from '~/store/profile'
import { getCustomDimensions } from '~/common/utils/analytics'

export default () => {
  const profileStore = useProfileStore()
  const configStore = useConfigStore()
  const context = useContext()

  // clear the ecommerce object to prevent merged data
  const clearEcommerceObject = () => {
    context.$gtm.push({ ecommerce: null })
  }

  // Push to DL and clear the ecommerce object
  const pushAndClearEcommerceObject = (params: any) => {
    clearEcommerceObject()
    context.$gtm.push(params)
  }

  return {
    getCustomDimensions: () => getCustomDimensions(profileStore, configStore),
    clearEcommerceObject,
    pushAndClearEcommerceObject,
  }
}
