import { joinURL } from 'ufo'
import { ProviderGetImage } from '@nuxt/image'
import type { Context } from '@nuxt/types'
import { detectServerBaseUrl, findTLD } from '@cstweb/common'
import { TLD } from '@/common/constants/utils'

export const getImage: ProviderGetImage = (
  src,
  { modifiers: _modifiers, baseURL } = {},
  { options: _options, nuxtContext, $img: _$img }
) => {
  const { $config, req } = nuxtContext as Context
  let url = baseURL || $config.cst.mediaUrl
  let host

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)
  }
  if (process.client) {
    host = location.origin
  }

  if (TLD.CN.toLowerCase() === findTLD(host)?.toLowerCase()) {
    url = $config.cst.cn.mediaUrl || baseURL
  }

  url = joinURL(url, src)

  return {
    url,
  }
}
