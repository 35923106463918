






import { defineComponent } from '@nuxtjs/composition-api'
import Skeleton from '@/components/global/skeletons/Skeleton.vue'
export default defineComponent({
  components: { Skeleton },
  setup() {
    return {}
  },
})
