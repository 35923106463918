import fromPairs from 'lodash/fp/fromPairs'
import toPairs from 'lodash/fp/toPairs'
import { BasketItemsDataInterface } from '~/types/checkout/checkoutTypes'

/**
 * This functions sorts the items showed in side basket or checkout/quote
 *
 * @param lineData as BasketItemsDataInterface
 * @param newestAddedTop Modifies sorting by date
 */

export function sortLineDataItems(lineData: BasketItemsDataInterface, newestAddedTop?: boolean) {
  return fromPairs(
    toPairs(lineData)
      .sort((a: any[], b: any[]) =>
        // .. Sort by date
        newestAddedTop
          ? new Date(b.at(1)?.addedAt).getTime() - new Date(a.at(1)?.addedAt).getTime()
          : new Date(a.at(1)?.addedAt).getTime() - new Date(b.at(1)?.addedAt).getTime()
      )
      .sort((a: any[], b: any[]) =>
        // .. FreeLine items should be at the bottom
        a.at(1).productKey === b.at(1).productKey && a.at(1)._isFreeLine ? 1 : -1
      )
      .sort((a: any[], b: any[]) =>
        // .. Re-sort so the buffer Kit is at the bottom of the experiments
        a.at(1)?.custom?.fields.experimentId === b.at(1)?.custom?.fields.experimentId &&
        a
          .at(1)
          ?.variant.attributes?.some(
            (attr: any) => attr.name === 'productType' && attr.value.valueOf()?.key === 'Buffer Kit'
          )
          ? 1
          : -1
      )
  ) as BasketItemsDataInterface
}
